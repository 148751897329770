// Super Nav
.supernav-mobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.supernav {
  width: 100%;
  background: #022A39;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 768px) {
    display: none;
  }

  ul {
    display: flex;
    padding: 7px 15px;
    margin: 0;
    overflow: auto;

    li {
      list-style: none;
      margin: 0 40px 0 0;

      a {
        color: #E6EAEC;
        padding: 5px 0px 1px;
        border-bottom: 2px solid transparent;
        transition: all 0.2s ease;
        font-size: 13px;
        line-height: 16px;
        display: block;
        white-space: nowrap;

        &:hover {
          color: #fff;
          text-decoration: none;
          border-bottom: 2px solid #45A4C7;
        }

        &.active {
          color: #fff;
          font-weight: 500;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .supernav-mobile {
    a {
      padding: .5rem 0;
    }
    .nav-item.hovering {
      ul {
        display: block;
        margin-left: 1rem;
        padding-top: 0;
      }
    }
    .navbar-nav {
      min-height: 0;
    }
  }
}