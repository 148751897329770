/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* Homepage - Hero --------------------------------------------------- */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.front .hero_container_mobile {
  display: none;
}

.front .product_heading_container h1#page-title {
  display: none;
}

.front .product_heading_container h1#page-title {
  font-size: 1px;
  opacity: 0;
}

.front .sub_header {
  background: none;
}

.front .hero_container .contextual-links-region {
  position: static;
}

.block {
  display: block;
}

.front .hero_container {
  overflow: hidden;
  position: relative;
  min-height: 700px;
  max-height: 900px;
  height: calc(100vh - 134px);
  /* 100vh minus height of header + utility nav */
}

.home_hero_fx {
  @include flexbox;
  @include align-items(center);
  height: calc(100vh - 134px);
  min-height: 700px;
  max-height: 900px;
}

/* Hero with centered text */
.home_hero_fx.text_center {
  .inner_container {
    color: #fff;
    display: block;
    float: none;
    height: auto;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    max-width: 1190px;
    padding: 90px 20px 0;

    h1 {
      text-transform: uppercase;
      line-height: 1.1;
      color: #fff;

      .intro_text.small {
        font-size: 28px;
        line-height: 2.2;
      }

      .main_text.light {
        font-weight: $font-weight-light;
      }
    }

    h4 {
      text-transform: uppercase;
      margin-bottom: em-calc(15);
      letter-spacing: 0.9px;
      color: #fff;
    }
  }
}

/* Hero with left block of text */
.home_hero_fx.text_left {
  @include flexbox;
  @include align-items(center);
  height: calc(100vh - 134px);
  min-height: 600px;
  max-height: 900px;

  .inner_container {
    color: #fff;
    display: block;
    float: none;
    height: auto;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    max-width: 1190px;
    padding: 90px 20px 0;

    .inner_text_container {
      color: #fff;
      display: block;
      float: left;
      height: auto;
      margin: 0;
      text-align: left;
      width: 70%;
      padding: 0;

      .intro_text {
        text-transform: uppercase;
        color: $color_fx_orange;
        display: block;
        font-size: 38px;
        font-weight: $font-weight-light;
        line-height: 1.6;
      }

      h1 {
        line-height: 1.15;
        color: #fff;
        font-weight: $font-weight-light;
        margin-bottom: 16px;
        font-size: 64px;
      }

      h4 {
        text-transform: uppercase;
        margin-bottom: em-calc(15);
        letter-spacing: 0.9px;
        color: #fff;
      }
    }
  }
}

/* Hero with right block of text */
.home_hero_fx.text_right {
  @include flexbox;
  @include align-items(center);
  height: calc(100vh - 134px);
  min-height: 600px;
  max-height: 900px;

  .inner_container {
    color: #fff;
    display: block;
    float: none;
    height: auto;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    max-width: 1190px;
    padding: 90px 20px 0;

    .inner_text_container {
      color: #fff;
      display: block;
      float: right;
      height: auto;
      margin: 0;
      text-align: left;
      width: 64%;
      padding: 0;

      .intro_text {
        text-transform: uppercase;
        color: $color_fx_orange;
        display: block;
        font-size: 38px;
        font-weight: $font-weight-light;
        line-height: 1.6;
      }

      h1 {
        line-height: 1.15;
        color: #fff;
        font-weight: $font-weight-light;
        margin-bottom: 16px;
        font-size: 60px;

        @include respond-to(max-width, 1300px) {
          font-size: 48px;
        }

        @include respond-to(max-width, 980px) {
          font-size: 35px;
        }
      }

      h4 {
        text-transform: uppercase;
        margin-bottom: em-calc(15);
        letter-spacing: 0.9px;
        color: #fff;
      }
    }
  }
}

/* Individual hero backgrounds ----------------- */

/* My Design V2 Banner */
#homepage-hero {
   background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  h2,
  h3,
  p {
    color: #fff;
    font-weight: 300;

    em {
      font-weight: 500;
    }
  }

  .cta.mobile {
    display: none;
    position: absolute;
    bottom: 0;
  }

  @media all and (max-width: 991px) {
    min-height: 550px;
    padding-top: 155px;

    .video {
      margin-bottom: 20px !important;

      video {
        width: 90% !important;
        position: absolute !important;
        left: 0% !important;
        top: 8% !important;
        z-index: -1 !important;
      }
    }

    .cta.mobile {
      display: block;
      width: 100%;
      margin-bottom: 0;
    }
  }

  .flex {
    display: flex;
    max-width: 90%;
    margin: 0 auto;
    align-items: center;

    .hero-left {
      flex: 0 0 55%;
    }

    .hero-right {
      flex: 0 0 45%;
    }

    @media all and (max-width: 991px) {
      flex-wrap: wrap;

      .hero-left {
        flex: 0 0 100%;
        padding-top: 0;
        padding-right: 0;

        .cta {
          display: none;
        }
      }

      .hero-right {
        flex: 0 0 100%;
      }
    }
  }

  .hero-left {
    padding-top: 100px;
    padding-right: 40px;

    h2 {
      text-transform: uppercase;

      em {
        color: $color_fx_orange;
      }
    }

    h3 {
      em {
        margin-right: 2px;
      }
    }

    .cta {
      margin-top: 60px;
    }
  }

  .hero-right {
    justify-content: right;

    .frame {
      max-width: 95%;

      @media all and (max-width: 991px) {
        margin-top: 25px;
        max-width: 105%;
        margin-left: -2.5%;
        justify-content: center;
      }
    }
  }

  &.vw {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 0;
    video {
      display: block;
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
    .mobile {
      display: none;
      @media all and (max-width: 767px) {
        display: block;
      }
    }
    .desktop {
      @media all and (max-width: 767px) {
        display: none;
      }
    }
    .flex {
      position: relative;
      left: 0;
      right: 0;
      width: 100vw;
      bottom: 0;
      max-width: none;
    }
    .hero-bottom {
      position: absolute;
      bottom: 0;
      color: #fff;
      background: rgba(0, 60, 88, 0.8);
      width: 100vw;
      font-size: 48px;
      @media all and (max-width: 767px) {
        padding: 40px 10px;
        background: #00435d;
      }
      @media all and (max-width: 650px) {
        padding: 35px 10px;
        bottom: -10px;
      }
      @media all and (max-width: 550px) {
        padding: 30px 10px;
      }
      @media all and (max-width: 475px) {
        padding: 20px 5px;
        bottom: 0px;
      }
      @media all and (max-width: 400px) {
        padding: 15px 10px;
        bottom: 0px;
      }
    }
    strong {
      font-style: italic;
      font-weight: 500;
    }
    p {
      margin-bottom: 0;
      padding-top: 20px;
      padding-bottom: 20px;
      text-shadow: 1px 1px 1px #1a1a1a61;
      font-size: 50px;
      @media all and (max-width: 1300px) {
        font-size: 36px;
      }
      @media all and (max-width: 970px) {
        font-size: 28px;
      }
      @media all and (max-width: 767px) {
        font-size: 40px;
      }
      @media all and (max-width: 550px) {
        font-size: 32px;
      }
      @media all and (max-width: 475px) {
        font-size: 28px;
      }
      @media all and (max-width: 400px) {
        font-size: 22px;
      }
    }
    em {
      color: #F48032;
      text-decoration: italic;
      font-weight: 400;
    }
    span {
      display: block;
      line-height: 1.15;
    }
    .left {
      padding-left: 20%;
      @media all and (max-width: 767px) {
        padding-left: 0;
      }
    }
    .cta {
      display: block;
      background: #F5822D;
      color: #fff;
      padding: 10px 20px;
      text-align: center;
      font-size: 20px;
      // vertical-align: middle;
      position: absolute;
      top: 40%;
      bottom: 50%;
      right: 5%;
      padding: 20px 35px;
      line-height: 0;
      @media all and (max-width: 1300px) {
        top: 30%;
      }
      @media screen and (max-width: 975px) {
        font-size: 16px;
      }
      @media all and (max-width: 767px) {
        display: none;
      }
    }
  }
}

/* FX Technician Banner */
// .front header {
//   background-color: #004D6B;
// }

.nl-wrap {
  background: #003c58;
  padding-top: 115px;

  .nl-title {
    font-family: inherit;
    max-width: 700px;
    width: 100%;
  }

  .name {
    span {
      color: #f5822d;
    }

    a {
      color: #f5822d;
    }
  }

  .nl-left {
    flex-basis: 50%;

    .nl-content {
      width: 100%;
      background: transparent;
      padding-right: 5%;
      padding-top: 0;
      padding-bottom: 0;

      .nl-title {
        font-size: 3.7em;
        line-height: 1.1;
        padding-bottom: 0.5em;
        text-transform: uppercase;

        .name {
          display: block;
          margin-left: 0;
          margin-top: 20px;
          line-height: 1;

          img {
            display: inline-block;
            max-width: 80px;
            vertical-align: middle;
          }

          div {
            display: inline-block;
            vertical-align: middle;
            margin-left: 5px;
          }
        }
      }
    }

    .nl-top {
      border-bottom: 8px solid #fff;

      .name {
        font-size: 38px;
        font-weight: 400;
        line-height: 1.2;

        span {
          font-size: 38px;
          font-weight: 400;
          line-height: 1.2;
        }
      }
    }
  }

  .nl-right {
    flex-basis: 50%;
    border-left: 8px solid #fff;

    .nl-top {
      border-bottom: 8px solid #fff;

      .image-wrap img {
        height: 696px;
      }
    }

    .nl-bottom {
      .image-wrap {
        display: flex;
        margin: 0 auto;
        padding: 50px 35px;
        align-items: center;

        img {
          width: 40%;
          padding-right: 15px;
        }
      }

      .name {
        width: 60%;
        text-align: left;
        padding-left: 15px;
        text-transform: none;
        font-size: 1.75em;
        font-style: normal;
        line-height: 1.2;
        border-left: 1px solid #fff;
        margin-top: 0;

        a {
          position: relative;
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .nl-wrap {
    .nl-left {
      .nl-content {
        padding-left: 88px;
        padding-right: 5%;

        .nl-title {
          font-size: 3em;
          padding-bottom: 0.35em;

          .name {
            font-size: 32px;

            span {
              font-size: 32px;
            }
          }
        }
      }
    }

    .nl-right {
      .nl-bottom {
        .name {
          font-size: 1.35em;
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .nl-wrap {
    .nl-left {
      .nl-content {
        .nl-title {
          font-size: 2.25em;
          padding-bottom: 0.6em;

          .name {
            font-size: 26px;

            span {
              font-size: 26px;
            }

            img {
              max-width: 60px;
            }
          }
        }
      }
    }

    .nl-right {
      .nl-bottom {
        .name {
          font-size: 1em;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .nl-wrap {
    .nl-left {
      width: 50%;

      .nl-content {
        padding-left: 5%;

        .nl-title {
          font-size: 1.85em;
          padding-bottom: 2.5em;

          .name {
            font-size: 22px;

            span {
              font-size: 22px;
            }

            img {
              max-width: 50px;
            }
          }
        }
      }
    }

    .nl-right {
      width: 50%;

      .nl-bottom {
        .image-wrap {
          flex-direction: column;

          img {
            order: 1;
            width: 100%;
            padding-right: 0;
            margin-top: 20px;
          }
        }

        .name {
          width: 100%;
          padding-left: 0;
          font-size: 1.35em;
          border-left: none;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .nl-wrap {
    display: block;
    padding-top: 50px;

    .nl-inner {
      flex-direction: column;

      .nl-top {
        background: transparent;
      }
    }

    .nl-left {
      width: 100%;
      flex-basis: 100%;
      border-left: none;
      border-right: none;

      .nl-top {
        border-bottom: 3px solid #fff;
      }

      .nl-bottom {
        display: none;
      }

      .nl-content {
        padding: 0 30px 0 30px !important;

        .nl-title {
          font-size: 2.75em;
          padding: 0.75em 0 0.5em;

          .name {
            font-size: 28px;

            span {
              font-size: 28px;
            }

            img {
              max-width: 70px;
            }
          }
        }
      }
    }

    .nl-right {
      width: 100%;
      flex-basis: 100%;
      border-left: none;
      border-right: none;

      .nl-top {
        border-bottom: 3px solid #fff;
      }

      .nl-bottom {
        .image-wrap {
          align-items: normal;

          img {
            max-width: 400px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .nl-wrap {
    .nl-left {
      .nl-content {
        padding: 0 15px 0 15px !important;

        .nl-title {
          font-size: 2em;
          padding: 0.75em 0 0.5em;

          .name {
            margin-left: 0;
            font-size: 22px;

            span {
              font-size: 22px;
            }

            img {
              max-width: 50px;
            }
          }
        }
      }
    }

    .nl-right {
      .nl-bottom {
        .image-wrap {
          padding: 25px 15px 25px 15px;

          img {
            max-width: 300px;
          }
        }

        .name {
          font-size: 1.25em;
        }
      }
    }
  }
}

.home_hero_fx.ws {
  background: #000 url("/sites/all/themes/fxl_responsive/images/bg_homepage_hero_ws.jpg") no-repeat scroll 46% top /
    cover;

  @include respond-to(max-width, 1300px) {
    background-position: 35% top;
  }
}

.home_hero_fx.sp-a {
  background: rgba(0, 0, 0, 0) url("/sites/all/themes/fxl_responsive/images/bg_homepage_hero_sp-a.jpg") no-repeat scroll
    50% top / cover;
}

.home_hero_fx.mpl {
  background: #000 url("/sites/all/themes/fxl_responsive/images/bg_homepage_hero_mpl.jpg") no-repeat scroll 50% top /
    cover;
}

.home_hero_fx.designer_standard {
  background: rgba(0, 0, 0, 0) url("/sites/all/themes/fxl_responsive/images/bg_homepage_hero_designer_standard.jpg")
    no-repeat scroll center center / cover;
}

.home_hero_fx.dx_transformer {
  background: rgba(0, 0, 0, 0) url("/sites/fxl.com/files/bg_homepage_hero_dx.jpg") no-repeat scroll center center /
    cover;
}

.home_hero_fx.luxor {
  background: #000 url("/sites/all/themes/fxl_responsive/images/bg_homepage_hero_luxor_2.jpg") no-repeat scroll bottom
    center / cover;
}

.home_hero_fx.thirty_years {
  background: #000 url("/sites/fxl.com/files/bg_homepage_hero_30_years.jpg") no-repeat scroll bottom center / cover;
}

.home_hero_fx.mr16_eco {
  background: #000 url("/sites/fxl.com/files/bg_homepage_hero_mr16_eco.jpg") no-repeat scroll bottom center / cover;
}

.home_hero_fx.hp-48 {
  background: #000 url("/sites/fxl.com/files/bg_homepage_hero_hp48.jpg") no-repeat scroll bottom center / cover;
}

.hero_container.global-launch {
  background: #000 url("/sites/all/themes/fxl_responsive/images/homepage/international.png") no-repeat scroll bottom
    center / cover;
  display: block !important;

  p {
    font-size: 4.6em;
    line-height: 1.3em;
  }

  .hammer {
    font-size: 32px;
    line-height: 32px;
  }
}

@media screen and (max-width: 960px) {
  .hero_container.global-launch p {
    font-size: 3.6em;
  }
}

@media screen and (max-width: 768px) {
  .front .hero_container.global-launch {
    background: #000 url("/sites/all/themes/fxl_responsive/images/homepage/international_mobile.png") no-repeat scroll
      bottom center / cover;
    min-height: 0;
    height: 550px;

    .home_hero_fx {
      align-items: start;
      min-height: 0;
      height: auto;

      &.text_center .inner_container {
        padding-top: 80px;
      }
    }

    .hammer {
      font-size: 21px !important;
      line-height: 1em;
    }

    p {
      font-size: 2.2em;
    }

    .button {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
}

.inline_block_it {
  display: inline-block;
}

.home_hero_fx.luxor {
  @include align-items(flex-end);

  .bar {
    @include flexbox;
    @include align-items(flex-end);
    background: rgba(0, 0, 0, 0.5);
    width: 100%;

    .inner_container {
      position: relative;
      max-width: 1360px;
      margin: 0 auto;

      .image_container {
        max-width: 580px;
        position: absolute;
        bottom: 0;
        width: 45%;
      }

      .inner_text_container {
        padding: 1.8em;
        width: 56%;
        float: right;

        sup {
          font-size: 60%;
        }

        p {
          font-size: 2.5em;
          line-height: 1.2;
          color: #fff;
          margin-bottom: 0.4em;
        }

        h1 {
          color: #fff;
          font-weight: 400;
          font-size: 1.5em;
          text-transform: uppercase;
          margin-top: 0;
        }
      }
    }
  }
}

@media screen and (max-height: 900px) {
  .home_hero_fx.luxor .bar .inner_container .image_container {
    max-width: 500px;
  }

  .home_hero_fx.luxor .bar .inner_container .inner_text_container {
    width: 60%;
  }
}

@media screen and (max-height: 900px) and (max-width: 1240px) {
  .home_hero_fx.luxor .bar .inner_container .inner_text_container {
    width: 56%;
  }
}

@media screen and (max-width: 1290px) {
  .home_hero_fx.luxor .bar .inner_container .image_container {
    width: 44vw;
  }

  .home_hero_fx.luxor .bar .inner_container .inner_text_container p {
    font-size: 3.2vw;
  }

  .home_hero_fx.luxor .bar .inner_container .inner_text_container h1 {
    font-size: 1.8vw;
  }
}

@media screen and (max-width: 980px) {
  .home_hero_fx.luxor .bar .inner_container .inner_text_container {
    padding: 1.4em;
  }
}

@media screen and (max-width: 768px) {
  .home_hero_fx_mobile.luxor {
    .text_container {
      background-color: #3b3b3b;
      padding: 4vw;

      @include respond-to(max-width, 480px) {
        padding: 5vw;
      }

      sup {
        font-size: 60%;
      }

      h1 {
        color: #fff;
        font-weight: 400;
        font-size: 3.5vw;
        text-transform: uppercase;

        @include respond-to(max-width, 480px) {
          font-size: 5vw;
        }
      }

      p {
        color: #fff;
        font-size: 5vw;
        line-height: 1.3;
        margin-bottom: 0;

        @include respond-to(max-width, 480px) {
          font-size: 7vw;
        }
      }
    }
  }
}

/* FX 30 Year Anniversary homepage hero */
.home_hero_fx.thirty_years {
  @include align-items(flex-end);

  @include respond-to(max-width, 1150px) {
    font-size: 90%;
  }

  @include respond-to(max-width, 940px) {
    font-size: 76%;
  }

  .bar {
    @include flexbox;
    @include align-items(flex-end);
    background: rgba(0, 60, 88, 0.8);
    padding: 1.4em;
    width: 100%;

    .inner_container {
      position: relative;
      width: 1100px;
      margin: 0 auto;

      .image_container {
        position: absolute;
        bottom: 11em;
        width: 35%;

        @include respond-to(max-width, 1150px) {
          width: 30%;
        }
      }

      .inner_text_container {
        padding: 1.5em;
        width: 67%;
        float: right;

        @include respond-to(max-width, 1150px) {
          width: 71%;
        }

        sup {
          font-size: 60%;
        }

        p {
          font-size: 2.5em;
          line-height: 1.2;
          color: #fff;
          margin-bottom: 0.4em;
        }

        h1 {
          color: #fff;
          font-weight: 400;
          font-size: 2.7em;
          text-transform: uppercase;
          margin-top: 0;
          line-height: 1.3;

          @include respond-to(max-width, 1150px) {
            font-size: 2.5em;
          }
        }
      }
    }
  }
}

@media screen and (max-height: 900px) {
  .home_hero_fx.thirty_years .bar .inner_container .image_container {
    width: 30%;
  }

  .home_hero_fx.thirty_years .bar .inner_container .inner_text_container {
    width: 70%;
  }
}

@media screen and (max-height: 900px) and (max-width: 1240px) {
  .home_hero_fx.thirty_years .bar .inner_container .image_container {
    width: 30%;
  }

  .home_hero_fx.thirty_years .bar .inner_container .inner_text_container {
    width: 70%;
  }
}

@media screen and (max-width: 980px) {
  .home_hero_fx.thirty_years .bar .inner_container .inner_text_container {
    padding: 1.4em;
  }
}

@media screen and (max-width: 768px) {
  .home_hero_fx_mobile.thirty_years {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(flex-end);

    img.thirty_years_logo {
      width: 40%;
      position: absolute;
      bottom: 57vw;
      left: 4%;

      @include respond-to(max-width, 480px) {
        width: 36%;
        bottom: 60vw;
      }

      @include respond-to(max-width, 410px) {
        bottom: 63vw;
      }
    }

    .text_container {
      background-color: #003c58;
      padding: 9vw 5vw 3vw 5vw;

      @include respond-to(max-width, 480px) {
        padding: 9vw 6vw 4vw 6vw;
      }

      sup {
        font-size: 60%;
      }

      h1 {
        color: #fff;
        font-weight: 400;
        font-size: 7.1vw;
        line-height: 1.4;
        margin-bottom: 0;
        padding-bottom: 1vw;
        text-transform: uppercase;
      }

      p {
        color: #fff;
        font-size: 5vw;
        line-height: 1.3;
        margin-bottom: 0;
      }
    }

    .button {
      margin-bottom: 0 !important;
    }
  }
}

/* HP-48 homepage hero */
.home_hero_fx.hp-48 {
  @include align-items(center);

  @include respond-to(max-width, 1230px) {
    font-size: 90%;
  }

  @include respond-to(max-width, 990px) {
    font-size: 76%;
  }

  .bar {
    margin-top: 50px;
    background: rgba(0, 60, 88, 0.85);
    padding: 1.4em;
    width: 100%;
    height: 284px;
    @include flexbox;
    @include align-items(center);

    @include respond-to(max-width, 990px) {
      height: 230px;
    }

    @include respond-to(max-width, 850px) {
      height: 200px;
    }

    .inner_container {
      position: relative;
      width: 1190px;
      margin: 0 auto;

      .image_container {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 31%;
      }

      .inner_text_container {
        padding: 0.5em 1em 1em 1em;
        width: 68%;
        float: right;

        sup {
          font-size: 60%;
        }

        p {
          font-size: 1.5em;
          text-transform: uppercase;
          line-height: 1.1;
          color: #fff;
          margin-bottom: 0.4em;

          @include respond-to(max-width, 1100px) {
            font-size: 1.3em;
          }

          @include respond-to(max-width, 850px) {
            font-size: 1.1em;
          }
        }

        h1 {
          color: #fff;
          font-weight: 400;
          font-size: 3.5em;
          margin-bottom: 14px;
          margin-top: 0;
          line-height: 1.1;
          font-style: italic;

          @include respond-to(max-width, 1100px) {
            font-size: 3.1em;
          }

          @include respond-to(max-width, 850px) {
            font-size: 2.7em;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .home_hero_fx_mobile.hp-48 {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(flex-end);

    img.product_spotlight {
      width: 49%;
      position: absolute;
      bottom: 56vw;
      left: 50%;
      -webkit-transform: tranlateX(-50%);
      transform: translateX(-50%);

      @include respond-to(max-width, 380px) {
        width: 47%;
        bottom: 60vw;
      }
    }

    .text_container {
      background-color: #003c58;
      padding: 29vw 5vw 4vw 5vw;

      sup {
        font-size: 60%;
      }

      h1 {
        color: #fff;
        font-weight: 400;
        font-size: 7vw;
        margin-bottom: 14px;
        margin-top: 0;
        line-height: 1.1;
        font-style: italic;
        text-align: center;
      }

      p {
        font-size: 3.4vw;
        text-transform: uppercase;
        line-height: 1.1;
        color: #fff;
        margin-bottom: 0.4em;
        margin-top: 1.2em;
        text-align: center;
      }
    }

    .button {
      margin-bottom: 0 !important;
    }
  }
}

/* MR-16 Eco homepage hero */
.home_hero_fx.mr16_eco {
  @include align-items(flex-end);

  @include respond-to(max-width, 1150px) {
    font-size: 90%;
  }

  @include respond-to(max-width, 990px) {
    font-size: 76%;
  }

  .bar {
    @include flexbox;
    @include align-items(flex-end);
    background: rgba(0, 60, 88, 0.85);
    padding: 1.4em;
    width: 100%;

    .inner_container {
      position: relative;
      width: 1100px;
      margin: 0 auto;

      .image_container {
        position: absolute;
        bottom: 4em;
        width: 46%;
      }

      .inner_text_container {
        padding: 0.5em 1em 1em 1em;
        width: 54%;
        float: right;

        sup {
          font-size: 60%;
        }

        p {
          font-size: 3.6em;
          line-height: 1.1;
          color: #fff;
          margin-bottom: 0.4em;
        }

        h1 {
          color: $color_fx_orange;
          font-weight: 400;
          font-size: 2.7em;
          margin-bottom: 0;
          margin-top: 0;
          line-height: 1.3;

          @include respond-to(max-width, 1150px) {
            font-size: 2.5em;
          }
        }
      }
    }
  }
}

@media screen and (max-height: 900px) {
  .home_hero_fx.mr16_eco .bar .inner_container .image_container {
    width: 54%;
  }

  .home_hero_fx.mr16_eco .bar .inner_container .inner_text_container {
    width: 46%;
  }
}

@media screen and (max-height: 900px) and (max-width: 1240px) {
  .home_hero_fx.mr16_eco .bar .inner_container .image_container {
    width: 54%;
  }

  .home_hero_fx.mr16_eco .bar .inner_container .inner_text_container {
    width: 46%;
  }
}

@media screen and (max-width: 980px) {
  .home_hero_fx.mr16_eco .bar .inner_container .inner_text_container {
    padding: 1.4em;
  }
}

@media screen and (max-width: 830px) {
  .home_hero_fx.mr16_eco .bar .inner_container .image_container {
    width: 50%;
  }

  .home_hero_fx.mr16_eco .bar .inner_container .inner_text_container {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .home_hero_fx_mobile.mr16_eco {
    @include flexbox;
    @include flex-direction(column);
    @include justify-content(flex-end);

    img.product_spotlight {
      width: 78%;
      position: absolute;
      bottom: 46vw;
      left: 11%;

      @include respond-to(max-width, 440px) {
        bottom: 50vw;
      }

      @include respond-to(max-width, 360px) {
        bottom: 52vw;
      }
    }

    .text_container {
      background-color: #003c58;
      padding: 9vw 5vw 4vw 5vw;

      @include respond-to(max-width, 480px) {
        padding: 9vw 6vw 4vw 6vw;
      }

      sup {
        font-size: 60%;
      }

      h1 {
        color: $color_fx_orange;
        font-weight: 400;
        font-size: 7.1vw;
        line-height: 1.4;
        margin-bottom: 0;
        padding-bottom: 1vw;
      }

      p {
        color: #fff;
        font-size: 10vw;
        line-height: 1.1;
        margin-bottom: 0;
        font-style: italic;
      }
    }

    .button {
      margin-bottom: 0 !important;
    }
  }
}

.home_hero_fx .button {
  margin-bottom: 0;
  margin-top: 8px;
}

/* M-PL */
.home_hero_fx.mpl {
  .inner_container {
    max-width: 1500px !important;
    padding-left: 60px;

    .intro_text {
      margin-bottom: 0;
      line-height: 1em !important;
      font-size: 30px !important;
    }
  }
}

.home_hero_fx_mobile.mpl.text_left {
  position: relative;

  .outer_container {
    position: absolute;
    bottom: 0;
  }

  .inner_container {
    max-width: none;
    width: 100%;
    float: left !important;
    background: rgba(0, 0, 0, 0.6);

    .inner_text_container {
      width: 100%;
      position: relative;
      top: -20px;

      .intro_text {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
        font-size: 3.7vw !important;
      }

      h1 {
        font-size: 7.5vw !important;
      }
    }
  }

  .button {
    width: 100%;
    margin: 0;
  }
}

.home_hero_fx_mobile.mpl.text_left .button {
  width: 100% !important;
}

@media only screen and (max-width: 1100px) {
  .home_hero_fx.mpl {
    .inner_container {
      .intro_text {
        font-size: 2.2vw !important;
      }
    }
  }
}

/* DX Transformer */

.home_hero_fx.dx_transformer {
  .inner_container {
    display: table;
    height: 100%;
    margin: 0 auto;
    max-width: 1200px;
    position: relative;
    width: 100%;
  }

  .intro_text {
    text-transform: uppercase;
    color: $color_fx_orange;
    display: block;
    font-size: 28px;
    font-weight: $font-weight-light;
    line-height: 1.2;
    margin-bottom: 8px;

    @include respond-to(max-width, 1150px) {
      font-size: 24px;
    }

    @include respond-to(max-width, 80px) {
      font-size: 20px;
    }
  }

  .dx_transformer_image {
    height: auto;
    left: 0;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    top: calc(50% + 116px);
    -webkit-transform: translateY(-60%);
    transform: translateY(-60%);
    width: auto;
    z-index: 1;

    @include respond-to(max-width, 1150px) {
      width: 34vw;
    }

    @include respond-to(max-height, 890px) {
      width: 370px;
    }
  }

  .left_side {
    display: table-cell;
    position: relative;
    width: 40%;
  }

  .right_side {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    display: table;
    height: 320px;
    left: 30%;
    padding-left: 90px;
    padding-right: 40px;
    position: relative;
    width: 200%;
    position: absolute;
    top: calc(50% + 116px);
    -webkit-transform: translateY(-60%);
    transform: translateY(-60%);

    @include respond-to(max-width, 1150px) {
      left: 27vw;
      padding-left: 8vw;
      padding-top: 40px;
      padding-bottom: 40px;
      height: auto;
    }

    @include respond-to(max-height, 890px) {
      left: 288px;
    }
  }

  .right_side .inner {
    display: table-cell;
    vertical-align: middle;
  }

  .right_side .inner2 {
    max-width: 780px;
  }

  .right_side h1 {
    color: #fff;
    font-size: 52px;
    line-height: 1.15;
    margin-bottom: 10px;
    margin-top: 0;
    font-weight: $font-weight-light;

    @include respond-to(max-width, 1150px) {
      font-size: 40px;
    }

    @include respond-to(max-width, 850px) {
      font-size: 34px;
    }
  }
}

@media screen and (max-width: 1150px) and (max-height: 890px) {
  .home_hero_fx.dx_transformer .dx_transformer_image {
    width: 34vw;
  }

  .home_hero_fx.dx_transformer .right_side {
    height: auto;
    left: 27vw;
    padding-bottom: 40px;
    padding-left: 8vw;
    padding-top: 40px;
  }
}

@media screen and (max-width: 1200px) {
  .home_hero_fx.text_left .inner_container .inner_text_container h1 {
    font-size: 56px;
  }

  .front .hero_container .home_hero_fx.text_left {
    background-position: 65% top;
  }
}

@media screen and (max-width: 1000px) {
  .home_hero_fx.text_left .inner_container .inner_text_container h1 {
    font-size: 48px;
  }

  .home_hero_fx.text_left .inner_container .inner_text_container {
    width: 80%;
  }
}

@media screen and (max-width: 1000px) {
  .home_hero_fx .inner_container h1 {
    font-size: 3em;
  }
}

@media screen and (max-width: 768px) {
  .front .hero_container {
    display: none;
  }

  .front .hero_container_mobile {
    display: block;
  }

  .front .hero_container_mobile {
    overflow: hidden;
    position: relative;
    height: auto;

    .button {
      font-size: 4vw;
      padding: 3vw;
      width: 100%;
    }
  }

  /* Mobile hero with centered text */
  .home_hero_fx_mobile.text_center {
    .inner_container {
      color: #fff;
      display: block;
      float: none;
      height: auto;
      margin: 0 auto;
      text-align: center;
      width: 100%;
      max-width: 980px;
      padding: 50px 20px 0;

      h1 {
        line-height: 1.2;
        font-size: 2.5em;
        /* 40px/16px */
        color: #fff;

        @include respond-to(max-width, 600px) {
          font-size: 2em;
          line-height: 1.2;
        }

        @include respond-to(max-width, 480px) {
          font-size: 1.5em;
        }

        .intro_text.small {
          font-size: 0.8em;
          line-height: 1.8;
        }

        .main_text {
          line-height: 1.2;

          @include respond-to(max-width, 380px) {
            font-size: 0.8em;
            line-height: 1.4;
          }
        }

        .main_text.light {
          font-weight: $font-weight-light;
        }
      }

      h4 {
        text-transform: uppercase;
        margin-bottom: em-calc(15);
        letter-spacing: 0.9px;
        font-size: 1.125em;
        /* 18/16 */
        color: #fff;
      }
    }

    .button {
      padding-left: 2em;
      padding-right: 2em;
      width: auto !important;
    }
  }

  /* Mobile hero with left-justified text */
  .home_hero_fx_mobile.text_left {
    .inner_container {
      color: #fff;
      display: block;
      float: none;
      height: auto;
      margin: 0 auto;
      text-align: center;
      width: 100%;
      max-width: 980px;
      padding: 50px 20px 0;

      .inner_text_container {
        color: #fff;
        display: block;
        float: left;
        height: auto;
        margin: 0;
        text-align: left;
        width: 70%;
        padding: 0;

        .intro_text {
          color: $color_fx_orange;
          display: block;
          font-size: 38px;
          font-weight: $font-weight-light;
          line-height: 1.6;
          text-transform: uppercase;
        }

        h1 {
          line-height: 1.15;
          color: #fff;
          font-weight: $font-weight-light;
          margin-bottom: 16px;
          font-size: 40px;
        }

        h4 {
          text-transform: uppercase;
          margin-bottom: em-calc(15);
          letter-spacing: 0.9px;
          color: #fff;
          font-size: 18px;
        }
      }
    }
  }

  /* Individual mobile hero backgrounds ----------------- */
  .home_hero_fx_mobile.sp-a {
    background: transparent url("/sites/all/themes/fxl_responsive/images/bg_homepage_hero_mobile_sp-a.jpg") no-repeat
      scroll center top / cover;
  }

  .home_hero_fx_mobile.designer_standard {
    background: transparent url("/sites/all/themes/fxl_responsive/images/bg_homepage_hero_mobile_designer_standard.jpg")
      no-repeat scroll top center / cover;
  }
}

/* End of 768 styles */

@media screen and (max-width: 668px) {
  .home_hero_fx_mobile.text_left .inner_container .inner_text_container {
    width: 100%;
  }

  .home_hero_fx_mobile.text_left .inner_container .inner_text_container h1 {
    font-size: 34px;
  }

  .home_hero_fx_mobile.text_left .inner_container .inner_text_container .intro_text {
    font-size: 28px;
  }
}

@media screen and (max-width: 560px) {
  .home_hero_fx_mobile.text_left .button {
    width: auto;
  }
}

@media screen and (max-width: 500px) {
  .front .hero_container_mobile .button {
    font-size: 20px;
    padding: 18px 18px 16px;
    width: 100%;
  }

  .home_hero_fx_mobile.text_left .inner_container .inner_text_container h1 {
    font-size: 24px;
  }

  .home_hero_fx_mobile.text_left .inner_container .inner_text_container .intro_text {
    font-size: 18px;
  }

  .home_hero_fx_mobile.text_left .inner_container .inner_text_container h1 {
    margin-top: 20px;
  }

  .home_hero_fx_mobile.text_left .button {
    width: auto !important;
  }

  .front .hero_container_mobile {
    overflow: hidden;
    position: relative;
  }

  .home_hero_fx_mobile.sp-a {
    background-position: 76% top;
  }
}

@media screen and (max-width: 470px) {
  .home_hero_fx_mobile .inner_container h1 {
    font-size: 8.5vw;
  }
}

@media screen and (max-width: 430px) {
  .home_hero_fx_mobile.text_left .inner_container {
    padding: 70px 20px 0;
  }

  .home_hero_fx_mobile.sp-a {
    background: transparent url("/sites/all/themes/fxl_responsive/images/bg_homepage_hero_mobile_small_sp-a.jpg")
      no-repeat scroll center bottom / cover;
    display: block;
  }

  .home_hero_fx_mobile.text_left .button {
    font-size: 12px;
    padding-left: 20px;
    padding-right: 20px;
    width: auto !important;
  }
}

/* Luxor wireless linking banner */
.luxor-wireless-linking-banner {
  min-height: 200px;

  .img-box {
    position: relative;

    img {
      background: url("/sites/all/themes/fxl_responsive/images/banners/luxor_linking/FX-331-Luxor-Linking_desktop.jpg");
      width: 100%;
    }

    .text-area {
      position: absolute;
      bottom: 0;
      right: 0;
      max-width: 60%;
      margin-right: 40px;

      .header-text {
        color: #ee7623;
        text-transform: uppercase;
        font-size: 3vw;
        font-family: "SeroPro";
        font-weight: 300;
        font-style: italic;
        margin-bottom: 10px;
        text-align: right;
        float: right;

        span {
          white-space: nowrap;
        }

        sup {
          font-size: 35%;
          vertical-align: top;
          position: relative;
          top: 9px;
        }
      }

      .p-text {
        font-size: 2vw;
        color: white;
        margin-bottom: 10px;
        line-height: 1;
        float: right;
        font-weight: 500;
        overflow: auto;
      }

      .btn {
        float: right;
        clear: both;

        .button.cta {
          overflow: hidden;
          margin-top: 10px;
          margin-bottom: 40px;
        }
      }
    }
  }
}

@media all and (max-width: 1600px) {
  .luxor-wireless-linking-banner {
    .img-box {
      .text-area {
        .btn {
          .button.cta {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1300px) {
  .luxor-wireless-linking-banner {
    .img-box {
      .text-area {
        .header-text {
          font-size: 2.5vw;
        }

        .p-text {
          font-size: 1.7vw;
        }
      }
    }
  }
}

@media all and (max-width: 1100px) {
  .luxor-wireless-linking-banner {
    .img-box {
      .text-area {
        .btn {
          .button.cta {
            font-size: 0.7em;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

@media all and (max-width: 980px) {
  .luxor-wireless-linking-banner {
    .img-box {
      .text-area {
        .header-text,
        .p-text {
          margin-bottom: 5px;
        }
      }
    }
  }
}

/* On screens that are 768px or less,  */
@media all and (max-width: 768px) {
  .luxor-wireless-linking-banner {
    position: relative;
    margin-bottom: 30px;

    .img-box {
      .img-switch {
        max-width: 100%;
        content: url("/sites/all/themes/fxl_responsive/images/banners/luxor_linking/FX-331-Luxor-Linking_mobile.jpg");
      }

      .text-area {
        position: absolute;
        bottom: -30px;
        right: 0;
        max-width: 100%;
        background: rgba(0, 0, 0, 0.5);
        margin: 0;

        .header-text {
          color: #ee7623;
          font-size: 33px;
          margin-bottom: 0;
          padding: 20px 20px 0;
        }

        .p-text {
          padding: 0 20px;
          color: white;
          font-size: 23px;
          line-height: 32px;
          text-align: right;
          margin-bottom: 15px;

          span {
            white-space: nowrap;
          }
        }

        .btn {
          width: 100%;

          .button.cta {
            display: block;
            width: 100%;
            margin: 0;
            font-size: 16px;
          }
        }
      }
    }
  }
}
/* End Luxor wireless linking banner */

/* FX VK Homepage Banner */

body.i18n-en .rh-wall-light-img {
  display: inline;
}

body.i18n-en .rh-wall-light-copy {
  display: inline;
}

body.i18n-en .vk_only-text {
  display: none;
}

.vk-launch-banner {
  background: url("https://www.fxl.com/sites/fxl.com/files/_banners/vk/homepage_vk_hero.jpg");
  background-size: cover;
  background-position: right bottom;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 4%;
  overflow: hidden;
  min-height: 810px;
  color: #fff;
}

.vk_bubble img {
  max-width: 250px;
  width: 100%;
}

.vk_bubble {
  position: absolute;
  bottom: 40%;
}

.rh-wall-light-img {
  display: none;
}

.rh-wall-light-copy {
  display: none;
}

.vk-text {
  font-size: 2em;
  margin-bottom: 10px;
}

.vk-launch-banner h2 {
  color: #fff;
  font-weight: lighter;
  font-style: italic;
  text-transform: uppercase;
  font-size: 3.3em;
}

.vk_bottom {
  background-color: rgba(0, 77, 107, 0.8);
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
}

.vk_copy {
  z-index: 1;
  position: relative;
  padding: 2% 5%;
  flex: 65%;
  text-align: left;
}

.vk_cta {
  flex: 33%;
  align-self: center;
  padding: 2%;
}

@media screen and (max-width: 1960px) {
  .vk_bubble {
    bottom: 40%;
  }

  .vk-launch-banner h2 {
    font-size: 2.6em;
  }

  .vk-text {
    font-size: 1.8em;
  }
}

@media screen and (max-width: 1600px) {
  .vk_bubble {
    bottom: 35%;
  }

  .vk-launch-banner h2 {
    font-size: 2.2em;
  }

  .vk-text {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 1325px) {
  .vk-launch-banner h2 {
    font-size: 1.8em;
  }

  .vk-text {
    font-size: 1.1em;
  }
}

@media screen and (max-width: 768px) {
  .vk_bubble {
    bottom: 40%;
  }

  .button {
    margin: 0;
    width: 100%;
  }

  .vk-launch-banner h2 {
    font-size: 2.5em;
  }

  .vk-text {
    display: none;
  }

  .vk_cta {
    flex: 100%;
    padding: 0;
  }

  vk_copy {
    flex: 100%;
  }

  .vk_bubble img {
    max-width: 200px;
  }
}

@media screen and (max-width: 500px) {
  .vk_bubble {
    padding-top: 75%;
  }

  .vk_bubble img {
    max-width: 175px;
  }

  .vk-launch-banner h2 {
    font-size: 2em;
  }
}

// EXA banner//

.exa-banner {
  background: url("/sites/all/themes/fxl_responsive/images/banners/exa/exa-background.jpg");
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  color: #fff;
  font-family: "SeroPro";
  margin-bottom: 10px;
  background-position: bottom center;

  h2 {
    text-transform: uppercase;
    color: #fff;
    padding-top: 3em;
    padding-left: 1.27em;
    margin-bottom: 0px;
    font-size: 64px;
    pointer-events: none;
  }

  h2 strong {
    color: #ee7623;
  }
  .tag-line {
    padding-left: 1.2em;
    color: #fff;
    margin-top: -29px;
    font-size: 64px;
    font-weight: 300;
    margin-bottom: 0px;
    pointer-events: none;
  }
  .p-one,
  .p-two {
    font-family: "SeroPro";
    padding-left: 2em;
    font-size: 40px;
    font-weight: 200 !important;
    margin-top: 0px;
    margin-bottom: 0px;
    text-decoration: none;
    color: #fff;
    pointer-events: none;
  }
  .p-two {
    margin-top: -16px;
  }
  .btn.exa_cta {
    padding-left: 4em;
    font-size: 20px;
    font-weight: 500;
    margin-top: 30px;
  }

  .exa-btn {
    font-weight: 500;
    border: none;
    box-shadow: none;
    background-color: #ee7623;
    text-transform: uppercase;
    padding-top: 1em;
    padding-bottom: 0.875em;
    padding-left: 1.125em;
    padding-right: 1.125em;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }

  .light-row {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 6.375em;
    margin-bottom: 30px;
    padding-right: 50px;
    margin-right: 50px;
  }
  .light-card {
    flex: 0 1 calc(25% - 1em);
    border: none;
  }
  .light-card img {
    width: 200px;
    height: auto;
  }
  .light-card p {
    font-family: "Seropro";
    font-size: 20px;
    text-align: center;
    padding-top: 20px;
  }
}
.cover-link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* On screens that are 1045px or less,  */
@media all and (max-width: 1045px) {
  .exa-banner {
    .light-row {
      margin-bottom: 0;
      justify-content: space-around;
      margin-right: auto;
    }
  }
}

/* screens 954 */
@media all and (max-width: 954px) {
  .exa-banner {
    .exa-copy {
      width: 100%;
      font-size: 30px;
    }
    h2 {
      font-size: 70px;
    }
    .tag-line {
      font-size: 52px;
      padding-left: 15px;
      margin-left: 1em;
    }
    .light-row {
      margin-bottom: 0;
      margin-left: 24px;
      justify-content: space-around;
    }

    .btn.exa_cta {
      display: flex;
      width: 100%;
      .button,
      .cta,
      .exa-btn {
        font-size: 22px;
        margin-bottom: 0;
      }
    }
  }
}

/* On screens that are 814px or less,  */
@media all and (max-width: 826px) {
  .exa-banner {
    h2 {
      padding-left: 1em;
      font-size: 64px;
      padding-top: 0;
      margin-top: 2em;
    }
    .tag-line {
      font-size: 45px;
      padding-left: 15px;
    }
    .light-row img {
      margin-right: 10px;
    }
  }
}

/* On screens that are 769px or less,  */
@media all and (max-width: 769px) {
  .exa-banner {
    background: url("/sites/all/themes/fxl_responsive/images/banners/exa/exa-bg-iPhone.png");
    position: relative;
    max-width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    height: 900px;
    h2 {
      margin-left: 0px;
      font-size: 60;
      padding-top: 0;
      margin-top: 0;
    }
    .tag-line {
      font-size: 45px;
      padding-left: 15px;
    }

    .p-one,
    .p-two {
      display: none;
    }

    .btn.exa_cta {
      display: flex;
      width: 100%;
      padding-left: 0;
      margin-bottom: 0;
    }
    .light-row {
      margin-left: auto;
      margin-right: auto;
      padding-right: 0;

      .light-row img {
        display: inline;
        margin-top: 8em;
        height: auto;
        width: 140px;
      }
      .light-card p {
        display: none;
      }
    }
  }
}

/* screens 696 and below */

@media all and (max-width: 696px) {
  .exa-banner {
    h2 {
      padding-left: 40px;
      font-size: 57px;
    }
    .tag-line {
      padding-left: 0;
    }

    .light-row {
      display: flex;
      text-align: center;
      justify-content: space-evenly;

      .light-card p {
        display: none;
      }
    }
  }
}
/* screens 642 and below */
@media all and (max-width: 642px) {
  .exa-banner {
    h2 {
      padding-left: 28px;
      font-size: 50px;
    }
    .tag-line {
      font-size: 40px;
      padding-left: 19px;
      margin-left: 0;
    }
  }
}
/* screens 634 and below */

@media all and (max-width: 634px) {
  .exa-banner {
    .light-row {
      margin-top: 2em;
      .eww,
      .eig {
        height: auto;
        width: 140px;
        margin-right: 0;
      }
    }
  }
}
/* screens 559 and below */

@media all and (max-width: 559px) {
  .exa-banner {
    background: url("/sites/all/themes/fxl_responsive/images/banners/exa/exa-background-iphone-se.jpg");
    position: relative;
    max-width: 100%;
    margin-top: 6px;
    margin-bottom: 7px;
    display: flex;
    flex-direction: column-reverse;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    height: 900px;
    h2 {
      padding-left: 14px;
      font-size: 43px;
    }
    .tag-line {
      font-size: 33px;
      padding-left: 17px;
      margin-left: 0;
    }
    .light-row {
      display: none;
    }
  }
}
/*  screens 559 and below */
@media all and (max-width: 559px) {
  .exa-banner {
    max-width: 100vw;
    margin-top: 6px;
    margin-bottom: 7px;
    height: 900px;
    background-position: top center;
    h2 {
      font-size: 38px;
    }
    .tag-line {
      font-size: 30px;
    }
  }
}

@media all and (max-width: 483px) {
  .exa-banner {
    height: 754px;
    max-width: 100%;
  }
}
@media all and (max-width: 483px) {
  .exa-banner {
    height: 720px;
  }
}

@media all and (max-width: 483px) {
  .exa-banner {
    height: 720px;
  }
}

@media all and (max-width: 416px) {
  .exa-banner {
    height: 668px;
    max-width: 100%;
    margin-top: 6px;
    margin-bottom: 7px;

    h2 {
      font-size: 32px;
    }
    .tag-line {
      font-size: 26px;
    }
  }
}
/* screens 375 and below */
@media all and (max-width: 375px) {
  .exa-banner {
    background: url("/sites/all/themes/fxl_responsive/images/banners/exa/exa-background-iphone-se.jpg");
    position: relative;
    max-width: 100%;
    margin-top: 6px;
    margin-bottom: 7px;
    height: 668px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
    back h2 {
      font-size: 32px;
      margin-top: 0;
    }
    .tag-line {
      font-size: 26px;
    }
  }
}

.sf-banner {
  background-image: url("/sites/all/themes/fxl_responsive/images/banners/sf/FX-325-SF_photoLibrary.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 768px;
  position: relative;
  @include for-size(phone-down) {
    background-image: url("/sites/all/themes/fxl_responsive/images/banners/sf/SF_106_MOBILE-RT.jpg");
    min-height: 597px;
    background-size: cover;
  }

  &:before {
    content: "";
    height: 768px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.65) 0, rgba(0, 0, 0, 0) 800px);
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.65) 0, rgba(0, 0, 0, 0) 800px);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.65) 0, rgba(0, 0, 0, 0) 800px);
    @include for-size(phone-down) {
      display: none;
    }
  }

  &-content {
    margin: 0 26px 0.5em 92px;
    position: absolute;
    z-index: 2;
    top: 56%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #fff;
    max-width: 700px;
    @include for-size(phone-down) {
      position: static;
      margin: 0;
      padding: 65px 20px 0;
      max-width: 100%;
      -ms-transform: none;
      transform: none;

      .button.primary {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
      }
    }

    h2 {
      font-size: 54px;
      color: #fff;
      text-transform: uppercase;
      margin-bottom: 0.2em;
      @include for-size(phone-down) {
        font-size: 32px;
      }
    }

    p {
      font-size: 32px;
      line-height: 1.2em;
      font-style: italic;
      @include for-size(phone-down) {
        font-size: 26px;
      }
    }

    .fx-orange {
      color: #ee7623;
    }

    .button.primary {
      padding: 1.3em 5.5em;
    }
  }
  .cover-link {
    z-index: 3;
  }
}
// FC-GW Homepage Banner
.fc-gw-banner {
  background: linear-gradient(rgba(0, 16, 36, 0) 55%, rgba(0, 9, 27, 0.8) 75%),
    url("/sites/all/themes/fxl_responsive/images/banners/fc-gw/fc-gw-bg-image-1.jpg"), #000;
  background-size: cover;
  background-position: 100% 0%;
  background-repeat: no-repeat;
  height: 100%;

  @include for-size(phone-down) {
    background: linear-gradient(rgba(0, 16, 36, 0) 50%, rgba(0, 9, 27, 0.8) 60%),
      url("/sites/all/themes/fxl_responsive/images/banners/fc-gw/fc-gw-bg-image-mobile.png"), #000;
    background-position: center right;
    background-size: 125%;
  }

  #fc-gw-text {
    display: flex;
    align-items: flex-end;
    position: absolute;
    bottom: 2em;
    width: 100%;

    .text_left {
      width: 70%;
      padding: 1em 4em;

      h2,
      h3 {
        color: white;
        padding: 0px 20px;
        font-weight: 100;
      }
      h2 {
        text-transform: uppercase;
        em {
          color: #ee7623;
          font-style: normal;
        }
      }
    }
    .text_right {
      width: auto;
      margin: auto;
    }
    @include respond-to(max-width, 991px) {
      flex-wrap: wrap;
      bottom: 0;
      a.button {
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: 0;
        margin: 0;
        font-size: 0.85rem;
      }

      .text_right {
        width: 100%;
      }
      .text_left {
        width: 100%;
        padding: 3em 0;
        @include for-size(phone-down) {
          padding: 1em 0 3em 0;
        }
      }
    }
  }

  #fc-gw-product {
    position: absolute;
    right: 4%;
    top: 50%;
    width: 300px;
    padding: 0 1em;

    @include respond-to(min-width, 1425px) {
      top: 55%;
    }
    @include respond-to(min-width, 1700px) {
      top: 65%;
      right: 6%;
    }

    img {
      display: none;
    }
    @include for-size(phone-down) {
      top: 125px;
      height: 25%;
      margin: auto;
      text-align: center;
      width: 200px;

      img {
        display: block;
        margin: auto;
      }
    }

    .fc-gw-caption {
      font-size: 1.2em;
      color: white;
      text-align: center;

      @include for-size(phone-down) {
        margin-top: 1em;
      }
    }
  }
  .hero_container {
    @include for-size(phone-down) {
      display: block;
    }
  }
}
//Cora Banner
#homepage-hero.cora {
  background-color: #003c58;
  background-image: none;
  padding-top: 0;
  min-height: unset;
}

.cora {
  padding-bottom: 3em;

  .cora-top {
    height: 425px;
    width: 100%;
    background-image: url('https://www.fxl.com/sites/fxl.com/files/_banners/cora/fx-cora-desktop-bg.jpg');
    background-size: 100%;
    background-position: bottom center;
    background-repeat: no-repeat;

    @include for-size(tablet-down) {
      background-image: url('https://www.fxl.com/sites/fxl.com/files/_banners/cora/fx-cora-tablet-bg.jpg');
      height: 600px;
    }

    @include for-size(phone-down) {
      background-image: url('https://www.fxl.com/sites/fxl.com/files/_banners/cora/fx-cora-phone-only-bg.jpg');
      height: 365px;
    }
  }

  .hero-bottom {
    color: #fff;
    width: 100vw;
    margin-top: 3em;

    .left {
      width: 75%;

      @include for-size(tablet-down) {
        width: 100%;
      }
    }

    .right {
      width: 25%;
      margin-left: 2em;

      .button {
        padding-left: 2.2em;
        padding-right: 2.2em;
      }

      @include for-size(tablet-down) {
        width: 100%;

        .cta {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }

    p {
      font-size: 40px;
      text-transform: uppercase;
      margin-bottom: 0.2em;
      line-height: 1.1 !important;
      font-weight: 100;
    }

    .cora-sub-text {
      font-size: 26px;
      text-transform: none;
      font-style: italic;
    }
  }
}

//Evo Collection
#homepage-hero.evo-collection {
  background-color: #30578b;
  background-image: none;
  min-height: 750px;
  background-image: url('/sites/all/themes/fxl_responsive/images/banners/evo/evo-desktop-bg.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center 115px;

  @media screen and (max-width: 768px) {
    min-height: 700px;
      background-image: url('/sites/all/themes/fxl_responsive/images/banners/evo/evo-mobile-bg.jpg');
  }
}
.evo-collection {
  .evo-top  {
    .cover-link {
      z-index: 3;
    }
    video.desktop {
      width: 100vw;
      height: 600px;
      object-fit: cover;
      position: absolute;
      left: 0;
      right: 0;
      top: 115px;
      bottom: 0;
      z-index: 1;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    video.mobile {
      display: none;

      @media screen and (max-width: 768px) {
        display: block;
        width: 100vw;
        height: 575px;
        object-fit: cover;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }
  .hero-bottom {
    background-color: #000;
    width: 100vw;
    position: absolute;
    bottom: 0px;
    z-index: 2;

    @media screen and (max-width: 768px) {
      bottom: -20px;
    }

    .left {
      padding-top: 1em;
      padding-right: 1.5em;
      padding-left: 2.5em;
      @media screen and (max-width:768px) {
        padding-left: 1.5em;
      }

      h2 {
        color: #95B6C6 !important;
        font-size: clamp(1.75rem, 2.5vw, 4rem);
        margin-bottom: .125em;
      }
      span {
        color: #fff;
      }
      p {
        font-size: clamp(1rem, 2vw, 1.75rem);
        font-style: italic;
        margin-bottom: 0;
        padding-bottom: .75em;

        @media screen and (max-width: 991px) {
          display: none;
        }
      }
    }
    .right {
      width: 20%;
      @media screen and (max-width: 991px) {
        float: none !important;
        width:100%;
      }
      .button {
        background-color: #d29d1b;
        color: #000;
        padding-right: 2.5em;
        padding-left: 2.5em;
        text-wrap: nowrap;
        margin-top: 15%;
        transition: none;

        @media  screen and (max-width: 991px) {
          width: 100vw !important;
          margin-top: 2em;
          margin-bottom: 0;
        }
      }
    }
  }
}

// FXL Collections Homepage
#homepage-hero.collections {
  background-color: #fff;
  background-image: none;
  padding-top: 115px;
  margin-bottom: 0;
  padding-bottom: 0;
    @media screen and (max-width: 768px) {
      padding-top: 0px;
    }

  .collections-top{
    min-height: 600px;
    background-image: url('/sites/all/themes/fxl_responsive/images/banners/collections_banner/collections_hero.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media screen and (max-width: 991px) {
      min-height: 400px;
    }
    @media screen and (max-width: 600px) {
      background-image: url('/sites/all/themes/fxl_responsive/images/banners/collections_banner/collections_hero_m.png');
      width: 100%;
      min-height: 75vh;
    }
  }

  .collections-content {
    h2 {
      color: #222;
      font-size: 42px;
      font-weight: 500;
    }

    p {
      font-size: 22px;
      color: #222;
      padding-bottom: 2em;
    }
  }
}
.collections-content {
  max-width: 1180px;
  margin: 0 auto;
  padding: 3em 1em;


  .collections-tiles {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    align-items: center;
    padding-bottom: 3em;

    .tile {
      width: 33.3333%;

      img {
        width: 100%;
        margin: 0 auto;
        padding: 1em;

        &:hover {
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
        }
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
    .collections_cta {
      display: block;
      width: 315px;
      margin: auto;

      @media screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }

// PX-150 Homepage Banner
.px-150-homepage-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  background-image: url(/sites/all/themes/fxl_responsive/images/banners/px-150/Nate_Cottage_033-sky-Desktop.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 46.875vw;
  min-height: 700px;
  max-height: 1000px;
  width: 100%;

  @media screen and (max-width: 768px) {
    background-image: url(/sites/all/themes/fxl_responsive/images/banners/px-150/Nate_Cottage_033-Mobile.jpg);
    height: 40vh;
  }

  .banner-mobile-only {
    display: none !important;

    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  .button.cta {
    position: absolute;
    bottom: 20px;
    left: 41%;

    @media screen and (max-width: 768px) {
      position: static;
      order: 3;
      width: 100%;
      bottom: 0;
      left: 0;
    }
  }
}

.px-150-img {
  max-width: 41%;
  position: absolute;
  width: 41%;
  bottom: 94px;
  left: 0;
  z-index: 2;
  text-align: right;

  @media screen and (max-width: 768px) {
    position: static;
    order: 1;
    max-width: 60%;
    width: 60%;
    margin-bottom: -8px;
  }

  img {
    max-width: 100%;
    height: auto;
    padding-right: 5%;
    padding-left: 5%;

    @media screen and (max-width: 768px) {
      position: relative;
      margin-left: 0;
      bottom: -8%;
    }
  }

}

.px-150-content {
  background: rgba(0, 60, 88, 0.75);
  position: absolute;
  bottom: 130px;
  left: 0;
  width: 100%;

  @media screen and (max-width: 768px) {
    position: static;
    background: #003C58;
    order: 2;
    padding: 35px 10px 5px;
    width: 100%;
  }

    .desktop-text {
      display: block;
    }

    .mobile-text {
      display: none;
    }

  @media screen and (max-width: 768px) {
      .desktop-text {
        display: none;
      }

      .mobile-text {
        display: block;
      }
  }

  p {
    margin-left: 41%;
    color: #FFF;
    font-size: 2.604vw;
    margin-bottom: 10px;
    margin-top: 10px;
    line-height: 1.3;

    @media screen and (max-width: 768px) {
      margin-left: 0;
      font-size: 9vw;
    }

    strong {
      color: #F5822D;
      font-weight: 500;
    }
  }
}

// MP 30 & 31 Homepage Banner
.mp-3031-hp-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: url(/sites/all/themes/fxl_responsive/images/banners/mp-30-31/MP-30_31_105_RT-Desktop.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 900px;
  max-height: 100%;
  width: 100%;

  @media screen and (max-width: 768px) {
    background-image: url(/sites/all/themes/fxl_responsive/images/banners/mp-30-31/MP-30_31_104_RT-Mobile.jpg);
    height: 702px;
    justify-content: flex-start;
  }

  .banner-mobile-only {
    display: none !important;

    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  // All banner content
  .mp-3031-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 5%;
    width: 1075px;
    max-width: 75%;

      @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        max-width: 100%;
      }

    // Round image of MP-30 & 31
    .mp-3031-image {
      max-height: 250px;
      margin-bottom: 40px;
      display: flex;

      img {
        max-width: 100%;
        max-height: 250px;

        @media screen and (max-width: 768px) {
          max-height: 200px;
        }
      }

      @media screen and (max-width: 768px) {
        justify-content: flex-end;
      }
    }

    // Text blocks
    .mp-3031-text {
      p {
        margin: 0;
      }

      p.desktop-text,
      p.mobile-text {
        color: #FFF;
        margin-bottom: 20px;

        strong {
          color: #F5822D;
          font-weight: 500;
        }
      }
    }

      p.desktop-text {
        display: block;
        font-size: clamp(2.5rem,3vw,3.1rem);
        line-height: inherit;

        span {
          font-size: clamp(2.4rem,1.8vw,5rem);
          line-height: inherit;
        }
      }

      p.mobile-text {
        display: none;
        font-size: 36px;
        line-height: 42px;

        span {
          font-size: 26px;
          line-height: 32px;
        }
      }

      @media screen and (max-width: 768px) {
        p.desktop-text {
          display: none;
        }

        p.mobile-text {
          display: block;
        }
      }

    // Button
    .banner-button {
        margin: 40px 0 50px 0;

      .button.cta {
        position: relative;
        left: 0;
        bottom: 0;

        @media screen and (max-width: 768px) {
          position: absolute;
        }
      }
    }
  }
}

// PP-36 Homepage Banner
.pp36-hp-banner {
  height: 880px;
  overflow: hidden;
  position: relative;

  .pp36-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    background-color: #003C58;
  }

  .pp36-text-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex: 1;

    .pp36-text-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 5%;
      max-width: 550px;

      @media screen and (max-width: 768px) {
        margin: 5%;
      }

      p {
        font-size: clamp(32px, 3vw, 45px);
        color: #fff;
        line-height: 1.3em;
        margin-bottom: 20px;

        @media screen and (max-width: 768px) {
          font-size: clamp(38px, 8vw, 54px);
        }
      }

      p.sub-text {
        font-size: clamp(28px, 3vw, 35px);
        color: #F4A469;
        line-height: 1.2em;

        @media screen and (max-width: 768px) {
          font-size: clamp(24px, 6vw, 38px);
        }
      }
    }

    img.pp36-img {
      max-height: 300px;
      width: auto;
      align-self: flex-start;
      bottom: -65px;
      position: relative;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }

  }

  .pp32-img-cover{
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .banner-desktop-only {
      display: block;
    }

    .banner-mobile-only {
      display: none;
    }
  }

  .button.cta {
    position: relative !important;
    bottom: 20px;
    left: 0% !important;

    @media screen and (max-width: 768px) {
      position: static;
      order: 3;
      width: 100%;
      bottom: 0;
      left: 0;
    }
  }

  @media screen and (max-width: 768px) {
    .pp36-container {
      flex-direction: column-reverse;
    }

    .pp32-img-cover {

      .banner-desktop-only {
        display: none;
      }

      .banner-mobile-only {
        display: block;
      }
    }
  }
}

//RP-32,33,34,35 Homepage Banner
#homepage-hero.rp-banner {
  height: 700px;
  padding-top: 0;

  @media screen and (max-width: 768px) {
    height: 90vh;
    min-height: 900px;
  }

  .rp-banner-top {
    height: 500px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      height: 80vh;
    }

    .left {
      background-image: url('/sites/all/themes/fxl_responsive/images/banners/rp/rp-banner-left.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
      width: 50%;
      height: 100%;

    }

    .right {
      background-image: url('/sites/all/themes/fxl_responsive/images/banners/rp/rp-banner-right.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position-y: top;
      background-position-x: center;
      width: 50%;
      height: 100%;
      border-left: 6px solid $color_fx_blue;

      .product-award {
        position: absolute;
        top: 20%;

        img {
          max-width: 260px;
        }
        @media screen and (max-width: 991px) {
          display: none;
        }
      }
      .product-award-mobile {
        display: none;

        @media screen and (max-width: 991px) {
          display: block;

          img {
            display: block;
            max-width: 240px;
            margin: auto;
          }
        }
      }
    }
    @media screen and (min-width: 1368px) {
      .right,
      .left {
        background-position-x: right;
        background-position-y: 35%;
      }
    }

    @media screen and (max-width: 768px) {
      .right {
        width: 100%;
        height: 45%;
        border-left: none;
        background-position-y: center;
      }

      .left {
        border-bottom: 4px solid $color_fx_blue;
        width: 100%;
        height: 55%;
        min-height: 270px;
        background-position-y: center;
      }
    }

    .rp-32-33,
    .rp-34-35 {
      position: relative;
      top: 35%;
      left: 45%;
      width: 40%;
      height: 70%;
      align-content: center;
      text-align: center;

      h5 {
        color: #fff;
        font-size: 1.25em;
      }

      span {
        color: #fff;
        font-size: 1.25em;
        font-style: italic;
      }

      img {
        max-width: 175px;
      }
      div {
        height: 175px;
      }

      @media screen and (max-width: 990px) {
        top: 20%;
        width: 50%;

        h5,
        span {
          font-size: 1em;
        }

        img {
          display: none;
        }
        div {
          display: none;
        }
      }
      @media screen and (max-width: 768px) {
        width: 65%;
        left: 30%;
      }
    }
    .rp-32-33 {
      @media screen and (max-width: 9991px) {
        top: 35%;
      }

      @media screen and (max-width: 768px) {
        height: auto;
        top: 0;
        margin-top: 2em;
      }
    }
  }

  .rp-text {
    width: 100%;
    background: $color_fx_blue;
    color: #fff;
    padding: 1em 0;
    text-align: center;
    position: relative;

    h2 {
      margin-bottom: 0;

      em {
        font-weight: 400;
      }

      strong {
        font-weight: 500;
      }
    }

    .rp-mobile {
      display: none;
    }

    h3 {
      color: #F4A469;
      font-style: italic;
      padding: 0 1em;
    }

    .button {
      bottom: -1em;
    }

    @media screen and (max-width: 768px) {
      text-align: start;
      padding: 1em 0 0 0;

      .rp-desktop {
        display: none;
      }

      .rp-mobile {
        display: block;
        padding: 0 16px;
      }

    }
  }

}
// FXL LMS Homepage Banner
#homepage-hero.homepage-banner {
  &.fx-intro {
    display: flex;
    height: 700px;

    @media (max-width: 990px) {
      display: block;
      height: auto;
      padding-top: 0;
    }

    .image {
      background-image: url(/sites/all/themes/fxl_responsive/images/banners/fxl-training/FXL-University-Screen.jpg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 50%;

      @media (max-width: 990px) {
        width: 100%;
        height: 350px;
        background-size: 100%;
      }
    }
    .copy {
      background: url(/sites/all/themes/fxl_responsive/images/banners/fxl-training/fx-background.jpg);
      width: 50%;
      padding: 50px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      @media (max-width: 990px) {
        width: 100%;
        background: $color_fx_blue;
      }

      .copy-container {
        position: relative;
        top: 20%;

        h2 {
          color: #F4A469;
          font-style: italic;
          font-weight: normal;
          font-size: 52px;
          margin-top: 60px;
          text-transform: none;

          span {
            white-space: nowrap;
            color: #fff;
            font-style: normal;
            display: block;

            @media (max-width: 990px) {
              white-space: wrap;
            }
          }

          @media (max-width: 1400px) {
            font-size: 40px;
          }

          @media (max-width: 1200px) {
            font-size: 35px;
          }

          @media (max-width: 990px) {
            font-size: 46px;
            margin-top: 0;
          }
        }

        p {
          color: #F4A469;
          font-size: 30px;

          @media (max-width: 1400px) {
            font-size: 25px;
          }
        }

        .button.secondary {
          background: #fff;
          color: $color_fx_orange;
          display: inline-block;
          padding: 10px 30px 9px;
          margin-top: 50px;

          @media (max-width: 990px) {
            margin-top: 15px;
            margin-bottom: 0;
            position: absolute;
            width: 100vw;
            left: -50px;
            background: $color_fx_orange;
            color: white;
          }
        }
      }
    }
  }
}

// Legends of Landscape Homepage Banner
#homepage-hero.fx-legends-banner {
  background-image: url(/sites/all/themes/fxl_responsive/images/banners/legends/legends-fxl.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #28438B;
  min-height: 800px;

  @media (max-width: 990px) {
    background-image: url(/sites/all/themes/fxl_responsive/images/banners/legends/legends-fxl-tablet.jpg);
  }

  @media (max-width: 480px) {
    background-image: url(/sites/all/themes/fxl_responsive/images/banners/legends/legends-fxl-mobile.jpg);
    min-height: 775px;
  }

  .copy {
    display: block;
    width: 100%;
    justify-content: center;
    align-items: center;

    .copy-container {
      margin: auto;
      color: white;
      text-align: center;
      width: 100%;
      position: absolute;
      top: 57%;

     @media (max-width: 990px) {
        top: 50%;
        padding: 0 15px;
      }

      @media (max-width: 767px) {
        top: 48%;
      }

      @media (max-width: 480px) {
        top: 43%;
      }

      @media screen and (min-width: 1300px) {
        top: 54%;
      }

      h2 {
        font-family: 'Press Start 2P', Arial, Helvetica, sans-serif;
        -webkit-font-smoothing: antialiased;
        font-size: clamp(26px, 2.5vw, 35px);
        font-style: normal;
        font-weight: 400;
        letter-spacing: -1.2px;

        @media (max-width: 767px) {
          font-size: clamp(21px, 3.75vw, 26px);
        }

        @media (max-width: 480px) {
          font-size: clamp(21px, 5.5vw, 26px);
        }
      }

      p {
        text-align: center;
        font-family: "Sero Pro";
        font-size: clamp(26px, 2.25vw, 35px);
        font-style: normal;
        font-weight: 500;
        letter-spacing: 1.2px;

        @media (max-width: 767px) {
          font-size: clamp(21px, 3.75vw, 26px);
        }

        @media (max-width: 480px) {
          font-size: clamp(21px, 5.5vw, 26px);
        }
      }
    }
  }

  .play-button {
    width: 100%;
    margin: auto;
    text-align: center;
    position: absolute;
    bottom: 10%;

    @include for-size(phone-down) {
      bottom: 0px;
    }

    .nes-btn {
      order: 4px solid #414141;
      border: 4px solid #414141;
      clip-path: polygon(0px calc(100% - 4px), 4px calc(100% - 4px), 4px 100%, calc(100% - 4px) 100%, calc(100% - 4px) calc(100% - 4px), 100% calc(100% - 4px), 100% 4px, calc(100% - 4px) 4px, calc(100% - 4px) 0px, 4px 0px, 4px 4px, 0px 4px);
      -webkit-box-shadow: inset -3px -4px 0px #BF8511;
      box-shadow: inset -3px -4px 0px #BF8511;
      background-color: #F1BA2E !important;
      font-family: "Press Start 2P", Arial, Helvetica, sans-serif !important;
      color: #222 !important;
      padding: 10px 15px;
      font-size: 17px;
      display: inline-block;
    }

    @include for-size(phone-down) {
      .nes-btn {
        padding: 8px 0;
        width: 100%;
        margin-bottom: 0;
      }
    }
  }
}

// My Design Color Homepage banner
#homepage-hero.mydesign-color-banner {
  background-image: url(/sites/all/themes/fxl_responsive/images/banners/my-design-color/my-design-color-banner.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #28438B;
  height: 700px;

  .mydesign-color-container {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    align-items: flex-end;
    padding: 0 2em;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .copy {
      width: 50%;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      z-index: 1;

      @media (max-width: 768px) {
        width: 100%;
        padding-top: 4em;
      }
      @media (max-width: 490px) {
        padding-top: 10em;
      }

      h2 {
        color: #FFF;
        text-shadow: 0px 4px 7px rgba(0, 0, 0, 0.35);
        font-family: "Sero Pro";
        font-size: clamp(50px, 3.75vw, 60px);
        font-style: normal;
        font-weight: 400;
        letter-spacing: -1.2px;

        @media (max-width: 991px) {
          font-size: clamp(41px, 3.75vw, 60px);
        }

        @media (max-width: 480px) {
          font-size: clamp(40px, 5.5vw, 60px);
        }
      }

      p {
        color: #FFF;
        font-family: "Sero Pro";
        font-size: clamp(20px, 3vw, 40px);
        font-style: normal;
        font-weight: 400;
        letter-spacing: 1.23px;
      }
      .my-design-color-cta {
        text-transform: capitalize;
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }

  .tablet-container {
    width: 50%;
    height: 100%;
    align-content: end;

    @media (max-width: 768px) {
      width: 100%;
    }

    #slideshow {
      position: relative;
      width: 100%;
      max-width: 800px;
      min-height: 500px;
      margin: auto;
      overflow: hidden;
      bottom: 0;
      @media (max-width: 768px) {
        position: static;
      }
    }

    #slideshow img {
      position: absolute;
      width: auto;
      height: auto;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      bottom: 0;
    }

    #slideshow img.next {
      opacity: 1;
    }
  }
  .my-design-color-cta-mobile {
    display: none;

    @media (max-width: 768px) {
      display: block;
      text-transform: capitalize;
    }
  }
}
// ZW Homepage Banner
#homepage-hero.zw-banner {
  min-height: 700px;

  @media (max-width: 991px) {
    padding-top: 50px;
  }

  .zw-banner-container {
    display: grid;
    grid-template-areas: 'zw-text zw-image';
    grid-template-columns: 40% 60%;

    @media (max-width: 1300px) {
      grid-template-columns: 50% 50%;
    }

    @media (max-width: 768px) {
      grid-template-areas:
        "zw-image"
        "zw-text";
      grid-template-columns: 1fr; // or 100%
    }


    .left {
      background-image: url('/sites/all/themes/fxl_responsive/images/banners/zw-banner/fx-bg.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      height: 825px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      grid-area: zw-text;

      @media (max-width: 768px) {
        flex-wrap: wrap;
        flex-direction: row;
        max-height: 300px;
      }

      .copy {
        color: #fff;
        padding: 40px;
        max-width: 550px;

        @media (max-width: 768px) {
          padding: 40px 20px;
          max-width: 100%;
        }
      }

      h2 {
        font-size: 54px;

        @media (max-width: 768px) {
          font-size: 38px;
        }
      }

      span {
        font-size: 38px;

        @media (max-width: 768px) {
          font-size: 28px;
        }
      }

      .zw-cta {
        margin-top: 2em;
        padding: 0 40px;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    .right {
      background-image: url(/sites/all/themes/fxl_responsive/images/banners/zw-banner/zw-banner.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 825px;
      position: relative;
      grid-area: zw-image;

      @media (max-width: 768px) {
        height: 400px;
        background-position: right;
        background-size: 140%;
      }

      @media (max-width: 480px) {
        background-image: url(/sites/all/themes/fxl_responsive/images/banners/zw-banner/zw-banner-mobile.jpg);
        background-size: cover;
        background-position: center;
      }

      img {
        max-width: 260px;
        position: absolute;
        bottom: 10%;
        left: -15%;

        @media (max-width: 768px) {
          max-width: 215px;
          left: 55%;
          bottom: -5%;
        }

        @media (max-width: 480px) {
          max-width: 150px;
          left: 50%;
          bottom: -25px;
        }
      }
    }
  }

  .zw-cta-mobile {
    display: none;

    @media (max-width: 768px) {
      display: block;
      text-transform: capitalize;
    }
  }
}

// FXL Catalog Banner
#fxlcatalog-hp-banner {
  height: 848px;
  width: 100%;
  background-color: #003C58;

  @media (max-width: 991px) {
    height: 792px;
  }

  a.cover-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: inherit;
    display: block;
  }

  .fxlcatalog-container {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 991px) {
      flex-direction: column;
      align-items: stretch;
    }

    .fxlcatalog-text-section {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 2%;
      width: 35%;
      background-image: url(/sites/all/themes/fxl_responsive/images/banners/fxl-catalog/blue-swish-bg.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;
      height: 100%;
      padding-top: 10%;

      @media (max-width: 991px) {
        background-image: url(/sites/all/themes/fxl_responsive/images/banners/fxl-catalog/blue-swish-mobile.jpg);
        width: 100%;
        justify-content: flex-end;
      }

      .fxlcatalog-text-block {
        display: flex;
        flex-direction: column;
        margin: auto;
        max-width: 650px;

        @media (max-width: 991px) {
          display: flex;
          flex-direction: column;
          margin: 0;
          max-width: 100%;
          align-items: flex-start;
        }

        h1 {
          color: #F4A469;
          font-size: clamp(32px, 2vw, 58px);
          text-transform: uppercase;
          font-weight: 100;

          @media (max-width: 991px) {
            font-size: clamp(38px, 2vw, 48px);
          }
        }

        p {
          color: #fff;
          font-size: clamp(23px, 2vw, 38px);
          line-height: 1.3;

          @media (max-width: 991px) {
            font-size: clamp(32px, 2vw, 58px);
            margin-bottom: 10px;
          }
        }
      }

      a.button.cta {
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 3px;
        text-transform: capitalize;
        width: 315px;

        @media (max-width: 991px) {
          display: none;
        }
      }

    }

    .fxlcatalog-img-block {
      width: 65%;
      height: 100%;
      background-image: url(/sites/all/themes/fxl_responsive/images/banners/fxl-catalog/catalog_banner-desktop.jpg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      @media (max-width: 991px) {
        background-image: url(/sites/all/themes/fxl_responsive/images/banners/fxl-catalog/catalog_banner-mobile.jpg);
        width: 100%;
      }
    }

    a.button.cta.mobile-btn {
      display: none;

      @media (max-width: 991px) {
        display: inline-block;
        text-transform: capitalize;
        border-radius: 0;
        box-shadow: none;
        width: 100%;
        margin-bottom: 0;
      }
    }
  }
}

// Wifi Plug Banner
#wifi-plug-banner {
  width: 100%;
  height: auto;

  .wifi-plug-banner-container {
    position: relative;
    width: 100%;
    height: 848px;
    background: url(/sites/all/themes/fxl_responsive/images/banners/wifi-plug/Oconomowoc_Desktop.jpg) no-repeat center center/cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    overflow: hidden;

    @media (max-width: 767px) {
      background-image: url(/sites/all/themes/fxl_responsive/images/banners/wifi-plug/Oconomowoc_Mobile.jpg);
    }

    a.cover-link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      z-index: 3;
    }

    .content-container {
      position: absolute;
      display: flex;
      bottom: 0;
      width: 100%;
      color: white;
      background: rgba(0, 40, 60, 0.75);
      padding: 20px;

      @media (max-width: 898px) {
        background-color: #003C58;
        padding: 0;
      }

      .wifi-plug-img-container {
        position: relative;
        flex: 1;
        max-width: 300px;
        margin-right: 20px;

        @media (max-width: 1200px) {
          max-width: 50px;
        }

        @media (max-width: 898px) {
          max-width: 15px;
          margin-right: 0;
        }

        img.wifi-plug-image {
          position: absolute;
          left: -24%;
          bottom: -18%;
          max-width: 375px;
          width: auto;
          z-index: 1;

          @media (max-width: 1200px) {
            max-width: 250px;
            left: -115%;
            bottom: 90%;
          }

          @media (max-width: 495px) {
            max-width: 200px;
            left: -200%;
            bottom: 90%;
          }
        }
      }

      .content-text-container {
        margin-left: 2%;
        flex: 2;

        @media (max-width: 1200px) {
          margin-top: 2%;
          margin-right: 0;
          padding: 20px;
        }

        @media (max-width: 767px) {
          margin: 0;
          padding: 25px 0 15px 0;
        }

        h1 {
          color: #fff;
          font-size: clamp(38px, 2.5vw, 54px);
          font-weight: 100;
          margin-bottom: 0;

          @media (max-width: 898px) {
            font-size: clamp(38px, 9vw, 54px);
          }
        }

        p {
          font-size: clamp(28px, 2vw, 34px);

          @media (max-width: 898px) {
            font-size: clamp(28px, 7vw, 34px);
            margin-bottom: 0;
          }
        }

        a.button.cta.desktop-only-btn {
          display: block;
          max-width: fit-content;

          @media (max-width: 767px) {
            display: none;
          }
        }
      }

      .image-container {
        position: relative;
        max-width: 600px;
        flex: 1;
        margin-right: 20px;

        @media (max-width: 1200px) {
          max-width: 50px;
        }

        @media (max-width: 898px) {
          max-width: 15px;
        }

        img {
          position: absolute;
          right: 1%;
          bottom: -20px;
          max-width: 600px;
          display: flex;
          justify-content: flex-end;

          @media (max-width: 1530px) {
            max-width: 300px;
          }

          @media (max-width: 1200px) {
            max-width: 250px;
          }

          @media (max-width: 898px) {
            bottom: 100%;
            max-width: 200px;
          }

          @media (max-width: 495px) {
            max-width: 150px;
            right: 100%;
          }

          &.desktop-only-img {
            display: block;

            @media (max-width: 1530px) {
              display: none;
            }
          }

          &.mobile-only-img {
            display: none;

            @media (max-width: 1530px) {
              display: block;
            }

            @media (max-width: 365px) {
              display: none;
            }
          }
        }
      }
    }
  }

  a.button.cta.mobile-only-btn {
    display: none;

    @media (max-width: 767px) {
      display: inline-block;
      text-transform: capitalize;
      border-radius: 0;
      box-shadow: none;
      width: 100%;
      margin-bottom: 0;
    }
  }

}