//Media Queries
/***********************
phone-only
tablet-portrait-up
tablet-landscape-up
desktop-up
big-desktop-up
***********************/
@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 475px) { @content; }
  } @else if $size == phone-down {
    @media (max-width: 768px) { @content; }
  } @else if $size == tablet-down {
    @media (max-width: 991px) { @content; }
  } @else if $size == desktop-down {
    @media (max-width: 1300px) { @content; }
  } @else if $size == large-desktop-down {
    @media (max-width: 9999px) { @content; }
  }
}

@mixin flexbox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
}

@mixin align-items($value: stretch) {
	@if $value == flex-start {
		-webkit-box-align: start;
		-ms-flex-align: start;
	} @else if $value == flex-end {
		-webkit-box-align: end;
		-ms-flex-align: end;
	} @else {
		-webkit-box-align: $value;
		-ms-flex-align: $value;
	}
	-webkit-align-items: $value;
	-moz-align-items: $value;
	align-items: $value;
}

@mixin justify-content($value: flex-start) {
	@if $value == flex-start {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
	} @else if $value == flex-end {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
	} @else if $value == space-between {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
	} @else if $value == space-around {
		-ms-flex-pack: distribute;
	} @else {
		-webkit-box-pack: $value;
		-ms-flex-pack: $value;
	}
	-webkit-justify-content: $value;
	-moz-justify-content: $value;
	justify-content: $value;
}
