

// used to fence styles to the content type
// body tag
.node-type-webform-hero {
  line-height: 1.1;

  // Site Messages
  .alert-box.success, .alert-box.secondary, .alert-box.alert {
    position: fixed;
    top: 2em;
    z-index: 1000;
    left: 25%;
    right: 25%;
    color: #fff;
    background: #003c58;
    background-color: rgb(0, 60, 88);
    background-color: #003c58;
    text-align: center;
    padding: 2em;

    .close {
      color: #fff;
      opacity: 0.9;
    }
  }

  // Menu
  .l-header {
    background:rgba(0, 77, 107, 0.56);
    height: inherit;
    height: fit-content;

    &.fixed {
      background: #003c58;
    }

    // FXL Logo
    .main_logo a img.logo {
      height: auto;
      max-height: 26px;
      width: auto;
      max-width: auto;
    }


    // Product, Professionals, Homeowners
    .navigation_region {
      font-family: SeroPro;
      font-weight: 500;
      font-style: normal;
    }

    .nav-container{
      //height: 80px;
      @media screen and (min-width: 768px) {
        max-height: 80px;
        height: 8.25vw;
      }
    }

    .main-menu-slideout {
      margin-top: 113px;
    }

    .navigation_region ul.menu>li {
      margin-bottom: 0px;
    }
  }

  .headers_container {
    padding-top: 0px;
  }

  .headers_container .sub_header {
    background-color: transparent;
    position: relative;
  }

  // Landscape & Architectural Lighting
  .sub_header_inner {
    position: absolute;
    left: 0;

    @media screen and (min-width: 768px) {
      //top: 12.1vw;
      display: none;
    }
    @media screen and (min-width: 960px) {
      top: 116px;
      display: inline-block;
    }
  }
}



// Class for the article tag
.node-webform-hero {
  position: relative;
}

// nested divs under the main tag
.webform_hero_inner {

  @media screen and (min-width: 769px) {
    margin-top: 2em;
  }

  .large-12.main.columns {
    max-width: 100%;
    width: 100%;
    padding:0;
  }
}

// Hero-Banner wrapper
.field-name-field-hero-banner {
  img {
    width: 100%;
  }
}

// Header text wrapper
.field-name-field-hero-header {
  position: unset;

  text-align: center;
  font-size: 7.5vw;
  font-family: SeroPro;
  font-weight: 500;
  font-style: italic;
  line-height: 1.2;

  padding: .75em 0;
  background: rgba(0, 60, 88, 1);
  color: rgba(255, 255, 255, 1);

  @media screen and (min-width: 768px) {
    position: absolute;
    text-align: left;
    width: 58.75%;
    top: 11.75vw;
    font-size: 4vw;
    padding: 2% 0% 2% 8%;
    background: rgba(0, 77, 107, .7);
  }
  // vw font sizing grows past the design at this point.
  @media screen and (min-width: 1492px) {
    font-size: 61px;
  }
}

// Hero-Banner Orange Header Text
.web-hero-header-color {
  color: #F5822D;
}
// Hero-Banner Italic header text
.web-hero-header-italic {
  font-family: SeroPro;
  font-weight: 300;
  font-style: italic;
}

// Hero-Banner Logo
.field-name-field-hero-branding {
  position: unset;
  background: rgba(0, 60, 88, 1);
  margin: 0 auto;

  padding: 1em 2em 2.25em 2em;
  text-align: center;

// makes the logo fill the div w a max width
  @media screen and (max-width: 767px) {
    img {
      width: 100%;
      max-width: 640px;
    }
  }

  @media screen and (min-width: 768px) {
    position: absolute;
    background: transparent;
    left: 8%;
    top: 25.5vw;
    max-width: 700px;
    max-height: 144px;
    width: 32vw;
    padding: 1em 0 0 0;
  }

}

// body wrapper for content below Hero-Banner
.webform-hero-body-wrapper {
  max-width: 1100px;
  margin: 0 auto;
  padding: 1.5em 2em;
  line-height: 1.6;
}

// Page Title H1 field
.webform-hero-page-title {
  font-size: 4.95vw;
  line-height: 1.25;

  @media screen and (min-width: 768px) {
    font-size: 4.95vw;
  }
  @media screen and (min-width: 1220px) {
    font-size: 3.75em;
  }
}

// Page Body P field
.webform-hero-body {
  max-width: 52em;
}

// Global Setting b/c its a global fix for clearing the floats
.webform-client-form {
  &:after {
    content: "";
    clear: both;
    display: table;
  }
}

// webform wrapper
.webform-hero-body-wrapper {
  border-style: none;
  max-width: 1100px;
  padding: 2em 4em;
  max-width: 68.75em;

  // Name and Email Input
  .form-text {
    max-width: 23em;
    display: block;
  }
  // Date Dropdown
  .form-select {
    max-width: 20em;
    display: block;
    padding-top: .25em;
  }
  // Wrapper for Step 2 Instructions
  .webform-component--webform-wrapper--photo-instructions {
    margin-top: 3em;
    max-width: 40em;
  }
  .webform-hero-text-title {
    padding-top: 2em;
  }

    // Photo upload page
    .webform-hero-text {
      max-width: 45.5625em;
    }

  .webform-hero-body, .webform-hero-text-p {
      //padding-right: 7em;
      max-width: 45.5625em;
  }
}

// Bullet List of Photo requirements
.webform-hero-list {
  list-style-position: inside;
  margin: .75em 0 1.25em 0;
}

// image upload wrapper
.webform-hero-photo-upload {
  margin-bottom: 0;
}

// wrapper for the Image upload
.webform-hero-photo-upload {
  .form-file {
    display: block;
  }
  .form-submit {
    margin: 1em 0;
  }
}

.webform-hero-trademark {
  text-align: center;
}
