/* Import */
//@use '_mixins' as *;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* Variables --------------------------------------------------------- */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


$desktop_max_width: 1190px;
$desktop_max_width_products: 1530px;

$gutter: 0.9375em;

$mobile_header_height: 50px;


/* Colors */
$color_fx_orange: #EE7623;
/* Orange */

$color_fx_blue: #003c58;
/* Blue */
$color_fx_blue_6_lighter: #0f4a66;
/* Blue - 6% Lighter */
$color_fx_blue_12_lighter: #1f5b77;
/* Blue - 12% Lighter */

$color_fx_gray: #9b9a9a;
/* Dark Gray - was #3b3b3b */
$color_fx_warm_gray: #faf9f8;
/* Light Gray - was #e4e1dd */
$color_fx_warm_gray_80_lighter: rgba(#faf9f8, 0.2);
/* Light Gray */

$color_fx_off_canvas_bg: #63717a;
$color_fx_off_canvas: #5f6b72;

/* Main font family */
$font-family-sero: "Sero",
Arial,
sans-serif;


/* Main Font Weights */
$font-weight-light: 400;
$font-weight-medium: 500;
$font-weight-bold: 800;
$font-weight-black: 900;


/* Font weights to remember:

400 - light
500 - medium
800 - bold
900 - black

/* Animations */
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

/* Temp */

#homepage-hero.vo {
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  min-height: 810px;
  background-image: none !important;
}
#homepage-hero.vo .flex {
  max-width: 100% !important;
}
#homepage-hero.vo h2 {
  color: #F5822D;
  font-weight: 300;
}
#homepage-hero.vo h2 span {
  font-weight: 500;
}
#homepage-hero.vo h3 {
  margin-bottom: 1.25em;
  font-weight: 500;
}
#homepage-hero.vo .left,
#homepage-hero.vo .right {
  position: relative;
}
#homepage-hero.vo .degree {
  position: absolute;
  top: 25%;
  right: 2%;
  max-width: 175px;
}
#homepage-hero.vo .left .degree {
  background: #1B4459F2;
  border-radius: 0% 0% 100% 100%;
  padding: 0px 25px 10px 30px;
  width: 198px;
  height: 120px;
  top: 29%;
}
#homepage-hero.vo .bottom {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
  #homepage-hero.vo .bottom:before {
  content: '';
  display: block;
  position: absolute;
  top: -100px;
  width: 130%;
  height: 650px;
  background: #003C58;
  text-align: center;
  border-radius: 75%;
  z-index: 0;
  left: -15%;
  right: -15%;
  }
  #homepage-hero.vo .bottom:after {
  content: '';
  display: block;
  position: absolute;
  background: #003C58;
  width: 100%;
  height: 150px;
  bottom: 0;
  }
  #homepage-hero.vo .copy {
  z-index: 1;
  position: relative;
  padding: 0% 15% 3.5% 15%;
  }
  @media screen and (min-width: 1350px) {
    #homepage-hero.vo {
      min-height: 875px;
    }
  }
  @media screen and (min-width: 1500px) {
    #homepage-hero.vo {
      min-height: 915px !important;
    }
  }
  @media screen and (min-width: 1700px) {
    #homepage-hero.vo {
      min-height: 950px !important;
    }
  }
@media screen and (max-width: 1325px) {
  #homepage-hero.vo .hero-inner {
    position: relative !important;
    bottom: initial !important;
    overflow: hidden !important;
  }
  #homepage-hero.vo .hero-inner .flex {
   padding-bottom: 280px;
  }
}
  @media screen and (max-width: 1100px) {
    #homepage-hero.vo {
      min-height: 675px;
    }
    #homepage-hero.vo h2 {
      font-size: 2em;
    }
    #homepage-hero.vo h3 {
      font-size: 1.25em;
    }
    #homepage-hero.vo .hero-inner .flex {
     padding-bottom: 230px;
    }
  }
  @media (max-width: 991px) {
    #homepage-hero.vo {
      min-height: 600px;
    }
    #homepage-hero.vo .bottom:before {
      height: 450px;
      top: -135px;
    }
    #homepage-hero.vo .flex {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
    }
    #homepage-hero.vo .degree {
       display: none;
    }
    #homepage-hero.vo .copy .cta {
      display: none;
    }
    #homepage-hero.vo .copy {
      z-index: 1;
      padding: 0% 17% 3% 17%;
      position: relative;
      top: -50px;
    }
  }
  @media (max-width: 767px) {
    #homepage-hero.vo {
      min-height: 435px;
    }
    #homepage-hero.vo .bottom {
      bottom: 2.5%;
    }
    #homepage-hero.vo .bottom:before {
      top: -75px;
    }
    #homepage-hero.vo .copy {
      padding: 10% 12% 0% 12%;
      top: -65px;
    }
    #homepage-hero.vo .bottom:before {
    height: 550px;
    width: 150%;
    left: -25%;
    right: -25%;
    }
    #homepage-hero.vo h2 {
      font-size: 1.5em;
    }
    #homepage-hero.vo h3 {
      display: none;
      font-size: 1.15em;
    }
  #homepage-hero.vo .hero-inner .flex {
   padding-bottom: 150px;
  }
}
  @media (max-width: 575px) {
    #homepage-hero.vo {
      min-height: 380px;
    }
    #homepage-hero.vo .copy {
      top: -65px;
    }
    #homepage-hero.vo .copy {
      padding: 8% 10% 0% 10%;
    }
    #homepage-hero.vo .bottom {
      bottom: 3.5%;
    }
    #homepage-hero.vo .bottom:before {
      height: 350px;
    }
  }
@media (max-width: 450px) {
    #homepage-hero.vo {
      min-height: 345px;
    }
  #homepage-hero.vo .hero-inner .flex {
   padding-bottom: 160px;
  }
  #homepage-hero.vo h2 {
    font-size: 1.4em;
  }
  #homepage-hero.vo .copy {
    top: -35px;
    padding: 6% 10% 0% 10%;
  }
}

#homepage-hero.srp {
  position: relative;
  max-width: 100%;
  position: relative;
  padding-top: 25px;
  overflow: hidden;
  background: none;
}

#homepage-hero.srp .hero-inner {
  max-width: 90%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

#homepage-hero.srp h2 {
  margin-bottom: -5px;
}

#homepage-hero.srp h3 {
  font-style: italic;
  opacity: .85;
  margin-bottom: 0;
}

#homepage-hero.srp .hero-inner {
  position: absolute;
  bottom: 65px;
}

#homepage-hero.srp video {
  position: relative;
  width: 100%;
  position: absolute;
  left: 0;
  top: -150px;
  z-index: 0;
}

/*
body:not(.front) .inner_main {
  margin: 0 auto;
  max-width: 100%;
  position: relative;
}





.large-12.main.columns {
  float: none;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
  max-width: 100%;
}
*/

#homepage-hero.srp .flex .hero-left {
  position: relative;
  z-index: 1;
  flex: 0 0 55%;
}
#homepage-hero.srp .flex .hero-right {
  flex: 0 0 45%;
  margin-top: 10%;
}

#homepage-hero.srp .hero-right ul {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
}

#homepage-hero.srp .hero-right li {
  padding: 1.5%;
  margin-right: 3%;
  list-style-type: none;
}

#homepage-hero.srp .hero-right li::marker {
  display: none;
}

#homepage-hero.srp .cta {
  font-size: 15px;
  margin-top: 40px;
  padding-left: 1.75em;
  padding-right: 1.9em;
}

#homepage-hero .cta.mobile {
  height: 45px;
  top: auto;
}

@media screen and (max-width: 1325px) {
  #homepage-hero.srp .hero-inner {
    position: absolute;
    bottom: 15%;
  }
  #homepage-hero.srp video {
    top: 0px;
  }
}

@media screen and (max-width: 991px) {
  #homepage-hero.srp {
    position: relative;
    padding: 0;
    min-height: auto !important;
    overflow: visible;
  }
  #homepage-hero.srp video {
    position: relative !important;
  }
  #homepage-hero.srp .flex .hero-left {
    flex: 0 0 100%;
    order: 2;
  }
  #homepage-hero.srp .flex .hero-right {
    flex: 0 0 100%;
    order: 1;
  }
  #homepage-hero.srp .flex .hero-right {
    padding: 5%;
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  #homepage-hero.srp video.desktop {
    display: none !important;
  }
}

/* My Design V2 Banner */
#homepage-hero.mydesignv2 {
  display: block;
  min-height: 880px;
  padding-top: 225px;
  padding-bottom: 75px;
  background: linear-gradient( rgba(0, 16, 36, 0.8), rgba(0, 9, 27, 0.8) ), url('/sites/all/themes/fxl_responsive/images/banners/mydesignv2/mydesignv2-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  h2,h3,p {
    color: #fff;
    font-weight: 300;
    em {
      font-weight: 500;
    }
  }
  .cta.mobile {
    display: none;
    position: absolute;
    bottom: 0;
  }
  @media all and (max-width: 991px) {
    min-height: 550px;
    padding-top: 200px;
      .video {
      margin-bottom: 20px !important;
      max-width: 750px;
      video {
        position: absolute !important;
        z-index: -1 !important;
        width: 89% !important;
        left: 0.5% !important;
        top: 9% !important;
        }
      }
    .cta.mobile {
      display: block;
      width: 100%;
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 767px) {
    .video video {
      top: 10% !important;
    }
  }

  @media screen and (max-width: 600px) {
    .video video {
      top: 12% !important;
    }
  }

  @media screen and (max-width: 475px) {
    .video video {
      top: 15% !important;
    }
  }

  .flex {
    display: flex;
    max-width: 90%;
    margin: 0 auto;
    align-items: center;
    .hero-left {
      flex: 0 0 55%;
    }
    .hero-right {
      flex: 0 0 45%;
    }
    @media all and (max-width: 991px) {
      flex-wrap: wrap;
      .hero-left {
        flex: 0 0 100%;
        padding-top: 0;
        padding-right: 0;
        .cta {
          display: none;
        }
      }
      .hero-right {
        flex: 0 0 100%;
      }
    }
  }
  .hero-left {
    padding-top: 100px;
    padding-right: 40px;
    h2 {
      text-transform: uppercase;
      em {
        color: $color_fx_orange;
      }
    }
    h3 {
      em {
        margin-right: 2px;
      }
    }
    .cta {
      margin-top: 60px;
    }
  }
  .hero-right {
    justify-content: right;
    .frame {
      max-width: 95%;
      @media all and (max-width: 991px) {
        margin-top: 25px;
        max-width: 105%;
        margin-left: -2.5%;
        justify-content: center;
      }
    }
  }
}

/* Language Switcher */
body.section-product .select_language_modal ul {
 display: block !important;
}
#modalLanguage {
  background: transparent;
  border: none;
  box-shadow: none;
  top: 35% !important;
  @include for-size(phone-down) {
    overflow: visible;
  }
  .close-reveal-modal {
    font-size: 4em;
    top: -50px;
    right: 5%;
  }
  .choose_title_container {
    margin-bottom: 35px;
  }
  .language_menu_logo {
    display: none;
  }
  .select_language_modal {
    max-width: 725px;
    margin: 0 auto;
    background: rgba(255,255,255,.95);
    padding: 50px 0;
    border: solid 1px #666666;
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
    position: relative;
    top: 80px;
    @include for-size(phone-down) {
      top: 50%;
      transform: translateY(-30%);
      padding: 35px;
      position: absolute;
    }
    .inner_container {
      max-width: 525px;
      h2,
      p {
        color: #000;
        border-bottom: none;
        text-align: center;
        margin-bottom: 10px;
      }
      ul a {
        text-transform: uppercase;
        font-weight: 500;
      }
      a:focus {
        outline: none;
      }
    }
  }
}
.reveal-modal-bg {
  background: rgba(59, 59, 59, 0.9);
}
.mobile.off-canvas-open.modal_open {
  #modalLanguage {
    background: rgba(59, 59, 59, 0.9);
    top: 0 !important;
    height: 100vh;
    .close-reveal-modal {
      top: 0%;
      right: 5%;
    }
    .select_language_modal {
      top: 100px !important;
    }
  }
}

/* Product Page */
.node-type-product {
  .field-name-field-ss-title {
    font-weight: 500;
    font-size: 1.75em;
    margin-bottom: 1.5em !important;
    color: #000;
  }
  .spec-details-footer {
    column-count: 5;
    column-gap: 20px;
    margin-bottom: 35px;
    @media screen and (max-width: 991px) {
      column-count: 4;
      column-gap: 15px;
    }
    @media screen and (max-width: 768px) {
      column-count: 3;
    }
    > div {
      margin-bottom: 25px !important;
      break-inside: avoid;
      h2 {
        display: block;
        margin-top: 25px;

        margin-bottom: 5px !important;
        font-size: 16px;
        line-height: 1.25;
        font-weight: 600;
        &:first-of-type {
          margin-top: 0;
        }
      }
      p {
        font-size: 14px;
        line-height: 1.25;
        margin-bottom: 0;
      }
      // Certifications
      ul {
        margin-top: 15px;
        li {
          display: inline-block;
          width: 48%;
          margin: 0;
          padding-left: 0;
          margin-bottom: 15px;
        }
      }
      .cert {
        display: inline-block;
        width: 50px;
        height: auto;
        min-height: 55px;
        text-indent: -9999px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }

      .ce {
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/ce-logo.png');
      }
      .ip64 {
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/ip64-logo.png');
      }
      .ip65 {
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/ip65-logo.png');
      }
      .ip66 {
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/ip66-logo.png');
      }
      .ip67 {
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/ip67-logo.png');
      }
      .ip68 {
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/ip68-logo.png');
      }
      .ida {
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/ida-logo.png');
      }
      .ul-us {
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/ul-us-logo.png');
      }
      .ul-nom {
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/ul-nom-logo.png');
      }
      .c-ul-us {
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/c-ul-us-logo.png');
      }
      .rohs {
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/rohs-logo.png');
      }
      .etl {
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/etl-logo.png');
      }
      .rcm {
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/rcm-logo.png');
      }
      .ik09 {
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/ik09-logo.png');
      }
      .ik10 {
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/ik10-logo.png');
      }
      .wl {
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/wl-logo.png');
      }
      .saso {
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/saso-logo.png');
      }
      .ukca {
        max-width: 35px;
        height: auto;
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/ukca-logo.png');
      }
      .fcc {
        max-width: 35px;
        height: auto;
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/fcc-logo.png');
      }
      .weee {
        max-width: 35px;
        height: auto;
        background-image: url('/sites/all/modules/custom/spec_sheet_gen/images/compliance/weee-logo.png');
      }
    }
  }
}

/* LED Homepage Banner */
#homepage-hero.led-board {
  position: relative;
  z-index: 0;
  width: 100%;
  max-height: 90vh;
  height: 100%;
  padding-top: 30px;
  .mobile {
    display: none;
  }
  @include for-size(phone-down) {
    padding-top: 0;
  }
  @include for-size(phone-down) {
    max-height: none;
    .background {
      position: relative;
      //top: 115px;
      @include for-size(phone-down) {
        //display: none;
      }
    }
    @include for-size(phone-down) {
      .mobile {
        display: block;
        top: 0;
      }
    }
  }

  .hero-inner {
    display: flex;
    @include for-size(phone-down) {
      flex-wrap: wrap;
    }
  }
  a:hover {
    outline: none !important;
    color: #fff;
  }
  .hero-left {
    display: flex;
    flex: 0 55%;
    position: relative;
    @include for-size(desktop-down) {
      flex: 0 60%;
    }
    @include for-size(tablet-down) {
      flex: 0 55%;
    }
    @include for-size(phone-down) {
      flex: 0 100%;
    }
    .flex {
      display: flex;
      @include for-size(phone-down) {
        flex: 0 100%;
      }
      .left-section {
        flex: 0 50%;
      }
      .right-section {
        flex: 0 50%;
      }
      img {
        width: 100%;
        min-height: 600px;
        @include for-size(desktop-down) {
          display: block;
          height: 100%;
          min-height: 550px;
        }
        @include for-size(tablet-down) {
          display: block;
          height: 100%;
          min-height: 450px;
        }
      }
    }
  }
  .hero-right {
    flex: 0 45%;
    padding: 15px 25px 0 25px;
    background-image: url('/sites/all/themes/fxl_responsive/images/banners/led-board/banner-bg@2x.jpg');
    background-size: cover;
    background-position: center;
    padding-top: 100px;
    @include for-size(desktop-down) {
      flex: 0 40%;
    }
    @include for-size(tablet-down) {
      flex: 0 45%;
    }
    @include for-size(phone-down) {
      flex: 0 100%;
      padding: 0px;
      padding-top: 20px;
    }
    h2 {
      color: #fff;
      font-size: 3vw;
      font-weight: 200;
      line-height: 1.25;
      width: 100%;
      max-width: 95%;
      margin-top: 5%;
      margin-bottom: 0px;
      @include for-size(desktop-down) {
        font-size: 3.5vw;
      }
      @include for-size(tablet-down) {
        font-size: 3.75vw;
      }
      @include for-size(phone-down) {
        font-size: 6.75vw;
        max-width: 95%;
        line-height: 1.25;
        margin-bottom: 20px;
        padding-left: 20px;
      }
      .highlight {
        display: block;
        color: #F5822D;
        font-weight: 300;
        font-size: 2.15vw;
        margin-top: 10%;
        @include for-size(phone-down) {
          margin-top: 5%;
          font-size: 4.75vw;
        }
      }
    }
    p {
      font-size: 2vw;
      margin-bottom: 15px;
      @include for-size(desktop-down) {
        font-size: 2.15vw;
      }
      @include for-size(phone-down) {
        font-size: 4vw;
        max-width: 95%;
        line-height: 1.25;
      }
    }
    @include for-size(phone-down) {
      padding-left: 0;
      width: 100vw;
    }
    .button.cta {
      font-size: 1em;
      transition: background-color .5s;
      @include for-size(desktop-down) {
        font-size: 1.25vw;
      }
      @include for-size(phone-down) {
        margin: 0 auto;
        font-size: 1.05em;
        margin-bottom: 0;
        width: 100vw;
        height: 50px;

      }
    }
  }
  .bottom-bar {
    position: absolute;
    bottom: 2.5%;
    width: 100%;
    display: flex;
    @include for-size(tablet-down) {
      bottom: 15px;
    }
    @include for-size(phone-down) {
      bottom: 0;
      left: 0;
    }
    .left-section {
      @include for-size(phone-down) {
        display: none;;
      }
    }
    .right-section {
      padding: 0;
      padding-left: 25px;
      margin-top: 25px;
      @include for-size(phone-down) {
        padding-left: 0;
      }
    }
  }
}

/* SL-5 Homepage Banner */


#homepage-hero {
margin-bottom: 75px;
}
#homepage-hero a:hover {
  outline: none !important;
  color: #fff;
}
.bottom-bar {
position: relative !important;
background: #003C58;
color:#fff !important;
bottom: 0 !important;
width: 100% !important;
display: block !important;
text-align: center;
padding: 35px 25px;
}
.bottom-bar h2,
.bottom-bar p {
color:#fff;
margin: 0;
margin-bottom: 10px;
line-height: 1.4;
}
.bottom-bar p {
display:block;
font-size: 32px;
font-style: italic;
}
.bottom-bar span {
color: #F5822D;
}
.bottom-bar .button {
margin-top: 25px;
}
@media screen and (max-width: 767px) {
.bottom-bar {
padding: 30px 20px;
}
.bottom-bar .button {
margin-top: 25px;
position: absolute;
left: -20px;
right: -20px;
width: 100vw !important;
}
.bottom-bar h2 {
font-size: 32px;
}
.bottom-bar p {
display: block;
font-size: 28px;
font-style: italic;
max-width: 80%;
margin: 0 auto;
}
}
@media screen and (max-width: 480px) {
.bottom-bar h2 {
font-size: 28px;
}
.bottom-bar h2,
.bottom-bar p {
line-height: 1.3;
}
.bottom-bar p {
font-size: 24px;
}
}

/* SRP Homepage Banner */
#homepage-hero.srp {
  position: relative;
  padding-top: 25px;
  .mobile {
    display: none;
  }
  @include for-size(phone-down) {
    padding-top: 0;
  }
  @include for-size(tablet-down) {
    .background {
      position: relative;
      //top: 115px;
      @include for-size(phone-down) {
        display: none;
      }
    }
    @include for-size(phone-down) {
      .mobile {
        display: block;
        top: 0;
      }
    }
  }
  .text-row {
    background-color: rgba(0, 60, 88, 0.8);
    position: absolute;
    bottom: 15%;
    width: 100%;
    display: flex;
    @include for-size(tablet-down) {
      bottom: 85px;
    }
    @include for-size(phone-down) {
      bottom: 105px;
      flex-wrap: wrap;
      padding: 25px 3.5% 25px;
    }
    @include for-size(phone-only) {
      bottom: 85px;
      img {
        max-width: 35% !important;
      }
    }
  }
  .left-section {
    flex: 0 20%;
    position: relative;
    @include for-size(phone-down) {
      flex: 0 100%;
    }
    img {
      margin: 0 auto;
      display: block;
      position: absolute;
      top: 50%;
      left: 10%;
      transform: translateY(-50%);
      width: 18vw;
      &:focus {
        outline: none;
      }
      @include for-size(phone-down) {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        width: 40vw;
        max-width: 175px;
        margin: 0;
        margin-bottom: 15px;
      }
    }
    @include for-size(phone-down) {
      top: initial;
      width: 100%;
    }
  }
  .right-section {
    flex: 0 80%;
    padding: 15px 25px 0 25px;
    @include for-size(phone-down) {
      flex: 0 100%;
      padding: 0;
    }
    h2 {
      color: #fff;
      font-size: 3vw;
      font-weight: 200;
      line-height: 1.25;
      width: 100%;
      max-width: 65%;
      text-transform: none;
      margin-bottom: 0px;
      @include for-size(desktop-down) {
        font-size: 3vw;
      }
      @include for-size(tablet-down) {
      }
      @include for-size(phone-down) {
        font-size: 6.75vw;
        max-width: 85%;
        line-height: 1.25;
        margin-bottom: 20px;
      }
      strong {
        color: #F5822D;
        font-weight: 500;
      }
    }
    p {
      font-size: 2vw;
      margin-bottom: 15px;
      @include for-size(desktop-down) {
        font-size: 2.15vw;
      }
      @include for-size(phone-down) {
        font-size: 4vw;
        max-width: 95%;
        line-height: 1.25;
      }
    }
    @include for-size(phone-down) {
      padding-left: 0;
      width: 100vw;
    }
    .button {
      font-size: 1em;
      transition: background-color .5s;
      @include for-size(desktop-down) {
        font-size: 1.25vw;
      }
      @include for-size(phone-down) {
        font-size: 1.05em;
        margin-bottom: 0;
        width: 100vw;
        height: 50px;
      }
    }
  }
  .bottom-bar {
    position: absolute;
    bottom: 2.5%;
    width: 100%;
    display: flex;
    @include for-size(tablet-down) {
      bottom: 15px;
    }
    @include for-size(phone-down) {
      bottom: 0;
      left: 0;
    }
    .left-section {
      @include for-size(phone-down) {
        display: none;;
      }
    }
    .right-section {
      padding: 0;
      padding-left: 25px;
      margin-top: 25px;
      @include for-size(phone-down) {
        padding-left: 0;
      }
    }
  }
}

/* LD Ledge Light Homepage Banner */
#homepage-hero.ld-ledge {
  position: relative;
  .mobile {
    display: none;
  }
  @include for-size(tablet-down) {
    .background {
      position: relative;
      top: 115px;
      @include for-size(phone-down) {
        display: none;
      }
    }
    @include for-size(phone-down) {
      .mobile {
        display: block;
        top: 0;
      }
    }
  }
  .left-section {
    position: absolute;
    top: 30%;
    left: 0;
    transform: translateY(-50%);
    padding-left: 5%;
    width: 100vw;
    @include for-size(desktop-down) {
      top: 28%;
    }
    @include for-size(tablet-down) {
      top: 45%;
    }
    @include for-size(phone-down) {
      top: initial;
      bottom: 20%;
      width: 100%;
    }
    .icon {
      width: 15vw;
      max-width: 240px;
      margin-bottom: 20px;
      @include for-size(phone-down) {
        display: none;
      }
    }
    .text {
      color: #fff;
      margin-bottom: 40px;
      position: absolute;
      @include for-size(phone-down) {
        bottom: 0;
        margin-bottom: 0;
      }
      h2 {
        color: #fff;
        font-size: 3.25vw;
        font-style: italic;
        font-weight: 200;
        line-height: 1.15;
        width: 100%;
        max-width: 60%;
        text-transform: uppercase;
        margin-bottom: 0px;
        @include for-size(desktop-down) {
          font-size: 3vw;
          max-width: 50%;
        }
        @include for-size(tablet-down) {
          max-width: 65%;
        }
        @include for-size(phone-down) {
          font-size: 6.75vw;
          max-width: 85%;
          margin-bottom: 10px;
        }
        strong {
          color: #F5822D;
          font-weight: 500;
        }
      }
      p {
        font-size: 2.25vw;
        font-style: italic;
        margin-bottom: 15px;
        @include for-size(desktop-down) {
          font-size: 2.15vw;
        }
        @include for-size(phone-down) {
          font-size: 4vw;
          max-width: 80%;
          line-height: 1.25;
        }
      }
    }
  }
  .right-section {
    bottom: 95px;
    position: relative;
    padding-left: 5%;
    @include for-size(desktop-down) {
      bottom: 75px;
    }
    @include for-size(tablet-down) {
      bottom: -25px;
    }
    @include for-size(phone-down) {
      bottom: 50px;
      padding-left: 0;
      width: 100vw;
    }
    .button {
      font-size: 1em;
      @include for-size(desktop-down) {
        font-size: 1.25vw;
      }
      @include for-size(phone-down) {
        font-size: 1.05em;
        margin-bottom: 0;
        width: 100vw;
        height: 50px;
      }
    }
  }
}

/* Homepage Hero - My Design */
#homepage-hero.my-design {
  display: flex;
  position: relative;
  z-index: 0;
  max-height: 90vh;
  height: 100%;
  border-top: 38px solid #00658A;
  top: 116px;
  margin-bottom: 25px;
  @include for-size(tablet-down) {
    margin-bottom: 3%;
  }
  @include for-size(phone-down) {
    top: 0;
    border-top: 0;
    flex-wrap: wrap;
    margin-bottom: 5%;
  }
  .left-section {
    .mobile {
      display: none;
    }
    img {
      max-width: 60vw;
    }
    @include for-size(phone-down) {
      flex: 0 0 100%;
      img {
        max-width: 100%;
      }
      .background {
        display: none;
      }
      .mobile {
        display: block;
      }
    }
  }
  .right-section {
    position: relative;
    padding: 6% 50px 25px;
    background: #003C58;
    color: #fff;
    font-weight: 400;
    @include for-size(tablet-down) {
      padding-top: 2.5%;
    }
    @include for-size(phone-down) {
      flex: 0 0 100%;
      padding: 5% 3% 25px;
    }
    .text p {
      font-size: 3vw;
      line-height: 1.15;
      margin-bottom: 25px;
      font-style: italic;
      padding-right: 5%;
      a {
        color: #fff;
      }
      @include for-size(tablet-down) {
        margin-bottom: 15px;
      }
      @include for-size(phone-down) {
        font-size: 7.5vw;
        line-height: 1.25;
      }
    }
    .md-icon {
        display: flex;
        flex-wrap: nowrap;
        line-height: 1;
        color: #F5822D;
        font-weight: 300;
        font-style: italic;
        align-items: center;
        align-content: center;
        @include for-size(phone-down) {
          margin-bottom: 75px;
        }
        > a {
          flex: 0 0 25%;
          max-width: 105px;
          margin-right: 15px;
          display: block;
          @media screen and (max-width: 1500px) {
            margin-right: 0;
            img {
              max-width: 80% !important;
            }
          }
        }
        p {
            flex: 0 0 75%;
            max-width: 475px;
            font-size: 2.35vw !important;
            margin-bottom: 0 !important;
            a {
              color: #F5822D;
            }
            @include for-size(desktop-down) {
              font-size: 2.25vw !important;
            }
            @include for-size(phone-down) {
              font-size: 5.5vw !important;
              max-width: 768px;
            }
        }
    }
    .button {
        position: absolute;
        bottom: 5%;
        left: 50px;
        background: #F5822D;
        font-size: 1em;
        max-width: 225px;
        margin-top: 25px;
        padding: 12px 25px;
        @include for-size(tablet-down) {
          font-size: .75em;
          bottom: 3%;
        }
        @include for-size(phone-down) {
          left: 0;
          bottom: 0;
          width: 100%;
          padding: 15px 25px;
          max-width: 100%;
          margin-bottom: 0;
          font-size: 1em;
        }
    }
  }
}

/* Designer VS Standard */
.page-node-21806 {
  .page_title_container {
    position: relative;
    background-color: #000000b3;
    padding: 1.2em 0 1em;
    margin-bottom: 2em;
    margin-top: -69px;
    z-index: 2;
  }
  .components_boxes li {
    display: inline;
    float: left;
    padding: 0 7% 0;
    width: 49%;
  }
  section#choose_designer .section_inner_mobile .ui-accordion .ui-accordion-content {
    height: auto;
  }
}

/* My Design Landing Page */

.page-node__my-design,
.page-node-165386,
.page-node-172506,
.page-node-169186,
.page-node-171351 {
  color: #3B3B3B;
  &.modal_open {
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      background: #0000005e;
      width: 100%;
      z-index: 2;
      display: none !important;
    }
    .reveal-modal-bg {
      display: none;
    }
  }
  .inner_main,
  .l-main {
    max-width: 100% !important;
    max-width: 1500px !important;
  }
  .large-12.main.columns {
    max-width: 1500px !important;
  }
  .page {
    position: relative;
  }
  .headers_container {
    background-color: transparent;
  }
  .product_heading_container {
    display: none !important;
  }
  .l-main {
    &:before {
      content: "";
      display: block;
      background: url(/sites/all/themes/fxl_responsive/images/mydesign/md-hero-bg@2x.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 0px 25px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100vw;
      z-index: -1;
      height: 850px;
      @include for-size(phone-down) {
        display: none;
      }
    }
  }
  .reveal-modal-bg {
    z-index: -1;
    display: none !important
  }
  .video_modal {
    background-color: #fff !important;
  }
  .reveal-modal .close-reveal-modal {
    font-size: 2.5em;
    line-height: 1;
    position: absolute;
    top: -5px;
    right: 5px;
  }
}

.md-hero-wrap {
  position: relative;
  //top: -40px;
  z-index: 1;
  @include for-size(phone-down) {
    top: -60px;
  }
  .md-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
  }
  .md-left,
  .md-right {
    display: flex;
    height: 675px;
    flex: 0 0 22%;
    z-index: 1;
    @include for-size(desktop-down) {
      flex: 0 0 15%;
    }
    @media screen and (max-width: 1300px) {
      height: 550px;
    }
    @include for-size(tablet-down) {
      display: none;
    }
    ul {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      list-style-type: none;
      height: 100%;
      margin-bottom: 0;
      padding-top: 60px;
      li {
        font-family: calgary-script-ot, sans-serif;
        font-weight: 400;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #3B3B3B;
        font-size: 40px;
        @include for-size(desktop-down) {
          font-size: 1.75vw;
        }
        line-height: 1.25;
        flex: 1 1 25%;
        margin-bottom: 10%;
        &:before {/*
          display: block;
          height: 49px;
          margin-bottom: 15px;
          background-size: 48px;*/
          content: "";
          display: inline-block;
          margin-bottom: -10px;
          background-image: url("/sites/all/themes/fxl_responsive/images/mydesign/check-icon.svg");
          background-repeat: no-repeat;
          background-size: 100%;
          width: 45px;
          height: 43px;
          margin-right: 10px;
          @media screen and (max-width: 1400px) {
            max-width: 40px;
            height: 38px;
            margin-bottom: -5px;
          }
          @media screen and (max-width: 1300px) {
            /*
            display: block;
            max-width: 25px;
            height: 25px;
            margin-bottom: 10px;*/
            width: 100%;
            max-width: 100%;
            background-size: 25px;
          }
        }
        &:last-of-type {
          margin-bottom: 5%;
        }
        a {
          color: #3B3B3B;
        }
      }
      @include for-size(desktop-down) {
        padding-top: 28%;
        li {
          font-size: 1.35em;
        }
      }
    }
  }
  .md-left {
    padding-right: 1%;
    padding-left: 1%;
    @include for-size(desktop-down) {
      padding-right: 2%;
    }
    ul {
      align-items: flex-start;
    }
  }
  .md-right {
    padding-left: 1%;
    padding-right: 1%;
    @include for-size(desktop-down) {
      padding-left: 3%;
    }
    ul {
      align-items: flex-end;
      text-align: right;
      li {
        &:before {
          background-position: right;
        }
      }
    }
  }
  .md-center {
    flex: 0 0 56%;
    z-index: 1;
    position: relative;
    top: 45px;
    overflow: hidden;
    @include for-size(desktop-down) {
      flex: 0 0 69%;
    }
    @include for-size(tablet-down) {
      flex: 0 0 100%;
    }
    @include for-size(phone-down) {
      top: 0;
      overflow: visible;
    }
    &:before {
      content: "";
      display: block;
      background: url(/sites/all/themes/fxl_responsive/images/mydesign/tablet-header@2x.png);
      background-size: 95%;
      background-repeat: no-repeat;
      background-position: center;
      z-index: -1;
      width: 100%;
      height: 50px;
      position: absolute;
      top: 3.5%;
      margin: 2px;
      @media screen and (max-width: 1300px) {
        top: 2.8%;
      }
      @include for-size(tablet-down) {
        top: 3.5%;
      }
      @include for-size(phone-down) {
        display: none;
      }
    }
    &:after {
      content: "";
      display: block;
      background: url(/sites/all/themes/fxl_responsive/images/mydesign/md-hero@2x.jpg);
      //background: url(/sites/all/themes/fxl_responsive/images/mydesign/md-hero-night@2x.jpg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 0 60%;
      //background-position: -150% 25%;
      z-index: -2;
      width: 110%;
      height: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: 2px;
      @include for-size(phone-down) {
        display: none;
      }
    }
    @include for-size(phone-down) {
      .tablet-frame {
        display: none;
      }
    }
    .hero-mobile {
      display: none;
      @include for-size(phone-down) {
        display: block;
        position: relative;
        left: -1em;
        right: -1em;
        width: 101vw;
        max-width: 101vw;
      }
    }
  }
  #hero-footer {
    position: relative;
    background: #004D6B;
    color: #fff;
    text-align: center;
    font-weight: 400;
    z-index: 1;
    padding: 40px 0;
    margin-bottom: 40px;
    flex: 0 0 100%;
    @include for-size(phone-down) {
      margin-bottom: 0;
    }
    h2 {
      color: #fff;
      font-family: "roboto", sans-serif;
      font-size: 2.25em;
      width: 100%;
      max-width: 1450x;
      margin: 0 auto;
      margin-bottom: 25px;
      @include for-size(phone-down) {
        font-size: 1.75em;
      }
    }
    &:before,
    &:after {
      content: "";
      display: block;
      background: #004D6B;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 9999px;
      left: -50%;
      z-index: -1;
      height: auto;
    }
    .start-designing {
      @include for-size(phone-down) {
        display: block;
        margin-bottom: 20px;
      }
    }
    .watch-video {
      display: none;
      background: #fff;
      color: #F5822D;
      @include for-size(phone-down) {
        display: block;
      }
    }
  }
}

// MD Language Updates
//ES
.page-node-165386 {
  .md-hero-wrap {
    .md-left,
    .md-right {
      ul {
        li {
          font-size: 34px;
          @media screen and (max-width: 1500px) {
            font-size: 1.75vw;
          }
          @media (max-width: 1300px) {
            font-size: 1.05em;
          }
        }
      }
    }
  }
}
// FR
.page-node-172506 {
  .md-hero-wrap {
    .md-left,
    .md-right {
      ul {
        li {
          font-size: 36px;
          @media screen and (max-width: 1500px) {
            font-size: 1.75vw;
          }
          @media (max-width: 1300px) {
            font-size: 1.1em;
          }
        }
      }
    }
  }
}
// DE
.page-node-169186 {
  .md-hero-wrap {
    .md-left,
    .md-right {
      ul {
        li {
          font-size: 30px;
          @media screen and (max-width: 1500px) {
            font-size: 1.75vw;
          }
          @media (max-width: 1300px) {
            font-size: .95em;
            letter-spacing: 1px;
            margin-bottom: 40%;
          }
        }
      }
    }
  }
}
// RU
.page-node-171351 {
  .md-hero-wrap {
    .md-left,
    .md-right {
      ul {
        li {
          font-size: 19px;
          @media screen and (max-width: 1500px) {
            font-size: 1.25vw;
          }
          @media (max-width: 1300px) {
            font-size: .7em;
            margin-bottom: 30%;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}

#md-main {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  font-family: "roboto", sans-serif;
  z-index: 2;
  position: relative;
  margin-top: -40px;
  @include for-size(phone-down) {
    margin-top: -60px;
  }
  // Global //
  p {
    font-family: "roboto", sans-serif;
  }
  .button {
    &.small {
      color: #F5822D;
      font-size: .75em ;
      border: 1px solid #E0E0E0;
      background-color: transparent;
      padding: 6px 12px;
      letter-spacing: 1.15px;
      transition: all .35s ease;
      &:hover {
        background-color: #F5822D;
        color: #fff;
      }
    }
    .icon-play {
      margin-right: 10px;
    }
  }
  // End
  // Sections Alternating
  > div:nth-child(even) {
    > div {
      &:first-of-type {
        text-align: right;
        margin-right: 23px;
        @include for-size(phone-down) {
          margin-right: 0;
          margin-bottom: 35px;
        }
      }
      &:last-of-type {
        margin-left: 23px;
        padding-top: 5%;
        padding-right: 10%;
        @include for-size(tablet-down) {
          padding-top: 0;
          padding-right: 0;
        }
        @include for-size(phone-down) {
          margin-left: 0;
        }
      }
    }
  }
  > div:nth-child(odd) {
    > div {
      &:first-of-type {
        order: 2;
        text-align: left;
        margin-left: 23px;
        @include for-size(phone-down) {
          order: 1;
          margin-left: 0;
          margin-bottom: 35px;
        }
      }
      &:last-of-type {
        text-align: right;
        margin-right: 23px;
        padding-top: 5%;
        padding-left: 10%;
        @include for-size(tablet-down) {
          padding-top: 0;
          padding-left: 0;
        }
        @include for-size(phone-down) {
          order: 2;
          margin-right: 0;
          text-align: left;
        }
        .check-icon {
          background-position: right;
          @include for-size(phone-down) {
            background-position: left;
          }
        }
      }
    }
  }
  // End
  .intro-section {
    display: flex;
    position: relative;
    flex: 0 0 100%;
    flex-wrap: wrap;
    margin-bottom: 80px;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -50vw;
      width: 200vw;
      background: #e4e1dd47;
      z-index: -1;
    }
    > div {
      flex: 0 0 50%;
      position: relative;
      font-size: 1.25em;;
      @include for-size(phone-down) {
        flex: 0 0 100%;
      }
      &:first-of-type {
        padding: 30px 40px;
        margin-left: 0 !important;
      }
      &:last-of-type {
        @include for-size(phone-down) {
          display: none;
        }
        background-image: url('/sites/all/themes/fxl_responsive/images/mydesign/watch-video@2x.jpg');
        background-size: cover;
        order: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 400px;
        margin-right: 0 !important;
      }
    }
  }
  .section {
    display: flex;
    align-content: space-between;
    flex: 0 0 100%;
    padding: 50px 35px;
    @include for-size(phone-down) {
      flex-wrap: wrap;
    }
    div {
      flex: 0 0 50%;
      background-repeat: no-repeat;
      background-size: 100%;
      @include for-size(phone-down) {
        flex: 0 0 100%;
        flex-wrap: wrap;
      }
      img {
        max-width: 700px;
        width: 95%;
        @include for-size(tablet-down) {
          width: 100%;
        }
      }
      .check-icon {
        display: block;
        height: 49px;
        margin-bottom: 20px;
        background-image: url('/sites/all/themes/fxl_responsive/images/mydesign/check-icon.svg');
        background-repeat: no-repeat;
        background-size: 48px;
      }
      h3 {
        font-family: calgary-script-ot, sans-serif;
        font-weight: 400;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #3B3B3B;
        font-size: 1.75em;
      }
      p {
        color: #3B3B3B;
        font-size: 1.25em;
        max-width: 550px;
      }
      .button {
        font-size: 1em;
      }
    }
  }
}

.md-footer {
  display: flex;
  position: relative;
  background: #F7F7F6;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
  justify-content: center;
  align-content: center;
  z-index: 1;
  &:before,
  &:after {
    content: "";
    display: block;
    background: #F7F7F6;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 9999px;
    left: -50%;
    z-index: -1;
    height: auto;
  }
  h3 {
    font-family: calgary-script-ot, sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #3B3B3B;
    font-size: 1.75em;
  }
  p {
    color: #3B3B3B;
    font-size: 1.25em;
  }
  .button {
    &.small {
      color: #F5822D;
      font-size: 1em;
      border: 1px solid #E0E0E0;
      background-color: transparent;
      padding: 9px 12px 6px;
      letter-spacing: 1.25px;
      transition: all .35s ease;
      &:hover {
        background-color: #F5822D;
        color: #fff;
      }
    }
  }
}

/* Hardscapes Promo */
// Landing Page
.section-dreamscapes,
.page-node-183906 {
  .section_inner {
    padding-left: 5%;
    padding-right: 5%;
  }
  .hdsc-hero-wrap {
    margin-bottom: 40px;
    .hero-inner {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      @include for-size(phone-down) {
        background: url(/sites/all/themes/fxl_responsive/images/hardscapes/promo/hero_background@2x.jpg);
        background-size: cover;
        padding-top: 30px;
        padding-bottom: 50px;
      }
      @include for-size(phone-only) {
        padding-bottom: 85px;
      }
      .hero-left {
        flex-basis: 55%;
        position: relative;
        @include for-size(phone-down) {
         flex-basis: 100%;
        }
        h1 {
          width: 100%;
          background: #003c58de;
          position: absolute;
          top: 10%;
          font-size: 2.5vw;
          font-weight: 300;
          color: #fff;
          padding: 40px 7.5%;
          @include for-size(tablet-down) {
            font-size: 32px;
          }
          @include for-size(phone-down) {
            font-size: 30px;
            padding: 30px 5%;
          }
          span.highlight {
            color: #F5822D;
            &:last-of-type {
              font-style: italic;
            }
          }
        }
        @include for-size(phone-down) {
          img {
            opacity: 0;
          }
        }
      }
      .hero-right {
        flex-basis: 45%;
        padding: 1% 2% 0;
        position: relative;
        @include for-size(phone-down) {
         flex-basis: 100%;
         position: absolute;
         bottom: 0;
         padding: 0;
        }
        h2 {
          color: #3B3B3B;
          font-size: 3.25vw;
          line-height: 1;
          font-weight: 200;
          text-transform: uppercase;
          max-width: 600px;
          @include for-size(phone-down) {
            display: none;
          }
          .highlight {
            display: block;
            color: #F5822D;
            font-weight: 500;
          }
        }
        img {
          width: 95%;
          max-width: 925px;
          position: absolute;
          left: -7%;
          bottom: 0;
          @include for-size(phone-down) {
            width: 55%;
            left: 43%;
            position: relative;
          }
        }
        a {
          position: absolute;
          bottom: 50px;
          padding: 15px 20px;
          margin: 0 auto;
          left: 0;
          right: 0;
          padding-bottom: 12.5px;
          max-width: 175px;
          @include for-size(phone-down) {
            left: 5%;
            right: initial;
            font-size: 18px;
            bottom: 50px;
            padding: 18px 30px;
            max-width: 200px;
          }
          @include for-size(phone-only) {
            font-size: 14px;
            max-width: 135px;
            padding: 12px 8px 10px;
          }
          &:hover {
            background-color: #F5822D;
            color: #3B3B3B !important;
          }
        }
      }
    }
  }
  .hdsc-main {
    .section_inner {
      @include flexbox;
      @include flex-direction(column);
      @include justify-content(space-between);
      max-width: 14500px;
    }
    h3 {
      color: #003C58;
      font-size: 30px;
      font-weight: 200;
      line-height: 1.2;
      margin-bottom: 15px;
      @include for-size(phone-down) {
        font-size: 20px;
      }
    }
    p {
      color: #3B3B3B;
      font-size: 20px;
      line-height: 1.45;
      @include for-size(phone-down) {
        font-size: 16px;
      }
    }
    .section_inner {
      max-width: 1500px;
    }
  }
}

.hardscapes-promo-form {
  > div {
    max-width: 1500px;
    margin: 0 auto;
  }

  .section_inner {
    max-width: 1500px;
    > .fieldset-wrapper {
      @include flexbox;
      @include flex-direction(row);
      @include justify-content(space-between);
      flex-wrap: wrap;
    }
  }
  fieldset {
    border: none;
  }
  h3 {
    font-size: 32px;
    color: #000000DE;
    margin-bottom: -7.5px;
    strong {
      font-weight: 500;
    }
  }
  p {
    font-size: 20px;
  }
  .form-item {
    clear: both;
    position: relative;
    @include for-size(phone-down) {
      margin-bottom: 20px;
    }
    &.webform-component--form-container--flex-group-1--section-1--other-please-specify,
    &.webform-component--form-container--flex-group-2--section-3--other-please-specify-2,
    &.form-type-radio {
      &:before {
        display: none;
      }
    }
  }

  .webform-component--form-container--webform-header {
    flex: 0 0 100%;
  }

  fieldset.flex-group,
  fieldset.webform-component--form-container--flex-group-1,
  fieldset.webform-component--form-container--flex-group-2,
  fieldset.webform-component--form-container--flex-group-3 {
    padding: 0;
    padding-right: 50px;
    margin: 0;
    flex: 0 0 33%;
    border-right: 1px solid #E4E1DD;
    @include for-size(tablet-down) {
      flex: 0 0 100%;
      padding-right: 0;
      border-right: none;
    }
    fieldset {
      position: relative;
      &:before {
        content: "*";
        display: block;
        color: red;
        position: absolute;
        top: 20px;
        left: 78px;
      }
    }
  }
  fieldset.webform-component--form-container--flex-group-2 {
    .fieldset-description {
      margin-left: 66px;
      margin-bottom: 40px;
      @include for-size(tablet-down) {
        margin-left: 0;
      }
    }
  }
  fieldset.webform-component--form-container--flex-group-3 {
    border-right: none;
    padding-right: 0;
    padding-left: 50px;
    @include for-size(tablet-down) {
      padding-left: 0;
    }
  }
  .webform-component--form-container--flex-group-1--section-1,
  .webform-component--form-container--flex-group-2--section-2,
  .webform-component--form-container--flex-group-2--section-3,
  .webform-component--form-container--flex-group-3--section-4 {
    legend {
      float: left;
      margin-right: 20px;
      margin-bottom: 35px;
      font-size: 24px;
      color: #F5822D;
      font-weight: 400;
      width: 50px;
      height: 50px;
      border: 1px solid #F5822D;
      position: relative;
    }
    .fieldset-legend {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .fieldset-description {
    position: relative;
    top: 5px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.35;
  }

  input.form-text  {
    border-color: #E4E1DD;
    box-shadow: none;
    padding-top: 9px;
    font-size: 16px;
    color: #3B3B3B;
  }
  .form-type-radio {
    display: flex;
    font-weight: 500;
    color: #000000DE;
    align-items: baseline;
    .option {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 500;
      color: #000000DE;
      position: relative;
      top: -1px;
    }
  }
  select {
    height: 2.3125em;
    color: rgba(0, 0, 0, 0.75);
    padding: 9px 5px 5px 5px;
    box-shadow: none;
    border: 1px solid #E4E1DD;
  }
  .disclaimer {
    font-size: 12px;
  }
  .webform-component-file {
    margin-bottom: 60px;
    .form-managed-file {
      margin-bottom: 10px;
      button {
        font-size: 14px;
        max-width: 200px;
        padding: 14px 35px 10px;
        margin-top: 25px;
        margin-bottom: 0;
        background-color: #F5822D !important;
        &:hover {
          color: #3B3B3B !important;
        }
      }
    }

    .description {
      font-size: 12px;
      strong {
        font-weight: 500;
      }
    }
  }

  .webform-component--form-container--flex-group-2--section-3--other-please-specify,
  .webform-component--form-container--flex-group-2--section-3--other-please-specify-2 {
    margin-top: -15px;
  }

  .webform-submit.form-submit {
    float: right;
    margin-right: 12%;
    font-weight: 500;
    background-color: #F5822D ; /*!important*/
    &:hover {
      color: #3B3B3B; /*!important*/
    }
    @include for-size(desktop-down) {
      margin-right: 5%;
    }
    @include for-size(tablet-down) {
      float: none;
      margin-top: 0;
      margin-left: 5%;
      margin-right: 0;
    }
  }
}

// Family Page //
.section-hardscapes,
.page-node-183911 {
  //temp
  h1,
  .button-group,
  .alert,
  .alerts,
  .messages {
    display: none;
  }
  @include for-size(phone-down) {
    .headers_container {
      padding-top: 40px;
    }
  }

  #hardscapes_landing_page {
      .section_inner {
        padding-left: 25px;
        padding-right: 25px;
      }

      #hardscapes_hero {

          .hero_image_content {

              background: #000 url("/sites/all/themes/fxl_responsive/images/hardscapes/family/herobanner@2x.jpg") no-repeat scroll bottom center / cover;
              height: 75vh;
              max-height: 985px;

              @include for-size(tablet-down) {
                height: 65vh;
              }

              .section_inner {
                  @include flexbox;
                  @include flex-direction(column);
                  @include justify-content(space-between);
                  max-width: 1500px;
                  height: 100%;

                  .hero-copy {
                    padding: 30px 0 35px;
                    top: 6%;
                    text-align: center;
                    color: #fff;
                    background-color: rgba(0, 22, 36, 0.35);
                    z-index: 1;
                    position: relative;

                    @include for-size(phone-down) {
                      top: 10%;
                      margin-bottom: 14vh;
                    }

                    &:before,
                    &:after {
                      content: "";
                      display: block;
                      background-color: rgba(0, 22, 36, 0.8);
                      z-index: -1;
                      width: 100%;
                      height: auto;
                      position: absolute;
                      top: 0;
                      bottom: 0;
                    }

                    &:before {
                      left: -50%;
                    }
                    &:after {
                      right: -50%;
                    }

                    span.highlight {
                      color: #F5822D;
                    }

                    h1 {
                      display:block;
                      color: #fff;
                      margin-bottom: 0;
                      font-size: 50px;
                      //font-size: 2.35vw;
                      font-weight: 500;

                      @include for-size(desktop-down) {
                        font-size: 42px;
                      }

                      @include for-size(phone-down) {
                        font-size: 36px;
                      }
                    }

                    p {
                      font-size: 28px;
                      margin-bottom: 0;

                        @include for-size(phone-down) {
                            font-size: 24px;
                            line-height: 1.3;
                            margin-top: 10px;
                        }

                        @include for-size(phone-down) {
                          font-size: 22px;
                        }
                    }
                  }
              }
          }
      }

      #featured_links {
        .section_inner {
          @include flexbox;
          @include flex-direction(column);
          @include justify-content(space-between);
          max-width: 1500px;
        }
        .five_bubbles {
          @include flexbox;
          @include align-items(bottom);
          @include justify-content(space-between);
          padding: 0 5%;
          position: relative;
          margin-top: -12%;

          @include for-size(tablet-down) {
            padding: 0;
            margin-top: -185px;
            @include justify-content(space-around);
            flex-wrap: wrap;
          }

          @include for-size(phone-down) {
            margin-top: 7.5%;
          }

          .bubble {
            opacity: 0;
            max-width: 21%;
            text-align: center;
            //-webkit-transition: all .5s;
            //transition: all .5s;
            animation: fadeInAnimation ease 1.25s;
            animation-fill-mode: forwards;
            @media screen and (max-width: 1500px) {
              max-width: 18%;
            }
            @include for-size(desktop-down) {
              //max-width: 16%;
            }
            @include for-size(tablet-down) {
              max-width: 33%;
              padding: 10px;
              margin-bottom: 30px;
              //width: 150px;
            }
            &:nth-child(1) {
              animation-delay: 0.2s;
            }
            &:nth-child(2) {
              animation-delay: 0.4s;
            }
            &:nth-child(3) {
              animation-delay: 0.6s;
            }
            &:nth-child(4) {
              animation-delay: 0.8s;
              @include for-size(tablet-down) {
                max-width: 41%;
                padding-left: 10%;
              }
            }
            &:nth-child(5) {
              animation-delay: 1s;
              @include for-size(tablet-down) {
                max-width: 41%;
                padding-right: 10%;
              }
            }

            span {
              display: block;
              margin-top: 15px;
              font-weight: 500;
              text-transform: uppercase;
              font-size: 26px;
              transition: all ease .35s;
              @media screen and (max-width: 1500px) {
                font-size: 20px;
              }
              @include for-size(tablet-down) {
                font-size: 24px;
              }
              @include for-size(phone-down) {
                font-size: 18px;
                line-height: 1.35;
              }
            }

            &:hover {
              border-color: $color_fx_orange;
              span {
                text-shadow: 0px 1px 2px rgba(71, 71, 71, 0.233);
              }
            }
            &:focus {
              outline: none;
            }
          }
        }
      }

      #intro_text {
        margin-top: 175px;
        padding-bottom: 60px;
        .section_inner {
          max-width: 1600px;
        }
        @include for-size(tablet-down) {
          margin-top: 100px;
        }
        @include for-size(phone-down) {
          margin-top: 60px;
        }
        @include for-size(phone-only) {
          //margin-top: 30vh;
        }
        p {
          font-size: 24px;
          line-height: 2;
          color: #3B3B3B;
          @include for-size(tablet-down) {
            font-size: 20px;
          }
          @include for-size(phone-down) {
            font-size: 1.2rem;
          }
        }
      }

      #hardscapes_features {
        display: flex;
        flex-wrap: wrap;
        align-content: space-around;

        > div:nth-child(odd) {
          > div {
            &:first-of-type {
              @include for-size(tablet-down) {
                flex: 0 0 45%;
              }
              @include for-size(phone-down) {
                margin-right: 0;
                margin-bottom: 35px;
                flex: 0 0 100%;
              }
            }
            &:last-of-type {
              margin-left: 40px;
              padding-right: 12%;
              @media screen and (max-width: 1500px) {
                margin-left: 35px;
                padding-right: 3%;
              }
              @include for-size(desktop-down) {
                margin-left: 30px;
              }
              @include for-size(tablet-down) {
                flex: 0 0 55%;
                margin-left: 20px;
                padding-top: 0;
                padding-right: 0;
              }
              @include for-size(phone-down) {
                margin-left: 0;
                flex: 0 0 100%;
              }
            }
          }
        }
        > div:nth-child(even) {
          > div {
            &:first-of-type {
              order: 2;
              @include for-size(tablet-down) {
                flex: 0 0 45%;
              }
              @include for-size(phone-down) {
                order: 1;
                margin-left: 0;
                margin-bottom: 35px;
                flex: 0 0 100%;
              }
            }
            &:last-of-type {
              padding-left: 3%;
              @include for-size(tablet-down) {
                flex: 0 0 55%;
                padding-top: 0;
                .copy {
                  p {
                    max-width: 94% !important;
                  }
                }
              }
              @include for-size(phone-down) {
                order: 2;
                margin-right: 0;
                flex: 0 0 100%;
              }
            }
          }
        }
        .section {
          display: flex;
          align-content: space-between;
          flex: 0 0 100%;
          position: relative;
          @media screen and (max-width: 1700px) {
            margin-bottom: 60px;
          }
          @include for-size(desktop-down) {
            margin-bottom: 10%;
          }
          @include for-size(tablet-down) {
            margin-bottom: 15%;
          }
          @include for-size(phone-down) {
            flex-wrap: wrap;
            margin-bottom: 20%;
          }
          div {
            flex: 0 0 50%;
            background-repeat: no-repeat;
            background-size: 100%;
            position: relative;
            @include for-size(phone-down) {
              flex: 0 0 100%;
              flex-wrap: wrap;
            }
            img {
              width: 100%;
              @include for-size(tablet-down) {
                width: 100%;
              }
            }
            h3 {
              font-weight: 500;
              font-style: normal;
              text-transform: uppercase;
              letter-spacing: 3px;
              color: #43484D;
              font-size: 4em;
              margin-top: 0;
              margin-bottom: 10px;
              @media screen and (max-width: 1500px) {
                font-size: 3.5em;
              }
              @include for-size(desktop-down) {
                margin-bottom: 5px;
                font-size: 2.5em;
                letter-spacing: 1px;
              }
              @include for-size(tablet-down) {
                font-size: 3.5vw;
                letter-spacing: 1;
              }
              @include for-size(phone-down) {
                font-size: 2rem;
                margin-bottom: 10px;
              }
            }
            p {
              color: #3B3B3B;
              font-size: 1.4em;
              line-height: 2;
              max-width: 800px;
              @media screen and (max-width: 1500px) {
                font-size: 1.2em;
              }
              @include for-size(desktop-down) {
                font-size: 1em;
                line-height: 1.75;
              }
              @include for-size(tablet-down) {
                font-size: 1.75vw;
              }
              @include for-size(phone-down) {
                font-size: 1.1rem;
              }
            }
            a {
              transition: all ease .35s;
              position: relative;
              &:before {
                position: absolute;
                content: "";
                width: 100%;
                height: 1px;
                top: 100%;
                left: 0;
                background: #ff7000;
                transition: transform 0.5s;
                transform: scaleX(0);
                transform-origin: right;
              }
              &:hover {
                &:before {
                  transform: scaleX(1);
                  transform-origin: left;
                }
              }
            }
            .copy {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              @media screen and (max-width: 1750px) {
                p {
                  max-width: 90%;
                }
              }
              @include for-size(tablet-down) {
                p {
                  max-width: 87.5%;
                }
              }
              @include for-size(phone-down) {
                position: relative;
                top: 0;
                transform: none;
                padding: 0 20px;
                p {
                  max-width: 100%;
                }
              }
            }
            .button.link {
              font-size: 1em;
              background: transparent;
              color: $color_fx_orange;
              padding-left: 0;
              padding-right: 0;
              margin-bottom: 0;
              @include for-size(desktop-down) {
                font-size: .88em;
              }
              @include for-size(tablet-down) {
                font-size: .8em;
              }
              @include for-size(phone-down) {
                width: auto;
              }
              @include for-size(phone-only) {
                width: auto !important;
                //font-size: .75rem;
              }
                &:before {
                  display: none;
                }
                &:after {
                  font-family: 'fontello';
                  content: '\e805';
                  display: block;
                  position: absolute;
                  right: -25px;
                  top: 45%;
                  -webkit-transform: translateY(-50%) rotate(90deg);
                  transform: translateY(-50%) rotate(90deg);
                  color: #ff6600;
                  -webkit-transition: all .75s;
                  transition: all .75s;
                }
                &:hover {
                  &:after {
                    right: -50px;
                    color: #fff;
                  }
                }
            }
          }
        }

      }

      #bottom_cta_container {
        padding-top: 100px;
        margin-bottom: 35px;
        background-color: #fff;
        @include for-size(desktop-down) {
          padding-top: 30px;
        }
        @include for-size(phone-down) {
          padding-top: 0px;
        }
        .section_inner {
          max-width: 1600px;
        }
          h3 {
            color: #3B3B3B;
            margin-bottom: 60px;
              @include for-size(desktop-down) {
                font-size: 2.25em;
              }
              @include for-size(tablet-down) {
                font-size: 1.75em;
              }
              @include for-size(phone-down) {
                margin-bottom: 30px;
              }
              @include for-size(phone-only) {
                font-size: 1.5em;
              }
          }
          a.cta {
            @include for-size(phone-down) {
              font-size: .85rem;
            }
            @include for-size(phone-only) {
              font-size: .75em;
              padding-left: 20px;
              padding-right: 20px;
              width: 75%!important;
            }
          }
      }

  }

}

//Translate Widget
.node-type-product .reveal-modal-bg {
  opacity: .98 !important;
}
.node-type-product {
  .translate_link_container {
    width: 100%;
    max-width: 1160px;
    margin: 0 auto;
    padding-left: 1em;
    padding-right: 1em;
    display: table;
    position: relative;
    top: -52px;
    right: 200px;
  }
}

.translate_link_container {
  .translate_link {
    font-weight: 500;
    line-height: 1;
    padding: 6px 10px 3px;
    position: absolute;
    right: 150px;
    top: 156px;
    z-index: 2;
    transition: hover .5s;
    &.no-supp {
      right: -35px;
    }
    @media screen and (max-width: 1370px) {
      top: 152px;
      font-size: 14px;
    }
    @include for-size(phone-down) {
      position: fixed;
      right: -2px !important;
      top: 140px;
      background: #EE7623;
      color: #fff;
      border-radius: 20px 0 0 20px;
      padding-bottom: 9px;
      z-index: 3;
      border: solid 1px #c4631ea8;
    }
    &:before {
      background-color: #EE7623;
      color: #fff;
      padding: 5px;
      border-radius: 50%;
      width: 27px;
      height: 27px;
      font-size: 16px;
      display: inline-block;
      text-align: center;
      margin-right: 5px;
      line-height: 1.15;
    }
  }
}

// SRP-RGBW
body.page-node-358286 {
  .accessories_heading {
    .special_note {
      display: none;
    }
  }
}

// Accessory Sub Title {
.field-name-field-sub-title {
  display: none;
  font-size: 75%;
  color: rgba(0, 0, 0, .75);
}
.node-type-product {
  .accessory_container {
    .field-name-field-sub-title {
      display: block;
      margin-top: -15px;
      margin-bottom: 30px;
    }
  }
}

// Generated Photometric Charts
body.html.page-node.page-node-.node-type-photometric-chart{
  #page-title{
    display:none;
  }
}
.photometric-title{
  font-size:1.5em;
  font-weight:500;
  color:#222;
}

.field-name-field-dynamic-photometrics {
  .field-items {
    .field-item {
      display: inline-block;
      width: 45%;
      margin-bottom: 25px;
      &.first,
      &.even {
        margin-right: 4%;
      }
    }
  }
}
table.photometrics {
  border:0px;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  padding: 10px;
  background: white;
  color: #535353;
  margin-bottom: 20px;
  margin-top:20px;
  .path {
    text-align: center;
  }
  .tr {
    &:last-of-type {
      border: none;
    }
  }
  td {
    padding: 5px;
    text-align: center;
  }

  .square {
    display: inline-block;
    width: 15px;
    height: 15px;
    background: grey;
    position: relative;
    top: 0px;
    margin-right: 5px;
    }
  .header {
    text-align: left;
    border-width: 0;
    font-weight: 500;
    font-size:1.75em;
    color:#222;
    padding-bottom:30px;
    border-bottom: 4px solid #E0E0E0;
  }
  .key {
    font-weight: 600;
  }
  .led {
    text-align: center;
  }
  /* Chart colors disabled
  .blue {
    .degree {
      color: #003C58 !important
    }
  }
  .red {
    .degree {
      color: #E8271B !important;
    }
  }
  .orange {
    color: #EE7623 !important;
  } */
  .degree {
    margin-left: -1px;
  }
  .key {
    strong,
    a {
      color: #222 !important;
    }
  }
  thead {
    background:#fff;

    tr:first-child {
      border-width: 0;
      white-space: nowrap;
      font-size:1.5em;
      font-weight:400;
      border-top:4px solid #e4e1dd;

      td{
        color:#003c58;
      }
    }

    .subhead{
      td{
        text-align:center;
      }
    }

    .key {
      background: #fff;
    }
  }
  th {
    color: #7C7A78;
    border-width: 0;
  }
  .ft,.cb,.bw {
    text-align: center;
    font-weight:400;
    color:#003c58;
  }
  .sub {
    td {
      border-width: 0;
      font-size: 40%;
      text-transform: capitalize;
      text-align: center;
    }
  }
  tbody {
    position: relative;
    overflow: hidden;
    background: transparent;
    z-index: 1;
    &::before,
    &::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    //background: radial-gradient(farthest-side at top, rgba(247, 131, 47, .35) ,#0000);
    //mask: conic-gradient(from -225deg at top,#0000, #000 1deg 90deg, #0000 91deg);
    //-webkit-mask: -conic-gradient(from -225deg at top,#0000, #000 1deg 90deg, #0000 91deg);
    }
    &::after {
      top: 10px;
    }
    /*
    .blue {
      color: #325D79 !important;
    }
    .red {
      color: #E52114 !important;
    } */
  }
  tr.even, table tr.alt, tr:nth-of-type(2n) {
    //background: transparent;//
    //background: #d0d0d045;//
    //border-color: #C5C1BF;//
   // border-style: solid;//
   // border-width: 1px;//
  }
  tr {
    border: none;
    td {
      white-space: nowrap;
    }
  }
  tr:last-of-type {
    //border-bottom: 1px solid #ccc;//
    td {
      font-size: 14px !important;
      .square {
        width: 10px !important;
        height: 10px !important;
        top: 2.5px !important;
      }
    }
  }
  th,
  tr td{
    overflow: hidden;
    padding: 12px;
    word-break: normal;
    background: transparent;
    color: #707070;
    border: 1px solid #C5C1BF;
  }
  tr td:last-child{
    border-right:0;
  }
  tr td:first-child{
    border-left:0;
  }
  .key {
    font-weight: 500;
    padding: 12px;
  }
  tr:nth-of-type(even) {
    background: none;
  }
  .field-name-field-vertical-beam-width,
  .field-name-field-horizontal-beam-width {
    display: inline-block;
  }
}

/* FX Design Service Web Form */

  .page-node-301836 {
    .headers_container.default h1#page-title {
      margin-top: 10px;
      margin-bottom: 0;
      color: #2ba6cb;
      font-weight: 200;
      margin-right: 5px;
      font-size: 46px;
    }

    .webform-client-form-301836{
      width:80%;
    }

    .webform-progressbar {
      width:60%;
      position:absolute;
      left:25px;
    }

    .webform-progressbar-outer {
      position: relative;
      width: 100%;
      height: 5px;
      margin: 0.35em -1px 2em;
      background-color: #777;
      border:none;
    }

    .webform-progressbar-page {
      position: absolute;
      width: 20px;
      height: 20px;
      margin: -10px -4px;
      border: 1px solid #777;
      border-radius: 20px;
      background-color: #777;
      border:none;
    }

    .webform-progressbar-page.current {
      background-color: #EE7623;
      border:none;
    }

    .webform-progressbar-inner {
      background-color: #EE7623;
      border:none;
      height:5px;
    }
    .webform-progressbar-page.completed {
      background-color: #EE7623;
      border:none;
    }

    .webform-component--customer-details-title{
      padding-top:40px;
      padding-bottom: 0px;
    }

    .progress-title{
      font-size: 1.4em;
      margin-top: 40px;
    }

    input[type="file"], input[type="checkbox"], input[type="radio"], select {
      margin:0px;
    }

    .form-item{
      padding-top:10px;
    }

    .form-managed-file .form-file, .form-managed-file .form-submit {
      margin-top:10px;
    }

    select{
      padding-top:5px;
      padding-bottom:5px;
      font-size:.875em;
      width:25%;
    }

    button{
      padding-left: 3.125em;
      padding-right: 3.125em;
    }

    .form-submit:focus, .form-submit:hover, .form-submit:not(:focus){
      font-size:14px;
    }

    .button-primary, .button-secondary{
      border-radius: 0px;
      padding-top: 1em;
      padding-bottom: 0.875em;
    }

    .webform-draft.form-submit{
      padding-left:1em;
      padding-right:1em;
      font-weight:100;
      background-color:#fff !important;
      color:#63717a;
    }

    .webform-previous.form-submit {
      background-color:#63717a !important;
    }

    .secondary.form-submit {
      margin-right: 3px;
      font-size: 14px;
      font-weight: 500;
      margin-top: 0;
      height: 40px;
      float:none;
    }

    .requested_delivery_date label {
      display:inline;
      width:25%;
    }

    #terms{
      margin-top:60px;
    }
  }

/* FX Design Service Landing Page (old nodes 320772 & 328332) */
  .page-node-331656 {
    #design-service-hero {
      background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(0,0,0,.75)), url(/sites/fxl.com/files/design-service/fxl_ds_hero.jpg);
      background-repeat: no-repeat;
      background-position-y: 75%;
      background-position-x: center;
      background-size: cover;
      overflow: hidden;
      height: 350px;
      padding-top: 90px;
      display: flex;
      align-items:flex-end;
    }

    #design-service-hero h2 {
      color:#fff;
      padding-left:30px;
      margin-bottom:1em;
    }

    .design-service-container {
      margin:auto;
      width:1400px;
      position:realtive;
    }

    #design-service-intro {
      align-items:center;
    }

    #design-service-types {
      padding:20px;
      margin:auto;
      width:100%;
      background-color:#e4e1dd;
    }

    .section_inner {
      padding:30px 30px 30px 30px;
      width:100%;
      display:flex;
      flex-wrap:wrap;
    }

    .right-side {
      flex:40%;
      padding-left:20px;
    }

    .left-side {
      flex:60%;
      padding-right:20px;
    }

    #intro {
      padding-top:30px;
      font-size:1.1em;
    }

    #fxlds-logo{
      position:relative;
      top:-8px;
      float:right;
      max-height:85%;
    }

    .designplan-copy{
      padding:20px;
      text-align: left;
      font-size: 1.025em;
    }

    .self-service{
      padding-right: 15px;
      flex:25%;
    }

    .pro-service{
      padding-left: 1px;
      flex:75%;
    }

    .self-box{
      padding: 0px 0px 0px 0px;
    }

    .pro-box{
      width:100%;
      display: flex;
      gap: 15px;
    }

    .horizontal-line{
      border-top: 1px solid #000;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
      height: 50px;
      width: 65%;
      margin: auto;
      position: relative;
      bottom: 0px;
    }

    .self-horizontal-line{
      border-top: 1px solid #000;
      height: 50px;
      width: 65%;
      margin: auto;
      position: relative;
      bottom: 0px;
    }

    .vertical-line{
      display: inline-block;
      border-left: 1px solid #000;
      position: relative;
      height: 50px;
      top: 0px;
      margin-left: 50%;
      margin-right: 50%;
    }

    .designplan{
      margin: 0px;
      min-height: 100px;
      padding: 0px;
      max-width: 32%;
    }

    .designplan-orange{
      background-color: #ee7623;
      color: white;
      font-weight: 500;
      font-size: 1em;
      padding: 15px;
      text-align: center;
      &:hover{
        background-color:#f5bf53;
        transition: all 1s ease;
      }
    }

    h3.service {
      font-size: 22px;
      font-weight: 100;
      text-align: center;
      text-transform: uppercase;
      padding-bottom: 1px;
      hyphens: none;
      color:#000;
    }

    .mydesignplan{
      background-color: #a1bdcc;
      position: relative;
      min-height: 650px;
      margin-top: 1px;
    }

    .mydesignplan h5{
      text-align:center;
    }

    .mydesignplan img, .proplan img{
      display:block;
      margin:auto;
      width:100%;
    }

    .proplan{
      background-color: #003c58;
      color: #fff;
      position: relative;
      min-height: 650px;
    }
    .proplan h5{
      color: white;
      text-align:center;
    }

    .plan-details-blue{
      background-color:#63717a;
      color:white;
      padding-top:60px;
      padding-bottom:60px;
    }

    .plan-details-blue h2{
      color:#fff;
    }

    .plan-details-blue li, .plan-details-light li{
      margin-left:15px;
    }

    .slider{
      width: 300px;
      position: relative;
      padding-top: 275px;
      margin-top: 40px;
      margin-left:0px;
    }

    .basiclayoutslide{
      padding-top:400px;
    }

    .generalconceptslide{
      padding-top:200px;
    }

    .slider>img{
      position: absolute;
      left: 0; top: 0;
      transition: all 0.5s;
    }
    .slider label {
      margin: auto 9px;
      border: 3px solid #999;
      float: left;
      cursor: pointer;
      transition: all 0.5s;
      opacity: 0.6;
    }

    .slider label img{
      display: block;
            height:25px;

    }

    .basiclayoutslide input[name='slide_switch_1'] {
      display: none;
    }
    .generalconceptslide input[name='slide_switch_2'] {
      display: none;
    }

    .slider input[name='slide_switch'] {
      display: none;
    }

    .basiclayoutslide input[name='slide_switch_1']:checked+label {
      border-color: #666;
      opacity: 1;
    }
    .generalconceptslide input[name='slide_switch_2']:checked+label {
      border-color: #666;
      opacity: 1;
    }
    .slider input[name='slide_switch']:checked+label {
      border-color: #666;
      opacity: 1;
    }

    .basiclayoutslide input[name='slide_switch_1'] ~ img {
      opacity: 0;
      transform: scale(1.1);
    }

    .generalconceptslide input[name='slide_switch_2'] ~ img {
      opacity: 0;
      transform: scale(1.1);
    }

    .slider input[name='slide_switch'] ~ img {
      opacity: 0;
      transform: scale(1.1);
    }

    .basiclayoutslide input[name='slide_switch_1']:checked+label+img {
      opacity: 1;
      transform: scale(1);
    }
    .generalconceptslide input[name='slide_switch_2']:checked+label+img {
      opacity: 1;
      transform: scale(1);
    }
    .slider input[name='slide_switch']:checked+label+img {
      opacity: 1;
      transform: scale(1);
    }

    @media (max-width: 800px) {

      .right-side, .left-side {
        flex:100%;
        padding-left:10px;
      }

      #intro{
        padding-bottom:30px;
        font-size:1.1em
      }

      #fxlds-logo{
        margin:auto;
        display:block;
        float:none;
      }

      .pro-box{
        flex-wrap:wrap;
      }

      .self-service, .pro-service{
        flex:100% !important;
      }

      .horizontal-line {
        border-left: none !important;
        border-right: none !important;
      }

      .pro-service{
        margin-top:60px;
      }
      .designplan{
        flex:100% !important;
        min-width:100%;
      }
    }
  }

/* FX Design Service Webform Confirmation Page */
  .page-node-319801{
    .ds-wrapper{
      display:flex;
      flex-wrap:wrap;
      align-items:center;
    }

    .headers_container.default h1#page-title {
      margin-top: 10px;
      margin-bottom: 0;
      color: #2ba6cb;
      font-weight: 200;
      margin-right: 5px;
      font-size: 46px;
    }

    .ds-left-side{
      flex:50%;
      text-align:left;
    }

    .ds-right-side img{
      margin:auto;
      display:block;
      max-height:350px;
    }

    .ds-right-side{
      flex:50%;
      vertical-align:middle;
        padding:40px;
      }
    }

/* FX Design Lighting Techniques Landing page */
.page-node-374116{

  .lighting-technique-section h2{
    font-weight:200;
    font-size:1.8em;
  }

  .lt-grey{
    background-color:#f8f7f6;
  }

  .lighting-technique-container{
    width:100%;
    margin:0 auto;
    padding-right:0px;
    padding-left:0px;
  }

 .lighting-technique-hero{
    width:100%;
    margin:auto;
    min-height:400px;
    background-image:url(/sites/fxl.com/files/_lighting-technique/CulDeSac_AZ_ZDC_015.jpg);
    background-position-y: 75%;
    background-position-x: center;
    background-size: cover;
    overflow: hidden;

  }

  .lighting-technique-hero h1{
    position: relative;
    top: 275px;
    padding-left: 10%;
    color:#fff;
  }

  .lighting-technique-menu{
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
    align-items:center;
  }

  .lt-menu-item{
    padding:1em;
    text-transform:uppercase;
    font-size:.75em;
  }

  .lighting-technique-section{
    width:80%;
    margin:auto;
    padding-bottom:60px;
    padding-top:60px;
  }

  .lt-line{
    color:#707070;
  }

  .lt-section-cta{
    width:100%;
    padding-bottom:2em;
    display:inline-block;
    font-size:14px;
  }

  .lt-cta-outline{
    padding: 5px;
    font-weight: 100;
    color: #EE7623;
    margin-right:4px;
    text-transform:uppercase;
  }

  .lt-cta-outline img{
    padding-right:5px;
  }

  .lt-card-container{
    padding-bottom: 15px;
    display: grid;
    grid-template-columns: auto auto auto;
    margin-bottom: calc(5vh + 5%);
  }

  .lt-card{
    font-size: 1.2em;
    margin: 0;
    perspective: 800px;
    transition: all 0.3s ease 0s;
    height:350px;
  }

  .lt-card-inner{
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 0px 0px 0 rgba(0,0,0,0);
  }

  .lt-card:hover .lt-card-inner {
    transform: rotateY(180deg);
  }

  .lt-card-inner{
    cursor: alias;
    transform-style: preserve-3d;
    transition: transform 0.4s ease 0s;
    -webkit-animation: giro 1s 1;
    animation: giro 1s 1;
  }

  .lt-card-front, .lt-card-back {
    backface-visibility: hidden;
    box-sizing: border-box;
    color: white;
    display: block;
    font-size: 1.2em;
    height: 100%;
    padding: 0.2em;
    position: absolute;
    text-align: center;
    width:100%;
  }

  .lt-card-back{
     transform: rotateY(180deg);
  }

  .lt-card-front img, .lt-card-back img{
    width:100%;
    margin:auto;
    padding:0px;
  }

  .card-name, .card-description{
    padding: 1px;
  }

  .card-name p{
    font-size: 16px;
    font-weight:400;
    color:#707070;
    text-align:center;
  }

  .lt-flip{
    width: 17px !important;
    position: relative;
    bottom: 5px;
    left: 4px;
   }

  .card-description p{
    font-size:16px;
    font-weight: 400;
    text-align: center;
    padding: 5px 1px;
    color:#707070;
    line-height:1.2;
  }

  @media (max-width: 768px) {

    .lt-section-cta a{
      padding-bottom:2em;
    }

    .lt-card-inner{
      height:350px;
    }

    .lt-card-container{
      margin-bottom: 10px;
      padding-bottom:10px;
    }

  }

  @media (max-width: 600px) {

   .lt-section-cta a{
      padding-bottom:0.5em;
      font-size:1.25em;
    }

    .lt-card-container{
      grid-template-columns: auto;
      margin-bottom: 0%;
      padding-bottom: 1px;
    }

    .lt-cta-outline{
      display:block;
    }

    .lt-card{
      height:425px;
    }
  }
}

.section-searchunify h1,
.su__btn-back-to-top {
  display: none;
}

//FXL Collections Landing Page
.fxl-collections-wrapper {
  .fxl-collections-hero {
    display: flex;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image : url('/sites/fxl.com/files/_banners/fxl_collections/collections_header.jpg');
    height: 650px;

    .copy {
      margin: auto auto 0;
      padding: 4em 2.75em 2em;
      width: 85%;

      h1 {
        font-size: 51px;
        color: white;
        font-weight: 500;
        text-shadow: 0px 1px 2px rgba(71, 71, 71, 0.233);
      }
    }
  }

  .fxl-collections-container {
    width: 100%;
    margin: auto;

    .copy {
      max-width: 1180px;
      margin: auto;
      padding: 4em 2em;
      max-width: 85%;
    }

    h2 {
      color: #222222;
      font-weight: 400;
      font-size: 43px;

      strong {
        font-weight: 500;
      }
    }

    p {
      font-size: 26px;
      line-height: 2;
      max-width: 85%;
    }

    .fxl-collections-card {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      justify-content: space-between;
      padding: 0px 5px;
      margin: 1em auto;

      &:nth-child(even) {
        background: #F0F3F4;
      }

      &:nth-child(odd) {
        background: #F9FAFA;
      }

      h3 {
        color: #717A84;
        text-transform: uppercase;
        font-size: 36px;
        font-weight: 500;

        strong {
          color: #003c58;
          font-weight: 600;
        }
      }

      .left {
        width: 45%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        min-height: 350px;
      }

      .apex {
        background-image: url('/sites/fxl.com/files/_banners/fxl_collections/apex_tile.jpg');
      }

      .cora {
        background-image: url('/sites/fxl.com/files/_banners/fxl_collections/cora_tile.jpg');
      }

      .designer {
        background-image: url('/sites/fxl.com/files/_banners/fxl_collections/designer_tile.jpg');
      }

      .evo {
        background-image: url('/sites/fxl.com/files/_banners/fxl_collections/evo_tile.jpg');
      }

      .exa {
        background-image: url('/sites/fxl.com/files/_banners/fxl_collections/exa_tile.jpg');
      }

      .garden {
        background-image: url('/sites/fxl.com/files/_banners/fxl_collections/garden_tile.jpg');
      }

      .modern {
        background-image: url('/sites/fxl.com/files/_banners/fxl_collections/modern_tile.jpg');
      }

      .pinnacle {
        background-image: url('/sites/fxl.com/files/_banners/fxl_collections/pinnacle_tile.jpg');
      }

      .runa {
        background-image: url('/sites/fxl.com/files/_banners/fxl_collections/runa_tile.jpg');
      }

      .stella {
        background-image: url('/sites/fxl.com/files/_banners/fxl_collections/stella_tile.jpg');
      }

      .terra {
        background-image: url('/sites/fxl.com/files/_banners/fxl_collections/terra_tile.jpg');
      }

      .vantage {
        background-image: url('/sites/fxl.com/files/_banners/fxl_collections/vantage_tile.jpg');
      }

      .right {
        padding: 2em;
        width: 55%;

        p {
          max-width: 700px;
          line-height: 1.6;
          font-size: 22px;
        }

        .fxl-collections-products {
          display: inline-flex;
          overflow-x: scroll;
          flex-wrap: nowrap;
          width: 100%;

          .product-wrapper{
            display: inline-flex;
            overflow-x: scroll;
            flex-wrap: nowrap;
            width: 56%;

            .product {
              border: none;
              width: 128px;
              margin: 4px;

              img {
                width: 100%;
                margin: 0 auto 5px;
                padding-right: 2px;

                &:hover {
                  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15)
                }
              }

              .product-name {
                font-size: 14px;
                font-weight: 500;
                color: #003C52;
                margin-left: 2px;
                display: block;
              }
            }
          }
          .cta {
            width: 250px;
            font-size: 14px;
            display: flex;
            flex-wrap: wrap;
            margin-left: 2em;
            margin-right: auto;

            .more {
              border: none;
              font-size: 12px;
              font-weight: 500;
              color: #222;
              padding-bottom: 1em;
              align-self: flex-end;
              width: 100%;
            }

            .button {
              border: 1px solid $color_fx_orange;
              background: none;
              text-align: start;
              color: $color_fx_orange;
              text-transform: capitalize;
              padding: .75em 1em;
              border-radius: 10px;
              white-space: nowrap;
              max-height: 36px;

              &:hover {
                background-color: #FFF1E8;
              }
            }
          }
        }
      }
      &:last-child {
        margin-bottom: 60px;
      }
    }
    @media (max-width: 991px) {
      .copy {
        padding: 1.5em;
      }

      .fxl-collections-card {
        flex-wrap: wrap !important;

        .left,
        .right {
          width: 100% !important;
        }
        .product-wrapper{
          width: 100% !important;
        }
      }
      @media (max-width: 767px) {
        .apex {
            background-image: url('/sites/fxl.com/files/_banners/fxl_collections/apex_tile_m.jpg') !important;
          }

        .cora {
          background-image: url('/sites/fxl.com/files/_banners/fxl_collections/cora_tile_m.jpg')!important;
        }

        .designer {
          background-image: url('/sites/fxl.com/files/_banners/fxl_collections/designer_tile_m.jpg') !important;
        }

        .evo {
          background-image: url('/sites/fxl.com/files/_banners/fxl_collections/evo_tile_m.jpg') !important;
        }

        .exa {
          background-image: url('/sites/fxl.com/files/_banners/fxl_collections/exa_tile_m.jpg') !important;
        }

        .garden {
          background-image: url('/sites/fxl.com/files/_banners/fxl_collections/garden_tile_m.jpg') !important;
        }

        .modern {
          background-image: url('/sites/fxl.com/files/_banners/fxl_collections/modern_tile_m.jpg') !important;
        }

        .pinnacle {
          background-image: url('/sites/fxl.com/files/_banners/fxl_collections/pinnacle_tile_m.jpg') !important;
        }

        .runa {
          background-image: url('/sites/fxl.com/files/_banners/fxl_collections/runa_tile_m.jpg') !important;
        }

        .stella {
          background-image: url('/sites/fxl.com/files/_banners/fxl_collections/stella_tile_m.jpg') !important;
        }

        .terra {
          background-image: url('/sites/fxl.com/files/_banners/fxl_collections/terra_tile_m.jpg') !important;
        }

        .vantage {
          background-image: url('/sites/fxl.com/files/_banners/fxl_collections/vantage_tile_m.jpg') !important;
        }

        .right {
          .fxl-collections-products {
            flex-wrap: wrap !important;

            .product-wrapper {
              width: 100 !important;
            }

            .cta {
              width: 100% !important;
              margin-left: 0 !important;
              margin-top: 1.25em;

              .button {
                text-align: center !important;
              }
            }
          }
        }
      }
    }
  }
}

  // Collection Colors

  .color-designer {
    color: #004D6B
  }

  .color-vantage {
    color: #006BA9;
  }

  .color-modern {
    color: #4A4443;
  }

  .color-cora {
    color: #E76204;
  }

  .color-terra {
    color: #6D5F27;
  }

  .color-runa {
    color: #61281D;
  }

  .color-pinnacle {
    color: #C66A17;
  }

  .color-stella {
    color: #9D374A;
  }

  .color-exa {
    color: #C17E01
  }

  .color-garden {
    color: #7E8F3D;
  }

  .color-apex {
    color: #7F7976;
  }

  .collections-bg {
    background: #F9FAFA;
  }

//Fx about page
.fx-about-page {
  h2 {
    font-size: 42px;
  }
  a.button {
    text-transform: none;
  }

  .hero {
    background-image: url(https://www.fxl.com/sites/fxl.com/files/about/about_header.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
    overflow: hidden;
    position: relative;
    @include flexbox;
    @include align-items(center);
    @include justify-content(center);

    @include respond-to(max-width, 1280px) {
      height: 550px;
    }

    @include respond-to(max-width, 1030px) {
      height: 420px;
    }

    @include respond-to(max-width, 768px) {
      height: 400px;
    }

    @include respond-to(max-width, 580px) {
      height: 320px;
    }

    p {
      font-size: 22px;
      line-height: 2;

      @include respond-to(max-width, 1280px) {
        font-size: 18px;
      }

      @include respond-to(max-width, 640px) {
        font-size: 16px;
      }
    }

    h2 {
      position: absolute;
      bottom: 40px;
      width: 100%;
      max-width: 1080px;
      color: #fff;
      font-size: 42px;

      @include respond-to(max-width, 1280px) {
        font-size: 38px;
      }

      @include respond-to(max-width, 640px) {
        font-size: 32px;
      }
    }
  }

  #sticky_smooth_scroll_nav {
    background-color: $color_fx_blue;
    padding: 0;

    @media (max-width: 768px) {
      display: block;
    }

    ul {
      overflow-x: auto;
      white-space: nowrap;

      li {
        padding: 1.25em 0;

        a {
          font-size: 14px;
          font-weight: 500;
          text-decoration: none;
          border-bottom: none;
          padding: .5em;
        }

        &:hover {
          background-color: $color_fx_orange;
        }
      }
    }
  }

  #sticky_smooth_scroll_nav.scroll-to-fixed-fixed {
    padding: 0;
    box-shadow: none;
    position: fixed;
    top: 130px !important;

    @media (max-width: 768px) {
      top: 50px !important;
    }
  }

  #intro {
    display: flex;
    flex-wrap: nowrap;
    max-width: 1080px;
    margin: 5em auto;
    padding: 0 2em;

    .left {
      width: 50%;
    }

    .right {
      width: 50%;
      text-align: center;
      margin: auto;

      img {
        max-width: 375px;
      }
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;

      .left,
      .right {
        width: 100%;
        padding: 0 2em;

        img {
          max-width: 100%;
          margin-bottom: 2em;

        }
      }
    }
  }

  #advantages_lighting {
    background-image: url('https://www.fxl.com/sites/fxl.com/files/about/Monserate_Winery.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    height: 650px;

    .advantage_container {
      background: rgba(255, 255, 255, 0.8);
      width: 35%;
      min-width: 350px;
      padding: 2em;
      position: absolute;
      top: 15%;
      right: 15%;
    }

    @media (max-width: 868px) {
      height: auto;
      min-height: 800px;

      .advantage_container {
        width: 100%;
        top: unset;
        bottom: 0;
        right: 0;
      }
    }
  }

  #selecting_fixtures .selecting_container,
  #luxor_technology .luxor_container {
    max-width: 1180px;
    margin: 5em auto;
    padding: 0 2em;

    h2,
    p {
      max-width: 800px;
    }

    a {
      text-align: center;
    }

    span {
      text-align: center;
      display: block;
    }

    .card_wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: space-between;

      .card {
        width: 32%;
        border-top: 1px solid $color_fx_orange;
        border-bottom: 1px solid $color_fx_orange;
        border-left: 1px solid $color_fx_orange;
        border-right: 1px solid $color_fx_orange;

        .card_image_container {
          border-bottom: none;

          .card_image {
            position: unset;
            height: 330px;
          }
        }

        .card_text {
          padding: 1em 1.25em;

          h3 {
            a{
              color: $color_fx_blue;
            }
          }
        }
      }
    }

    @media (max-width: 900px) {
      .card_wrapper {
        flex-wrap: wrap;
        justify-content: space-around;

        .card {
          width: 100%;
        }
      }
    }
  }

  #product_portfolio {
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 2em;

    span {
      text-align: center;
      display: block;
    }

    .portfolio_container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 3em;
      justify-content: space-around;

      h2,
      p {
        text-align: start;
      }

      .portfolio_products_wrapper {
        width: 18%;
        margin-bottom: 1em;

        .portfolio_products_image {
          margin: auto;
          width: 200px;
          height: 200px;
          box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.15);
          background-size: cover;
          background-repeat: no-repeat;
        }

        .np_product {
          background-image: url('https://www.fxl.com/sites/fxl.com/files/about/Component_73-1.jpg');

          &:hover {
            background-image: url('https://www.fxl.com/sites/fxl.com/files/about/hover/Component_73-1-h.jpg');
          }
        }

        .pb_product {
          background-image: url('https://www.fxl.com/sites/fxl.com/files/about/Component_73-2.jpg');

          &:hover {
            background-image: url('https://www.fxl.com/sites/fxl.com/files/about/hover/Component_73-2-h.jpg');
          }
        }

        .dm_product {
          background-image: url('https://www.fxl.com/sites/fxl.com/files/about/Component_73-3.jpg');

          &:hover {
            background-image: url('https://www.fxl.com/sites/fxl.com/files/about/hover/Component_73-3-h.jpg');
          }
        }

        .mpl_product {
          background-image: url('https://www.fxl.com/sites/fxl.com/files/about/Component_73-4.jpg');

          &:hover {
            background-image: url('https://www.fxl.com/sites/fxl.com/files/about/hover/Component_73-4-h.jpg');
          }
        }

        .mpz_product {
          background-image: url('https://www.fxl.com/sites/fxl.com/files/about/Component_73-5.jpg');

          &:hover {
            background-image: url('https://www.fxl.com/sites/fxl.com/files/about/hover/Component_73-5-h.jpg');
          }
        }

        .lf_product {
          background-image: url('https://www.fxl.com/sites/fxl.com/files/about/Component_73-6.jpg');

          &:hover {
            background-image: url('https://www.fxl.com/sites/fxl.com/files/about/hover/Component_73-6-h.jpg');
          }
        }

        .hs_product {
          background-image: url('https://www.fxl.com/sites/fxl.com/files/about/Component_73-7.jpg');

          &:hover {
            background-image: url('https://www.fxl.com/sites/fxl.com/files/about/hover/Component_73-7-h.jpg');
          }
        }

        .nl_product {
          background-image: url('https://www.fxl.com/sites/fxl.com/files/about/Component_73-8.jpg');

          &:hover {
            background-image: url('https://www.fxl.com/sites/fxl.com/files/about/hover/Component_73-8-h.jpg');
          }
        }

        .ve_product {
          background-image: url('https://www.fxl.com/sites/fxl.com/files/about/Component_73-9.jpg');

          &:hover {
            background-image: url('https://www.fxl.com/sites/fxl.com/files/about/hover/Component_73-9-h.jpg');
          }
        }

        .lp_product {
          background-image: url('https://www.fxl.com/sites/fxl.com/files/about/Component_73-10.jpg');

          &:hover {
            background-image: url('https://www.fxl.com/sites/fxl.com/files/about/hover/Component_73-10-h.jpg');
          }
        }

        .portfolio_products_title {
          margin-top: 1em;
          width: 200px;
          margin-left: auto;
          margin-right: auto;

          a {
            color: #222;

            strong {
              color: $color_fx_blue;
            }
          }
        }
      }
    }

    @media (max-width: 1140px) {
      padding: 0 2em;

      .portfolio_container {
        flex-wrap: wrap;
        justify-content: center;

        .portfolio_products_wrapper {
          width: 24%;
          margin-bottom: 2em;
        }
      }
    }

    @media (max-width: 768px) {
      .portfolio_container {
        .portfolio_products_wrapper {
          width: 48%;
        }
      }
    }
  }

  #find_distributors {
    background-image: url('https://www.fxl.com/sites/fxl.com/files/about/FX_Design_Residential_Project.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    height: 650px;
    background-position: right;
    margin-top: 3em;

    .lighting_distributors {
      background: rgba(255, 255, 255, 0.8);
      width: 28%;
      min-width: 350px;
      padding: 2em;
      position: absolute;
      top: 15%;
      left: 15%;
    }

    @media (max-width: 868px) {
      height: auto;
      min-height: 800px;

      .lighting_distributors {
        width: 100%;
        top: unset;
        bottom: 0;
        left: 0;
      }
    }
  }
  #get_inspired {
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 2em;

    .get_inspired_container {
      padding: 3em 0;
    }
  }
  #contact {
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 2em;

    .contact_container {
      padding: 3em 0;
    }
  }
}