// MM hero banner, changed to left-center position
.page-node-621,
.page-node-51686,
.page-node-48861,
.page-node-37271,
.page-node-53576 {
	.headers_container {
		background-position: left center;
	}
}

/*table.photometrics td {
	text-align: center;
}*/
