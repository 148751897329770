/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* Fonts ------------------------------------------------------------- */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


@font-face {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  src: url('/sites/all/themes/fxl_responsive/fonts/fontello/fontello.eot?81396285');
  src: url("/sites/all/themes/fxl_responsive/fonts/fontello/fontello.eot?81396285#iefix") format("embedded-opentype"),
      url("/sites/all/themes/fxl_responsive/fonts/fontello/fontello.woff2?81396285") format("woff2"),
      url("/sites/all/themes/fxl_responsive/fonts/fontello/fontello.woff?81396285") format("woff"),
      url("/sites/all/themes/fxl_responsive/fonts/fontello/fontello.ttf?81396285") format("truetype"),
      url("/sites/all/themes/fxl_responsive/fonts/fontello/fontello.svg?81396285#fontello") format("svg");
}


/* Sero Light - 400 */
@font-face {
  font-family: "Sero";
  font-style: normal;
  font-weight: 400;
  src: url("/sites/all/themes/fxl_responsive/fonts/sero/SeroWebPro-Light.woff") format("woff");
}

/* Sero Light - 400 */
@font-face {
  font-family: "Sero";
  font-style: normal;
  font-weight: 400;
  src: url("/sites/all/themes/fxl_responsive/fonts/sero/SeroPro-LightItalic.otf") format("otf");
}

/* Sero Light Italic */
@font-face {
    font-family: 'SeroPro';
    src: url('/sites/all/themes/fxl_responsive/fonts/sero/SeroPro-LightItalic.eot');
    src: url('/sites/all/themes/fxl_responsive/fonts/sero/eroPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('/sites/all/themes/fxl_responsive/fonts/sero/SeroPro-LightItalic.woff2') format('woff2'),
        url('/sites/all/themes/fxl_responsive/fonts/sero/SeroPro-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

/* Sero Medium Italic */
@font-face {
    font-family: 'SeroPro';
    src: url('/sites/all/themes/fxl_responsive/fonts/sero/SeroPro-MediumItalic.eot');
    src: url('/sites/all/themes/fxl_responsive/fonts/sero/SeroPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('/sites/all/themes/fxl_responsive/fonts/sero/SeroPro-MediumItalic.woff2') format('woff2'),
        url('/sites/all/themes/fxl_responsive/fonts/sero/SeroPro-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

/* Sero Medium*/
@font-face {
    font-family: 'SeroPro';
    src: url('/sites/all/themes/fxl_responsive/fonts/sero/SeroPro-Medium.eot');
    src: url('/sites/all/themes/fxl_responsive/fonts/sero/SeroPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('/sites/all/themes/fxl_responsive/fonts/sero/SeroPro-Medium.woff2') format('woff2'),
        url('/sites/all/themes/fxl_responsive/fonts/sero/SeroPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

/* Sero Regular - 500 */
@font-face {
  font-family: "Sero";
  font-style: normal;
  font-weight: 500;
  src: url("/sites/all/themes/fxl_responsive/fonts/sero/SeroWebPro-Medium.woff") format("woff");
}

/* Sero Bold - 800 */
@font-face {
  font-family: "Sero";
  font-style: normal;
  font-weight: 800;
  src: url("/sites/all/themes/fxl_responsive/fonts/sero/SeroWebPro-Bold.woff") format("woff");
}

/* Sero Black - 900 */
@font-face {
  font-family: "Sero";
  font-style: normal;
  font-weight: 900;
  src: url("/sites/all/themes/fxl_responsive/fonts/sero/SeroWebPro-Black.woff") format("woff");
}/* I guess we'll try this method of adding font icons so put the classnames from fontello on element */
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* margin-left: .2em;*/

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}



.icon-right-open-1:after,
.icon-plus_sign:after,
.icon-ok:after,
.icon-cancel:after,
.icon-up-open-1:after,
.icon-up-open-big:after,
.icon-download-alt:after,
.icon-eye:before,
.icon-eye-off:before,
.icon-trash:before,
.icon-export:before,
.icon-list:before,
.icon-print:before,
.icon-lock:after,
.icon-search:after,
.icon-lock-open-alt:after,
.icon-twitter:after,
.icon-mail:after,
.icon-facebook:after,
.icon-globe:after,
.icon-help-circled:after,
.icon-share:after,
.icon-play:after,
.icon-gallery-icon:after,
.icon-calendar:before,
.icon-newspaper:before,
.icon-down_arrow_accordion_2_optimized:after,
.icon-info-circled-alt:after,
.icon-docs:before,
.icon-file-pdf:before,
.icon-youtube-play:after,
.icon-pencil:before,
.icon-linkedin:after,
.icon-chevron-right:after,
.icon-instagram:after,
.icon-home:after,
.icon-attention:after,
.icon-info:after,
.icon-more:before,
.icon-ok-circled:before,
.icon-link:before {
  font-family: "fontello";
}

.icon-right-open-1:after {
  content: '\e800';
}


.icon-chevron-right:after {
  content: '\e800';
}


.icon-plus_sign:after {
  content: '\e801';
}

.icon-ok:after {
  content: '\e802';
}

.icon-cancel:after {
  content: '\e803';
}

.icon-up-open-1:after {
  content: '\e804';
}

.icon-up-open-big:after {
  content: '\e805';
}

.icon-download-alt:after {
  content: '\e806';
}

.icon-eye:before {
  content: '\e807';
}

.icon-eye-off:before {
  content: '\e808';
}

.icon-trash:before {
  content: '\e809';
}

.icon-export:before {
  content: '\e80a';
}

.icon-list:before {
  content: '\e80b';
}

.icon-print:before {
  content: '\e80c';
}

.icon-lock:after {
  content: '\e80d';
}

.icon-search:after {
  content: '\e80e';
}

.icon-lock-open-alt:after {
  content: '\e80f';
}

.icon-twitter:after {
  content: '\e813';
}

.icon-mail:after {
  content: '\e814';
}

.icon-facebook:after {
  content: '\e815';
}

.icon-globe:before {
  content: '\e816';
}

.icon-help-circled:after {
  content: '\e818';
}

.icon-share:after {
  content: '\e81a';
}

.icon-play:after {
  content: '\e81b';
}

.icon-gallery-icon:after {
  content: '\e81d';
}

.icon-calendar:before {
  content: '\e820';
}

.icon-newspaper:before {
  content: '\e821';
}

.icon-down_arrow_accordion_2_optimized:after {
  content: '\e829';
}

.icon-info-circled-alt:after {
  content: '\f086';
}

.icon-docs:before {
  content: '\f0c5';
}

.icon-youtube-play:after {
  content: '\f16a';
}

.icon-file-pdf:before {
  content: '\f1c1';
}

.icon-linkedin:after {
  content: '\f318';
}

.icon-instagram:after {
  content: '\f32d';
}

.icon-home:after {
  content: '\e810';
}

.icon-attention:after {
  content: '\e812';
}

.icon-info:after {
  content: '\e817';
}

.icon-ok-circled:before {
  content: '\f06d';
}

.icon-link:before {
  content: '\e82b';
}

.icon-more:before {
  content: '\e81c';
}

.icon-pencil:before {
  content: '\e819';
}



.log_out_link a:before {
  font-family: "fontello";
  content: '\e80f';
  color: #fff;
  margin-right: 10px;
}
