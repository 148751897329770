/* @file
 * This file is a custom file that loads all files. Each non-base layer
 * can be disabled.
 *
 * Do not name this file, "app.scss". If you run a compass update this file can
 * be wiped out with a compass update. By default, when a compass project is
 * created the file will be named app.scss. Thus this file is named,
 * THEMENAME.scss.
 *
 * This application file (THEMENAME.scss) is where all the partials are
 * imported.
 *
 * Theme styles are categorized using SMACSS standards. They utilize
 * categorization of styles into various categories. Those categories are the
 * following:
 *
 * - Base: CSS reset/normalize plus HTML element styling.
 * - Layout: Macro arrangement of a web page, including any grid systems.
 * - Component: Dictate minor layout modules or reusable elements.
 * - State: Describe the appearance of a module in various states.
 * - Theme: Purely visual optional styling ("look-and-feel") for a component.
 *
 * For more information about this new Drupal css file standard, please review
 * the following:
 * - https://drupal.org/node/1887922
 * - http://smacss.com/
 */

//@import "mixins/all_mixins";

/* Set some variables! */










// How to use respond-to mixin:

// .sidebar{
//   border: 1px solid #ccc;

//   @include respond-to(max-width, 600px){
//     float:right;
//     width:30%;
//   }

// }




// How to use flexbox mixin:

// @include flexbox;
// @include inline-flex;
// @include align-items(center);
// @include justify-content(space-around);
// @include flex-basis(16%);

@font-face {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    src: url('/sites/all/themes/fxl_responsive/fonts/fontello/fontello.eot?79951935');
    src: url("/sites/all/themes/fxl_responsive/fonts/fontello/fontello.eot?79951935#iefix") format("embedded-opentype"),
    url("/sites/all/themes/fxl_responsive/fonts/fontello/fontello.woff2?79951935") format("woff2"),
    url("/sites/all/themes/fxl_responsive/fonts/fontello/fontello.woff?79951935") format("woff"),
    url("/sites/all/themes/fxl_responsive/fonts/fontello/fontello.ttf?79951935") format("truetype"),
    url("/sites/all/themes/fxl_responsive/fonts/fontello/fontello.svg?79951935#fontello") format("svg");
}



#added-to-list-modal .item-added-tumbnail {
    float: left;
}

#added-to-list-modal .preview-quantity {
    border: 1px solid #ccc;
    padding: 5px 10px;

    font-size: 14px;
    margin: 0 10px;
}

#added-to-list-modal input {
    font-size: 14px;
    padding: 3px 10px;
    line-height: 14px;
}

#added-to-list-modal .close-modal a,
#added-to-list-modal .view-list a {
  background: #ee7623 none repeat scroll 0 0;
  clear: right;
  color: #fff;
  float: right;
  font-size: 18px;
  font-weight: 500;
  line-height: 12px;
  margin-top: 1em;
  padding: 18px 50px 16px;
  text-transform: uppercase;
  text-align: center;

  @include respond-to(max-width, 700px){
    line-height: 1.2;
  }

  &:hover{
    background-color: darken(#EE7623, 6%);
  }
}

#added-to-list-modal .view-list {
  clear: both;
  float: left;

  a {
    background: white;
    color: #EE7623;
    border: 3px solid #EE7623;
    padding: 15px 30px 13px;

    &:hover{
      border-color: darken(#EE7623, 6%);
      color: white;
    }
  }
}

@media (max-width: 700px) {
  #added-to-list-modal .close-modal a,
  #added-to-list-modal .view-list a,
  #added-to-list-modal .view-list {
    width: 100%;
  }
}




#added-to-list-modal .close-modal a:hover {
  text-decoration: none;
}

#added-to-list-modal h4 {
  font-size: 30px;
  margin-bottom: 8px;
}

#added-to-list-modal .preview-description {
    font-size: 18px;
}

#added-to-list-modal .preview {
    border: 1px solid #ccc;
    float: right;
    width: 100%;
}







.share-container {
  position: relative;
}

.share-toolbox {
  width: 305px;
  margin-top: 20px;
  display: none;
  position: absolute;
  z-index: 100;
  background: #fff;
  border: 1px solid #ccc;
  left: 50%;
  transform: translateX(-50%);
  -webkit-box-shadow: 0px 10px 18px 0px rgba(0,0,0,0.32);
  -moz-box-shadow: 0px 10px 18px 0px rgba(0,0,0,0.32);
  box-shadow: 0px 10px 18px 0px rgba(0,0,0,0.32);

  a.ajax-progress {
    position: relative;

    &:after {
      content: url('/sites/all/themes/fxl_responsive/images/fx-throbber-active.gif');
      width: 16px;
      height: 16px;
      display: block;
      position: absolute;
      top: 9px;
      right: -25px;
    }
  }
}

.my-list-item-desktop .share-toolbox {
  margin-top: 28px;

}

.view-my-lists .my-list-item-desktop div.share-toolbox {
  display: none;
  position: absolute;
  z-index: 100;
  background: #fff;
  border: 1px solid #ccc;
}

.share-toolbox:after {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #ccc;
  content: '';
  display: none;
  position: absolute;
  top: -15px;
  left: 50%;
  margin-left: -7px;
}

.share-toolbox .share-header {
  background: #ccc none repeat scroll 0 0;
  color: #333;
  font-weight: 500;
  padding: 7px 15px 5px;
  text-transform: uppercase;
  text-align: left;
}

.share-toolbox .share-header .list-share-close:before {
  content: 'X';
  width: 20px;
  height: 20px;
  display: inline-block;

}

.share-toolbox .share-content {
  padding: 10px;
}

.share-toolbox .share-content a {
  text-transform: uppercase;
  display: inline-block;
  background: #EE7623;
  margin: 0 5px;
  padding: 8px 10px 5px;
  color: #fff;
}


.share-toolbox .share-content a:first-of-type{
  margin-left: 0;
}


.share-toolbox .share-content a:last-of-type{
  margin-right: 0;
}


.share-toolbox .share-header .list-share-close {
  margin: 0 10px;
  position: absolute;
  right: 0;
  text-align: center;
}












.view-more-items .show-email,
.view-more-items .show-url,
.share-toolbox .success-message,
.share-toolbox .error-message {
  display: none;
  margin-top: 10px;
}

.my-list-print .print-footnote {
  display: none;
}

.my-list-print .menu-block-8,
.my-list-print .form-item-sort,
.my-list-print .delete-item,
.my-list-print #my-list-list-form .form-item-title label,
.my-list-print #my-list-list-form .form-item-description label,
.my-list-print button,
.my-list-print .form-item-note label,
.my-list-print .form-item-note input.empty,
.my-list-print .list-actions,
.my-list-print .back-to-my-lists-link,
.my-list-print .block-block-4,
.my-list-print .block-block-174 {
  display: none;
}

.my-list-print .node-title a {
  text-decoration: none;
}

.my-list-print .form-item-note input {
  border: 0;
}








.share-toolbox .show-url input,
.share-toolbox .show-email input {
  margin-top: 14px;
}

.share-toolbox .share-content a.list-share-pdf {
  padding-left: 16px;
  padding-right: 16px;
}

.share-toolbox .share-content a.list-share-url {
  padding-left: 14px;
  padding-right: 14px;
}




/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
/* My List ----------------------------------------------------------- */
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */


/* Individual list page -----------------------------  */


div[id^=edit-items] .field-item,
.field-name-field-list-items .field-item {
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
}

.item-details {
  @include flex-grow(2);
  max-width: 1010px;
}

#edit-items .item-thumbnail {
  @include flex-grow(0);
}

.my-list-field-list-view .item-delete {
  @include flex-grow(0);
}

.list-item-more-actions a{
  font-size: 21px;
  font-weight: 500;
  margin: 0 20px;
  text-transform: uppercase;
  color: #666;
}

.list-actions {
  @include flexbox;
  @include align-items(center);
  @include justify-content(flex-end);
}

.list-actions a{
  font-size: 14px;
  font-weight: 500;
  margin: 0 26px;
  text-transform: uppercase;
  height: 34px;
  display: inline-block;
  line-height: 1.4;
}

.item-actions .item-delete {
  font-size: 21px;
  font-weight: 500;
  text-transform: uppercase;
}

.item-actions .item-delete:before{
  font-family: 'fontello';
  content: '\e809';
  margin-right: 8px;
}

.back-to-my-lists-link a:before{
  font-family: 'fontello';
  content: '\e829';
  display: inline-block;
  font-size: 7px;
  margin-right: 8px;
  position: relative;
  top: -3px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.list-actions,
.my-design-project-header {
  .pdf-list:before{
    font-family: 'fontello';
    content: '\e80c';
    margin-right: 8px;
    font-size: 24px;
    position: relative;
    top: 2px;
  }
}

.list-actions {
  .copy-list:before{
    font-family: 'fontello';
    content: '\f0c5';
    margin-right: 8px;
    font-size: 24px;
    position: relative;
    top: 2px;
  }

  .share-list:before{
    font-family: 'fontello';
    content: '\e80a';
    margin-right: 8px;
    font-size: 24px;
    position: relative;
    top: 2px;
  }

  .delete-list:before{
    font-family: 'fontello';
    content: '\e809';
    margin-right: 8px;
    font-size: 24px;
    position: relative;
    top: 2px;
  }
}

.list-actions-container .form-submit.top {
  margin-bottom: 0;
  height: 64px;
}

.list-price {
  min-width: 280px;
  padding: 10px 40px;

  .extended-price {
    margin-left: 40px;
  }

  .price-value {
    color: $color_fx_orange;
    font-weight: 500;
  }
}

.item-actions {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  border-left: 2px solid #eaeaea;
}


.item-note{
  line-height: 1.4;
  padding-left: 10px;
  padding-right: 10px;
}

.list-item-more-actions{
  display: inline-block;
}


.item-qty-and-note-container{
  @include flexbox;
  @include align-items(center);
  max-width: 600px;
  height: auto;
  min-height: 32px;
}



.not-logged-in.node-type-my-list{

  /*.item-qty-and-note-container{
    @include flexbox;
    @include align-items(center);
    @include justify-content(start);
    max-width: 100%;
  }*/


  .my-list-field-list-view .item-quantity{
    margin-top: 0;
  }


}



.form-item.form-type-textfield.form-item-note {
  @include flex-grow(1);
}

.item-actions {
  @include flex-grow(0);
  @include flexbox;
  @include align-items(center);
  margin-left: 12px;
  margin-top: 8px;
}

.my-list-field-list-view .form-item-quantity {
  @include flexbox;
  @include align-items(center);
  float: left;
}

.form-item-note label {
  display: none;
}

.item-note.form-text {
  margin-bottom: 0;

}

.my-list-field-list-view .item-product-number{
  margin-top: 0;
  margin-bottom: 10px;
}

.my-list-field-list-view .item-date-added {
  margin-left: 0;
  font-size: 12px;
}


.my-list-field-list-view .form-item-quantity label {
  float: left;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.item-qty-and-note-container .form-item-note {
  margin-bottom: 0;
  margin-top: 0;
}

#list-items .form-item input {
  box-shadow: none;
}

.list-item-more-actions a:before {
  font-family: 'fontello';
  content: '\e81c';
  margin-right: 8px;
}

.list-item-more-actions a.download-item:before {
  font-family: 'fontello';
  content: '\e806';
  margin-right: 8px;
}

.list-item-more-actions a.edit-item:before {
  font-family: 'fontello';
  content: '\e819';
  margin-right: 8px;
}

.my-list-field-form .form-item-title input {
  font-size: 34px;
  font-weight: 500;
}

.my-list-field-form .form-item-description textarea {
  resize: none !important;
  height: 94px;
}

.my-list-field-list-view .form-item-title input {
  height: 50px;
}

.list-created {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #999;
}






.my-list-field-list-view .form-item-description {
  margin-bottom: 4px;
}

.my-list-field-list-view .form-item, .my-list-field-list-view .form-actions {
  margin: 0;
}




.list-actions-container {
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  max-width: 100%;
  width: 100%;
  float: right;
  margin-bottom: 22px;

  @include respond-to(max-width, 768px){
    display: block;
    max-width: 100%;
  }
}

#my-list-list-form .list-actions-container {
  max-width: 100%;
}







#delete-confirm-modal .buttons {
  float: right;
  margin-top: 12px;
}


















.form-submit.bottom {
  clear: both;
  display: inline-block;
  float: right;
  margin-bottom: 3em;
  margin-top: 2em;
  display: none;
}

.form-submit.bottom.show{
  display: inline-block;
}

.form-item-sort .form-select {
  margin-bottom: 0;
}

#my-list-list-form .my-list-items {
  float: left;
  width: 100%;
}





.form-item-sort .form-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: white none repeat scroll 0 0;
  border: 1px solid #e4e1dd;
  border-radius: 0;
  box-sizing: border-box;
  color: #333;
  display: block;
  outline: 0 none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 32px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  height: 64px;
  letter-spacing: -0.01em;
  line-height: 1.5;
  position: relative;

  option {
    padding: 10px;
    -moz-user-select: none;
    display: block;
    float: none !important;
    line-height: normal !important;
    min-block-size: 1em;
    overflow-wrap: normal !important;
    position: static !important;
    text-align: match-parent;
    text-indent: 0;
    white-space: nowrap !important;
  }


}








/*.form-item-sort .form-select:after {
  font-family: 'fontello';
    content: '\e829';
    font-size: 8px;
    right: 16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;

}*/



#edit-list-select-container{

  select option {
    padding: 10px;
  }

  option {
    -moz-user-select: none;
    display: block;
    float: none !important;
    line-height: normal !important;
    min-block-size: 1em;
    overflow-wrap: normal !important;
    position: static !important;
    text-align: match-parent;
    text-indent: 0;
    white-space: nowrap !important;
  }


}



.sort_container .icon-down_arrow_accordion_2_optimized {
  font-size: 9px;
  pointer-events: none;
  position: absolute;
  right: 15px;
  top: 42%;
}


.sort_container {
  position: relative;
}


.total_items {
  font-size: 14px;
  font-weight: 400;
  margin-top: 26px;
  display: inline-block;
  min-width: 200px;

  span{
    font-weight: 500;
  }

}

.total_and_pager_container {
  display: inline-block;
  margin-right: 40px;
}



.my-list-item-actions a {
  display: inline-block;
  margin: 0 10px;
}

.my-list-item-actions a:before {
  content: '';
  width: 10px;
  height: 10px;
  background: red;
  display: inline-block;
  margin: 0 5px 0 0;
}
.my-list-field-list-view .field-item {
  float: left;
  clear: both;
  width: 90%;
  border: 1px solid #ccc;
  margin: 1.2em 1em;
  padding: 1em;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.my-list-field-list-view .item-thumbnail {
  margin-right: 2em;
}

/*.my-list-field-list-view .item-thumbnail,
.my-list-field-list-view .item-details {
  float: left;
}

.my-list-field-list-view .item-product-number {
  font-weight: bold;
  margin-top: 1em;
  font-size: 16px;
}*/

.my-list-field-list-view .item-quantity,
.my-list-field-list-view .item-date-added {
  margin-top: 0;
  float: left;
}

.my-list-field-list-view .item-quantity {
  text-transform: uppercase;
}

.my-list-field-list-view .item-quantity .quantity-value {
  font-weight: bold;
  border: 1px solid #ccc;
  padding: 5px 10px;
  margin: 0 1em;
}

.my-list-field-list-view .item-date-added {
  /*margin-left: 2em;*/
  margin-left: 0;
  margin-top: 1em;
}

.my-list-field-list-view .form-item-quantity {
  float: left;
}

.my-list-field-list-view .form-item-quantity label {
  font-weight: normal;
  float: left;
  font-size: 14px;
}

.my-list-field-list-view .form-item-quantity input {
  font-weight: bold;
  padding: 5px 10px;
  margin: 0 1em;
  float: left;
  font-size: 14px;
}

.my-list-field-list-view .form-item-quantity input,
.my-list-field-list-view .form-item-note input {
  border: 2px solid #E4E1DD;
}

.my-list-field-list-view .item-delete {
  float: right;
}

.node-type-my-list.my-list-has-edit-access .body,
.node-type-my-list.my-list-has-edit-access h1#page-title {
  display: none;
}

.my-list-field-form .form-item-title input {
  font-size: 26px;
}

.my-list-field-form .form-item-description textarea {
  font-size: 16px;
  resize: none !important;
}

.my-list-field-form .form-item-description textarea:focus,
.my-list-field-form .form-item-description textarea:hover {
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.my-list-field-list-view .field-item {
  border: 1px solid #ccc;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  clear: both;
  float: left;
  margin: 1.2em 0;
  padding: 1em;
  width: 100%;
}

/*.my-list-field-list-view .item-thumbnail {
  margin-right: 1em;
}*/

.my-list-field-list-view .item-thumbnail {
  border: 1px solid #ccc;
  display: inline-block;
  float: left;
  /*margin-bottom: 12px;*/
  margin-right: 1em;
}

/*.my-list-field-list-view .item-thumbnail,
.my-list-field-list-view .item-details {
  float: left;
}*/



.my-list-field-list-view .item-product-number {
  font-size: 20px;
  /*margin-top: 1em;*/
  font-weight: 800;
}


.my-list-field-list-view .item-product-number small {
  font-weight: normal;
  text-transform: uppercase;
}


.my-list-field-list-view .item-quantity,
.my-list-field-list-view .item-date-added {
  float: left;
}

.back-to-my-lists-link {
  margin-top: 1em;
  text-align: right;
}

.my-list-field-list-view .item-quantity {
  text-transform: uppercase;
  min-width: 100px;
  font-weight: 500;
}

.my-list-field-list-view .item-quantity .quantity-value {
  font-weight: bold;
  border: 1px solid #ccc;
  padding: 5px 10px;
  margin: 0 1em 0 0.6em;
}

.my-list-field-list-view .item-date-added {
  /*margin-left: 2em;*/
}


.my-list-field-list-view .item-delete {
  float: none;
}

.my-list-field-form .form-item-title input {
  font-size: 30px;
}

.my-list-field-form .form-item-description textarea {
  font-size: 16px;
  resize: none !important;
  line-height: 1.4;
}

.my-list-field-form .form-item-description textarea:focus,
.my-list-field-form .form-item-description textarea:hover {
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

/* Modal Madness! ----------------- */


.create-list-modal,
#add-new-list-modal,
#add-new-list-modal-accessory,
#added-to-list-modal,
#success-modal,
#delete-confirm-modal,
.copy-list-modal-form,
.edit-list-modal {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 900px;
  transform: translate(-50%, -50%); /* Transform should be equally divisible by 2 to avoid blur */
  background: white;
  border: 1px solid #ccc;
  z-index: 10001;
  padding: 80px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);


  h2{
    font-weight: 400;
    margin-top: 0;
    font-size: 2.4em;

  }

  p{
    font-size: 20px;
  }

}


#login-modal{
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 400px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); /* Transform should be equally divisible by 2 to avoid blur */
  background: white;
  border: none;
  z-index: 10001;
  padding: 50px;

  @include respond-to(max-width, 768px){
    position: relative;
    top: 0;
    -webkit-transform: translate(-50%, none);
    transform: translate(-50%, none);
    left: 50%;
  }



  h2{
    font-size: 24px;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  p {
    margin-bottom: 30px;
  }


  .form-submit {
    margin-top: 10px;
    margin-bottom: 0;
    width: 100%;
  }

  .button.secondary{
    width: 100%;
  }


}


#login-modal-accessory{
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 400px;
  transform: translate(-50%, -50%); /* Transform should be equally divisible by 2 to avoid blur */
  background: white;
  border: none;
  z-index: 10001;
  padding: 50px;

  h2{
    font-size: 24px;
    margin-bottom: 30px;
    margin-top: 10px;
  }


  .form-submit {
    margin-top: 10px;
    margin-bottom: 0;
    width: 100%;
  }

  .button.secondary{
    width: 100%;
  }


}



.close_modal {
  height: 54px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 58px;
}

/*.or {
  display: inline-block;
  margin-bottom: 16px !important;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  text-align: center;
  width: 100%;
}*/

.item-added-description .product_number {
  font-weight: 800;
}

#added-to-list-modal .quantity {
  display: inline-block;
  font-size: 16px;
  margin-top: 4px;
}

.item-added-description {
  display: inline-block;
  padding-top: 15px;
  width: auto;
}






#my-list-welcome-back-login-form .or,
.order_number_container .or,
#the-login-form .or,
#the-login-form-accessory .or {
  display: inline-block;
  line-height: 0;
  margin-bottom: 20px;
  padding-top: 1px;
  position: relative;
  text-align: center;
  top: 2px;
  width: 100%;
  text-transform: uppercase;
}

#my-list-welcome-back-login-form .or:after,
.order_number_container .or:after,
#the-login-form .or:after,
#the-login-form-accessory .or:after{
  background-color: transparent;
  border-top: 1px solid #e7e7e7;
  content: "";
  display: block;
  height: 1px;
  margin-top: -1px;
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 1;
}

#my-list-welcome-back-login-form .or span,
.order_number_container .or span,
#the-login-form .or span,
#the-login-form-accessory .or span{
  background-color: #fff;
  color: #767676;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  padding: 0 8px 0 7px;
  position: relative;
  z-index: 2;
}










.close_modal:after {
  content: '\e801';
  display: inline-block;
  font-family: "fontello";
  font-size: 34px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%) rotate(-45deg);
  transform: translateY(-50%) translateX(-50%) rotate(-45deg);
}

#create-account {
  border: 2px solid #ccc;
  margin-bottom: 0;
}


.copy-list-modal .form-item,
.edit-list-modal .form-item {
  margin-bottom: 2em;
}


input[type="text"] {
  border: 1px solid #ccc;
  font-size: 14px;
}



#modal_overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.8);
  border: none;
}


#modal_overlay_accessory {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.8);
  border: none;
}

/*#delete-confirm-modal {
  max-width: 900px;
  padding: 90px;
  width: 100%;
}*/

/*#delete-confirm-modal h2 {
  font-weight: 400;
  margin-top: 0;
}*/

/*#delete-confirm-modal p {
  font-size: 20px;
}*/





.buttons{

  float: right;

  .button{
    margin-left: 6px;
    margin-bottom: 0;

    &:hover{
      background-color: darken($color_fx_orange, 6%);
    }
    &:focus{
      background-color: darken($color_fx_orange, 6%);
    }

  }

  .button.secondary{
    margin-left: 0;

    &:hover{
      background-color: darken(#e9e9e9, 6%);
    }
    &:focus{
      background-color: darken(#e9e9e9, 6%);
    }

  }

}

#modal_overlay.show,
#modal_overlay_accessory.show,
#login-modal.show,
#login-modal-accessory.show,
#the-login-form.show #login-modal,
#the-login-form-accessory.show #login-modal-accessory,
#add-new-list-modal.show,
#add-new-list-modal-accessory.show,
#add-new-list-modal.success {
  display: block;
}


#added-to-list-modal {
  display: block;
  max-width: 700px;
  width: 100%;
  padding: 0;

  .top_container {
    padding: 60px 80px;
    display: inline-block;
    width: 100%;

    @include respond-to(max-width, 768px){
      padding: 40px;
    }

  }

  .suggested_accessories_section{
    @include flex-grow(1);
  }

}


/*.submit_item {
  margin-bottom: 0;
  margin-top: 10px;
  padding: 9px 14px 7px;
  display: none;
  margin-left: 6px;
}


.item-details.editing + .submit_item{
  display: block;
}

.not_saved {
  border: 2px solid red !important;
}*/



.pagination-centered {
  display: inline-block;
  float: none;
  margin: 38px 0;
  max-width: 750px;
  text-align: left;
  width: calc(100% - 200px); /* 100% width minus width of bottom save button */
}


.bottom_pager {
  display: none;
}


#list-items .pagination-centered:nth-child(1){
  background-color: red !important;
  border: 2px solid orange;
}



.pagination-centered .item-list {
  display: inline-block;
}

/*.ajax-progress {
  display: inline-block;
  left: 50%;
  position: fixed;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: 3;
  font-size: 0;
}


.ajax-progress .throbber:after {
  background: rgba(255, 255, 255, 0.8) none repeat scroll 0 0;
  content: "";
  height: 102vh;
  left: -50vw;
  opacity: 1;
  position: absolute;
  top: -50vh;
  width: 102vw;
  z-index: -1;
}


.ajax-progress .throbber {
  background: transparent url("/sites/all/themes/fxl_responsive/images/fx-throbber-active.gif") no-repeat scroll 0 center;
  float: left;
  height: 16px;
  margin: 0 10px 2px 2px;
  width: 16px;
  z-index: 99999;
}
*/



.ajax-progress .throbber {
  background: transparent url("/sites/all/themes/fxl_responsive/images/fx-throbber-active.gif") no-repeat scroll 0 center;
  /*float: left;
  height: 16px;
  margin: 0 10px 2px 2px;
  width: 16px;
  z-index: 99999;*/
}

.ajax-progress .message{
  display: none;
}




#my-list-add-to-list-form #add-new-list-modal .form-submit {
  float: right;
  margin-bottom: 0;
  margin-top: 12px;
}


.download_condensed{
  /*display: none;*/
}


.edited {
  border: 2px solid #EE7623 !important;
}




/* Anonymous user list page styles ------------------- */


.not-logged-in.node-type-my-list .list-actions-container {
  margin-bottom: 0;
  margin-top: 4px;
}

.not-logged-in.node-type-my-list .form-item {
  margin-top: 0;
}

.not-logged-in.node-type-my-list .total_items {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 8px;
}


.not-logged-in .list-created {
  margin-top: 10px;
  margin-bottom: 50px;
}

.not-logged-in.node-type-my-list .body.field {
  line-height: 1.5;
}


.not-logged-in.node-type-my-list .pagination-centered {
  display: inline-block;
  float: none;
  margin: 8px auto;
  max-width: 750px;
  text-align: left;
  width: calc(100% - 370px);
}


.not-logged-in.node-type-my-list .my-list-field-list-view .field-item{
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
}


.not-logged-in.node-type-my-list .item-actions-condensed {
  @include flex-shrink(0);
}


.not-logged-in.node-type-my-list .field-name-field-list-items {
  display: inline-block;
  margin-bottom: 3em;
  width: 100%;
}

.node-type-my-list h1#page-title {
  display: none;
}

@media (min-width: 768px) {
  .not-logged-in.node-type-my-list{

    .list-item-more-actions {
      .download_condensed {
        display: none;
      }
      .item_actions_inner_container {
        display: block;
        &:after {
          display: none;
        }
      }
    }
  }
}













/*  My Lists page ---------------------------------- */


.view-my-lists {
  margin-bottom: 5em;
}

.my-list-item-title-and-created p {
  margin-bottom: 0;
}

.my-list-item-description {
  line-height: 1.5;
}

.view-my-lists .my-list-item-desktop{
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  width: 100%;

  .my-list-item-count {
    background: gray none repeat scroll 0 0;
    color: white;
    font-size: 1.5em;
    font-weight: bold;
    height: 100%;
    max-width: 90px;
    padding: 20px;
    text-align: center;
    width: 100%;
  }

  .my-list-item-title-and-created {
    @include flex-grow(2);
    @include flex-shrink(0);
    margin-right: auto;
    padding-left: 20px;

    .my-list-title {
      font-size: 22px;
      font-weight: 500;
      max-width: 300px;
    }
  }

  .my-list-item-description {
    @include flex-grow(1);
    @include flex-shrink(2);
    padding: 0 40px;
    overflow: hidden;
    text-overflow:ellipsis;

    @include respond-to(max-width, 1030px){
      padding: 0 30px;
      display: none;
    }
  }

  .list-actions {
    @include flex-shrink(0);
    position: relative;
    @include respond-to(max-width, 950px){
      @include flex-shrink(2);
    }
  }

  @include respond-to(max-width, 768px){
    display:none;
  }
}

#edit-items .item-thumbnail {
  border: 1px solid #ccc;
  display: inline-block;
}

.item-added-thumbnail {
  display: inline-block;
  float: left;
  margin-right: 10px;
  width: auto;
  border-right: 1px solid #ccc;
}

.view-my-lists .views-row {
  width: 100%;
  border: 1px solid #ccc;
  padding: 0;
  display: table;
  margin: 20px 0;
}

.view-my-lists .views-field-title {
  font-size: 24px;
}

.my-list-item-desktop .my-list-item-count .items-label {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 0.6em;
  display: block;
}

.my-list-item-mobile {
  display: none;
}

.item-added-description {
  padding-top: 15px;
}

.item-added-description p {
  font-size: 20px;
  margin-bottom: 4px;
}

.item-added-description p:nth-child(2) {
  font-size: 17px;
  font-weight: 500;
}

.download_condensed{
  display: block;
}

.item_actions_inner_container{
  display: none;
}

.download_condensed.open + .item_actions_inner_container {
  background-color: #eaeaea;
  top: 46px;
  display: block;
  padding: 8px;
  position: absolute;
  width: 160px;
  left: 42%;
  z-index: 2;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-box-shadow: 0px 10px 18px 0px rgba(0,0,0,0.32);
  -moz-box-shadow: 0px 10px 18px 0px rgba(0,0,0,0.32);
  box-shadow: 0px 10px 18px 0px rgba(0,0,0,0.32);

  a{
    display: block;
    float: none;
  }
}

.list-item-more-actions{
  position: relative;
}

.list-item-more-actions a {
  color: #666;
  font-size: 21px;
  font-weight: 500;
  margin: 10px;

  &:hover{
    color: $color_fx_orange;
  }
}

.list-item-more-actions .item_actions_inner_container a {
  font-size: 14px;
  padding: 6px 4px;
}

.item_actions_inner_container:after {
  border-bottom: 15px solid #eaeaea;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  content: "";
  display: block;
  height: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  position: absolute;
  top: -15px;
  width: 0;
}

.item-actions a span,
.item-actions-condensed a span {
  display: none;
}

.edit-list-modal button,
.edit-list-modal .button {
  float: right;
  margin-left: 6px;
}


@media only screen and (max-width: 768px) {
  .not-logged-in.node-type-my-list .item-actions-condensed {
    display: inline-block;
    float: right;
    @include align-self(flex-end);
  }

  .my-list-field-list-view .item-thumbnail{
    @include flex-shrink(0);
    @include align-self(flex-start);
  }

  .item-qty-and-note-container {
    @include flexbox;
    @include align-items(center);
    @include justify-content(flex-start);
    @include flex-wrap(wrap);

    height: auto;
    max-width: 600px;
    min-height: 32px;
  }


  .my-list-field-list-view .item-quantity{
    @include order(2);
    font-size: 14px;
  }

  .item-note {
    @include order(1);
    margin-bottom: 12px;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  .list-actions-container .form-submit.top {
    margin-bottom: 0;
    height: auto;
  }

  .buttons {
    float: none;
    width: 100%;

    .button {
      margin-left: 4%;
      margin-bottom: 0;
      padding: 18px 10px;
      width: 48%;
    }

    .button.secondary {
      margin-left: 0;
    }
  }

  #added-to-list-modal {
    overflow-y: scroll;
    padding: 0 !important;
  }

  .create-list-modal,
  #add-new-list-modal,
  #added-to-list-modal,
  #success-modal,
  #delete-confirm-modal,
  .copy-list-modal-form,
  .edit-list-modal {
    max-width: 900px;
    padding: 10%;
    width: 100%;

    h2 {
      font-weight: 400;
      margin-top: 0;
      font-size: 2em;

    }

    p {
      font-size: 18px;
    }
  }


  #login-modal{
    max-width: 900px;
    padding: 10%;
    width: 100%;

    h2 {
      font-weight: 500;
      margin-top: 0;
      font-size: 2em;
    }

    p {
      font-size: 18px;
    }
  }

  #login-modal-accessory{
    max-width: 900px;
    padding: 10%;
    width: 100%;

    h2 {
      font-weight: 500;
      margin-top: 0;
      font-size: 2em;
    }

    p {
      font-size: 18px;
    }
  }

  .not-logged-in.node-type-my-list h1#page-title {
    font-size: 2.5em;
    margin-top: 0.6em;
    margin-bottom: 10px;
  }

  .not-logged-in.node-type-my-list .list-actions-container {
    margin-bottom: 30px;
    margin-top: 10px;
    max-width: 100%;
  }

  .not-logged-in .list-created {
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .not-logged-in.node-type-my-list .total_items {
    display: table;
    margin: 0 auto 8px;
    width: auto;
  }

  .not-logged-in.node-type-my-list .pagination-centered {
    display: table;
    float: none;
    margin: 8px auto;
    max-width: 100%;
    text-align: center;
    width: 100%;
  }

  .not-logged-in.node-type-my-list .list-actions-container {
    margin-bottom: 30px;
    margin-top: 10px;
    max-width: 100%;
  }

  .not-logged-in.node-type-my-list .list-actions{
    margin-bottom: 14px;
  }

  .not-logged-in.node-type-my-list .share-toolbox{
    left: -154px;
    -webkit-transform: none;
    transform: none;

    &:after{
      right: 94px;
      left: auto;
    }
  }

  .item-note.form-text {
    margin-bottom: 0;
    display: inline-block;
    margin-top: 10px
  }

  .pagination-centered {
    clear: both;
    display: inline-block;
    float: none;
    margin: 30px auto;
    text-align: center;
    width: 100%;
  }

  .form-item-sort .form-select {
    height: 48px;
  }

  div[id^="edit-items"] .field-item{
    display: block;
  }

  .item-actions{
    display: block;
    float: right;
  }

  .list-item-more-actions a.download_condensed {
    height: 45px;
    width: 45px;
    text-align: center;
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 0;
    position: relative;
    top: 10px;
  }

  .list-item-more-actions a.download_condensed:before {
    margin-right: 0;
    position: relative;
    top: 8px;
  }

  .item-qty-and-note-container{
    display: block;
  }

  #list-items .form-item-quantity {
    margin: 0 0 10px;
  }

  .my-list-field-list-view .form-item-quantity input,
  .my-list-field-list-view .item-note {
    border-color: #eaeaea;
  }

  #added-to-list-modal {
    @include flexbox;
    @include flex-direction(column);
    height: 100%;
    max-width: 100%;
    width: 100%;
  }

  .pagination-centered {
    display: inline-block;
  }

  .list-actions-container{
    text-align: right;
  }

  .list-actions-container .form-submit.top {
    margin-bottom: 0;
    margin-top: 1em;
    width: 100%;
  }

  #list-items .list-actions {
    margin-bottom: 1em;
  }

  .form-submit.bottom {
    clear: both;
    display: inline-block;
    margin: 1em 0 2em;
    width: 100%;
  }

  .form-submit.bottom {
    margin-bottom: 1em;
  }

  .list-created {
    margin-bottom: 10px;
    margin-top: 15px;
    position: relative;
  }

  .my-list-field-list-view .form-item.form-item-description {
    margin-bottom: 1em;
  }

  .my-list-field-form .form-item-description textarea {
    height: 58px;
    margin-bottom: 10px;
  }

  .total_and_pager_container {
    display: table;
    margin: 0 auto;
    text-align: center;
  }

  .share-toolbox {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ccc;
    box-shadow: 0 10px 18px 0 rgba(0, 0, 0, 0.32);
    left: 50%;
    margin-top: 14px;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 300px;
    z-index: 100;
  }

  .share-toolbox .share-content a {
    background: #ee7623 none repeat scroll 0 0;
    color: #fff;
    display: block;
    margin: 5px;
    padding: 8px 10px 5px;
    text-align: center;
    text-transform: uppercase;
    margin: 10px !important;
  }

  .my-list-item-desktop {
    display: none;
  }

  .my-list-item-mobile {
    display: block;
    padding: 20px;

    p {
      margin-bottom: 0;
    }

    .my-list-item-title-and-body {
      margin-bottom: 40px;
    }

    .my-list-title {
      font-size: 22px;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 10px;
    }

    .my-list-item-created-and-count {
      display: inline-block;
      width: auto;
      font-size: 13px;

      p {
        font-weight: 500;
        line-height: 1.4;
        font-size: 13px;
      }
    }

    .list-actions {
      float: right;
      width: auto;

      .delete-list{
        margin-right: 0;
      }
    }

    .list-actions a{
      margin: 0 20px;
    }
  }

  .item-actions a span {
    display: none;
  }

  .item-actions .item-delete,
  .list-item-more-actions a{
    font-size: 20px;
  }

  .list-item-more-actions .item_actions_inner_container a {
    font-size: 16px;
    padding: 8px 6px;
  }

  .my-list-field-list-view .field-item {
    padding: 1em;
  }

  .my-list-field-list-view .item-date-added {
    margin-top: 3em;
  }

  .item-qty-and-note-container{
    max-width: 100%;
  }

  .download_condensed.open + .item_actions_inner_container {
    background-color: #eaeaea;
    box-shadow: 0 10px 18px 0 rgba(0, 0, 0, 0.32);
    display: block;
    left: -68px;
    padding: 8px;
    position: absolute;
    top: 58px;
    -webkit-transform: none;
    transform: none;
    width: 200px;
  }

  .not-logged-in.node-type-my-list .download_condensed.open + .item_actions_inner_container {
    left: -15px;
    right: auto;
  }

  .not-logged-in.node-type-my-list .item_actions_inner_container:after{
    left: 77%;
    -webkit-transform: translateX(-77%);
    transform: translateX(-77%);
  }

} /* End of 768 */


.list-actions a span {
  white-space: nowrap;
}

@media screen and (max-width: 1000px){
  .list-actions a span {
    font-size: 12px;
  }
}


@media screen and (max-width: 970px){

  .my-list-item-mobile {
    padding: 16px;
  }

  .list-actions a {
    margin: 0 16px;
  }

  .list-actions a span {
    display: none;
  }

  .list-actions a:before {
    left: 4px;
  }

  .my-list-item-mobile .list-actions {
    width: auto;
  }

  .share-toolbox::after {
    left: auto;
    right: 91px;
  }

  .not-logged-in.node-type-my-list .share-toolbox{
    left: -220px;
    -webkit-transform: none;
    transform: none;

    &:after{
      right: 94px;
      left: auto;
    }
  }

  .not-logged-in.node-type-my-list .share-toolbox::after {
    left: auto;
    right: 34px;
  }
}

@media screen and (max-width: 630px){
  .not-logged-in.node-type-my-list h1#page-title {
    font-size: 2em;
  }
}

@media screen and (max-width: 480px){
  .not-logged-in.node-type-my-list h1#page-title {
    font-size: 1.6em;
  }

  .item-note {
    font-size: 15px;
  }

  .not-logged-in.node-type-my-list .my-list-field-list-view .field-item {
    position: relative;
  }

  .not-logged-in.node-type-my-list .item-actions-condensed {
    position: absolute;
    right: 4px;
    bottom: 2px;
  }

  .create-list-modal,
  #add-new-list-modal,
  #added-to-list-modal,
  #success-modal,
  #delete-confirm-modal,
  .copy-list-modal-form {
    max-width: 900px;
    padding: 10%;
    width: 100%;

    h2 {
      font-weight: 400;
      margin-top: 0;
      font-size: 1.6em;

    }

    p{
      font-size: 16px;
    }

  }



    #login-modal{
    max-width: 900px;
    padding: 10%;
    width: 100%;


    h2{
      font-weight: 500;
      margin-top: 0;
      font-size: 1.6em;

    }

    p{
      font-size: 16px;
    }
  }


  #login-modal-accessory{
    max-width: 900px;
    padding: 10%;
    width: 100%;


    h2{
      font-weight: 500;
      margin-top: 0;
      font-size: 1.6em;

    }

    p{
      font-size: 16px;
    }
  }




  .my-list-field-list-view .item-product-number {
    font-size: 18px;
    /*font-weight: 500;*/
    margin-bottom: 6px;
  }

  .my-list-item-mobile {
    padding: 14px;
  }

  .my-list-item-mobile .list-actions {
    width: auto;
  }

  .my-list-item-mobile .my-list-item-created-and-count p {
    font-size: 12px;
  }

  .my-list-item-mobile .my-list-title{
    font-size: 20px;
  }

  .my-list-item-description {
    font-size: 14px;
  }

  .my-list-item-mobile .list-actions a {
    margin: 0 12px;
  }


}









@media screen and (max-width: 400px){

  .my-list-field-list-view .item-thumbnail {
    max-width: 74px;
  }

  .item-note {
    font-size: 14px;
  }

  .my-list-item-mobile .list-actions {
    padding: 10px;
    width: 100%;
  }

  .my-list-item-mobile .list-actions a {
    margin: 0 6%;
  }


}



.my-list-empty-text {
  clear: both;
}

.finish_options_container {
  display: none;
}

.finish_options_container.active {
  display: block;
}

#my-list-welcome-back-login-form .my-list-thumb {
  float: left;
}

#my-list-welcome-back-login-form p {
  margin-bottom: 0.5em !important;
  margin-top: 0.5em !important;
}

#my-list-welcome-back-login-form .content {
  float: left;
  width: 100%;
}

#my-list-welcome-back-login-form .content p.model {
  margin-top: 1.2em !important;
  font-weight: bold;
}

.my-list-field-list-view .field-item {
  background: rgba(255,255,255,0.8);
  transition: opacity 0.3s;
}

.my-list-field-list-view .ui-state-highlight.field-item {
  min-height: 100px;
  border: 3px dotted #dee7ec;
  background: #f0f3f5;
  color: #363636;
}

.my-list-field-list-view .handle {
  display: none;
  cursor: grab;
  padding: 1em 10px;
}

@media (max-width: 768px) {
  .my-list-field-list-view .handle {
    position: relative;
    bottom: -1em;
  }
}

.my-list-field-list-view .ui-sortable .handle {
  display: inline-block;
  min-width: 45px;
}

.ui-sortable-helper {
  box-shadow: 0px 3px 4px rgba(116,116,116,0.3);
  max-width: 1160px;
}

.list-actions .download-list:before {
  font-family: 'fontello';
  content: '\e806';
  margin-right: 8px;
  font-size: 24px;
  position: relative;
  top: 2px;
}

.project-name {
  position: relative;
}

#edit-name-description {
  position: absolute;
  top: 10px;
  right: 10px;
}

.product-summary ul {
  margin-top: 1em;
  margin-bottom: 0;
  list-style: none;

  li {
    display: inline-block;
    border-right: 2px solid #eee;
    padding: 10px;
    font-size: 0.9em;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      border-right: none;
    }
  }
}

h1.list-title {
  font-size: 1.8em;
  margin: 0 30px 0 0;
  font-weight: 500;
  text-transform: none;
}

.power-consumption {
  div {
    text-align: right;
    p {
      font-size: 18px;
      border: 1px solid #eee;
      padding: 10px 15px;
      line-height: 1em;
      border-radius: 18px;
      margin: 0;
      display: inline-block;
    }
  }
}

.products-power {
  margin-top: 10px;
}

.project-summary-wrapper {
  float: left;
  margin-bottom: 2em !important;

  .note {
    float: left;
    font-size: 0.8em;
    margin-top: 20px;
  }
}

.mylist-footer-note {
  font-size: 0.8em !important;
}

.project-summary {
  border: 1px solid #ddd;
  display: table;
  table-layout: fixed;
  width: 100%;

  * {
    color: #3a3a3a;
  }

  .button {
    color: #fff;
  }

  .additional-summary {
    display: none;
  }

  .description.open {
    #show_full_summary {
      display: none;
    }

    .additional-summary {
      display: inline;
    }
  }

  #show_full_summary {
    color: $color_fx_orange;
  }

  strong {
    font-weight: 500;
  }

  p {
    margin-bottom: 0;
    font-size: 0.9em;
  }

  .products-summary {
    width: 70%;
    padding: 15px;
    display: table-cell;
  }

  .name-description {
    background: #eaeaea;
    width: 30%;
    padding: 15px;
    display: table-cell;
    position: relative;

    .description {
      line-height: 1.2em;
    }

    .edit-list-details {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }


  .products {
    padding-left: 25px;
  }
}


.total-products {
  p {
    font-size: 1.4em;
  }
}

.created-string {
  color: #888;
  font-size: 13px;
  margin: 0;
}

.icon-attention {
  color: #cc0000;
}

.project-summary-wrapper {
  width: 100%;
}

.price-summary {
  border: 1px solid #ddd;
  border-top: none;
  padding: 18px 25px 15px;
  float: right;
  max-width: 430px;

  .total-price {
    font-weight: 500;
    color: $color_fx_orange;
    font-size: 1.5em;
  }

  span.icon-info-circled-alt {
    color: $color_fx_orange;
  }

  a {
    color: #3a3a3a;
    margin: 0 10px;
    font-size: 0.8em;
    position: relative;
    top: -5px;
    outline: none;
  }

  .pro-feature {
    background: $color_fx_blue;
    color: #fff;
    text-transform: uppercase;
    padding: 5px 20px 3px;
    display: inline-block;
    font-size: 0.8em;
    line-height: 2em;
    border-radius: 3px;
    position: relative;
    top: -5px;
  }
}

#my-list-list-form,
.my-list-node-view {
  .hide-price,
  .total-price,
  .price,
  .list-price {
    display: none;
  }

  .icon-info {
    margin: 0 5px;
  }

  .toggle-price {
    .ajax-progress {
      position: absolute;
      bottom: -2px;
      right: 2px;
    }
  }

  &.show-price {
    .hide-price,
    .total-price,
    .price,
    .list-price {
      display: inline-block !important;
    }

    .show-price {
      display: none;
    }
  }
}

.mobile-only {
  display: none;
}

.unit-price {
  margin-right: 20px;
}

.unit-price label,
.extended-price label {
  cursor: default;
}

.price-toggle p {
  margin: 0;
  font-size: 13px;
}

.price-toggle {
  border: 1px solid #ccc;
  width: 300px;
}

.price-toggle-header {
  background: #003c58;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px;
}

.price-toggle-inner {
  padding: 20px;
}

.price-toggle label {
  font-weight: normal;
  display: inline;
  font-size: 1em;
  margin-left: 5px;
  position: relative;
  top: 2px;
}

.price-summary .ajax-progress .throbber {
  background-color: #fff !important;
}

/* Desktop Styles to disable UI accordion */
@media (min-width: 768px) {
  .extended-summary {
    .extended-summary-label {
      display: none !important;
    }
    .ui-accordion-content {
      display: inline !important;
    }
    &.ui-widget {
      font-size: 1em;
    }
  }
}

.extended-summary {
  .ui-accordion {
    padding: 0;
    background: none;
  }
  .ui-accordion .ui-accordion-header .ui-accordion-header-icon,
  .ui-icon {
    display: none;
  }
  .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
    background: none;
    border: none;
  }
  .ui-widget-content {
    background: transparent;
    border: none;
  }
  .ui-accordion-content {
    padding: 0px !important;
  }
}

@media (max-width: 768px) {
  .ui-tooltip {
    display: none !important;
  }

  .my-list-field-list-view .ui-sortable .handle {
    margin-left: 26px;
  }

  .field-name-field-list-items {
    .field-item {
      display: block !important;
    }
    .list-price {
      float: left;
      clear: both;
      margin: 1em 0 0 0;
      padding: 0;
      border: none;
      min-width: 0;

      .unit-price {
        /*margin-right: 0;*/
        /*display: block !important;*/
      }
      .price.extended-price {
        /*margin-top: 1em;*/
        /*display: block !important;*/
      }
    }
    .item-actions-condensed {
      float: right;
    }
  }

  #my-list-list-form .toggle-price .ajax-progress {
    right: -20px;
  }

  #my-list-list-form.show-price {

    .list-price,
    .price.unit-price,
    .price.extended-price {
      border: none;
      min-width: 0;
      margin: 0;
      padding-right: 0;
      text-align: left;
      display: block;
      .unit-price {
        margin-right: 0;
      }
    }

    .item-actions .item-delete,
    .ui-sortable .handle, {
      margin-left: 10px;
    }

    .price {
      margin-top: 2em !important;

      &.extended-price {
        margin-left: 1em;
      }
    }
  }

  .mobile-only {
    display: inherit;
  }

  .desktop-only {
    display: none;
  }

  .power-consumption div {
    text-align: center;
  }

  .total-products .total-product-label {
    font-size: 0.8em;
    float: left;
    position: relative;
    top: 15px;
  }

  .project-summary {
    display: block;
    width: 100%;

    .name-description,
    .products-summary {
      display: block;
      width: 100%;
    }

    .mobile-break {
      display: block;
    }

    .products-summary {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .product-summary ul li {
    margin: 0;
    padding: 5px 0;
    border: none;
    text-align: center;
    width: 49%;
    text-align: left;
  }

  .price-summary {
    border: none;
    padding: 5px;
    position: relative;
    a {
      top: -2px;
      margin: 0 0 0 3px;
    }
  }

  .price-summary .total-price {
    font-size: 1em;
  }

  .price-summary .icon-info {
    display: none;
  }

  .price-summary .pro-feature {
    padding: 3px 15px 0;
    position: static;
    text-align: center;
    width: 100%;
    line-height: 1em;
    font-size: 0.8em;
  }

  .extended-summary {
    display: none;
  }

  .extended-summary.ui-accordion {
    display: block;
  }

  .extended-summary {
    padding: 10px;
  }

  .extended-summary-label {
    text-align: center;
    padding: 10px 0 !important;
    clear: both;
    padding: 0 10px;

    span {
      display: none;
      color: $color_fx_orange;
    }

    &.ui-accordion-header {
      span.active {
        display: none !important;
      }
      span.inactive {
        display: block !important;
      }
    }

    &.ui-accordion-header.ui-accordion-header-active {
      span.active {
        display: block !important;
      }
      span.inactive {
        display: none !important;
      }
    }
  }
}

.list_select_container {
  max-width: 250px;
}

@media (max-width: 870px) and (min-width: 768px) {
  #my-list-list-form.show-price .price {
    display: block !important;
    margin: 0 !important;
  }
  .list-price {
    min-width: 180px;
    text-align: right;
  }
  #my-list-list-form.show-price .price.extended-price {
    margin-top: 1em !important;
  }
}

@media (min-width: 850px) {
  .spec_builder_choice_container .choice_inner * {
    line-height: 16px;
    max-width: 200px;
  }
}

#accessory_modal .spec_builder_choice_container .choice_inner * {
  max-width: 100%;
}
