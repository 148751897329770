/* Generated Spec Sheet Updates */
.gen-spec-sheet {
  .node-63201 {
    .dimensions {
      .row {
        .columns.large-6 {
          display: inline-block;
          max-width: 44%;
          vertical-align: top;
          margin-right: 4%;
          h5 {
            margin-top: 60px;
            display: block;
            height: 40px;
          }
          p {
            height: 165px;
          }
          .media-image {
            text-align: left;
            right: 15px !important;
            max-width: 95% !important;
          }
        }
      }
    }
    article.photometrics p:last-of-type {
      max-width: 100%;
    }
    .photometrics {
      img {
        width: 80%;
      }
    }
  }
}
